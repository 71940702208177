const data = [
  {
    "Date": "2006-06-30",
    "MQE Strategy": -0.27,
    "HFRI 500": -0.29,
    "HFRI Currency Index": 1.1
  },
  {
    "Date": "2006-07-31",
    "MQE Strategy": -0.59,
    "HFRI 500": -0.35,
    "HFRI Currency Index": 0.89
  },
  {
    "Date": "2006-08-31",
    "MQE Strategy": -0.59,
    "HFRI 500": 0.67,
    "HFRI Currency Index": 4.34
  },
  {
    "Date": "2006-09-30",
    "MQE Strategy": -0.41,
    "HFRI 500": 0.86,
    "HFRI Currency Index": 2.84
  },
  {
    "Date": "2006-10-31",
    "MQE Strategy": -0.39,
    "HFRI 500": 2.74,
    "HFRI Currency Index": 4.17
  },
  {
    "Date": "2006-11-30",
    "MQE Strategy": -0.39,
    "HFRI 500": 4.85,
    "HFRI Currency Index": 8.16
  },
  {
    "Date": "2006-12-31",
    "MQE Strategy": -0.02,
    "HFRI 500": 6.93,
    "HFRI Currency Index": 12.53
  },
  {
    "Date": "2007-01-31",
    "MQE Strategy": -0.3,
    "HFRI 500": 8.15,
    "HFRI Currency Index": 13.58
  },
  {
    "Date": "2007-02-28",
    "MQE Strategy": -0.28,
    "HFRI 500": 8.99,
    "HFRI Currency Index": 10.97
  },
  {
    "Date": "2007-03-31",
    "MQE Strategy": -0.13,
    "HFRI 500": 10.22,
    "HFRI Currency Index": 10.34
  },
  {
    "Date": "2007-04-30",
    "MQE Strategy": 0.15,
    "HFRI 500": 12.19,
    "HFRI Currency Index": 13.8
  },
  {
    "Date": "2007-05-31",
    "MQE Strategy": 0.91,
    "HFRI 500": 14.73,
    "HFRI Currency Index": 17.02
  },
  {
    "Date": "2007-06-30",
    "MQE Strategy": 1.36,
    "HFRI 500": 16.09,
    "HFRI Currency Index": 20.85
  },
  {
    "Date": "2007-07-31",
    "MQE Strategy": 1.91,
    "HFRI 500": 16.78,
    "HFRI Currency Index": 19.46
  },
  {
    "Date": "2007-08-31",
    "MQE Strategy": 2.28,
    "HFRI 500": 14.49,
    "HFRI Currency Index": 16.34
  },
  {
    "Date": "2007-09-30",
    "MQE Strategy": 2.7,
    "HFRI 500": 17.34,
    "HFRI Currency Index": 20.43
  },
  {
    "Date": "2007-10-31",
    "MQE Strategy": 3.29,
    "HFRI 500": 20.71,
    "HFRI Currency Index": 22.52
  },
  {
    "Date": "2007-11-30",
    "MQE Strategy": 4.54,
    "HFRI 500": 18.04,
    "HFRI Currency Index": 21.1
  },
  {
    "Date": "2007-12-31",
    "MQE Strategy": 5.66,
    "HFRI 500": 18.52,
    "HFRI Currency Index": 20.15
  },
  {
    "Date": "2008-01-31",
    "MQE Strategy": 7.44,
    "HFRI 500": 15.04,
    "HFRI Currency Index": 20.11
  },
  {
    "Date": "2008-02-29",
    "MQE Strategy": 8.51,
    "HFRI 500": 16.98,
    "HFRI Currency Index": 21.06
  },
  {
    "Date": "2008-03-31",
    "MQE Strategy": 10.04,
    "HFRI 500": 14.19,
    "HFRI Currency Index": 22.15
  },
  {
    "Date": "2008-04-30",
    "MQE Strategy": 10.49,
    "HFRI 500": 15.99,
    "HFRI Currency Index": 22.03
  },
  {
    "Date": "2008-05-31",
    "MQE Strategy": 11.17,
    "HFRI 500": 18.07,
    "HFRI Currency Index": 22.29
  },
  {
    "Date": "2008-06-30",
    "MQE Strategy": 11.92,
    "HFRI 500": 16.52,
    "HFRI Currency Index": 22.25
  },
  {
    "Date": "2008-07-31",
    "MQE Strategy": 12.29,
    "HFRI 500": 13.57,
    "HFRI Currency Index": 22.58
  },
  {
    "Date": "2008-08-31",
    "MQE Strategy": 12.23,
    "HFRI 500": 11.58,
    "HFRI Currency Index": 19.17
  },
  {
    "Date": "2008-09-30",
    "MQE Strategy": 12.28,
    "HFRI 500": 4.81,
    "HFRI Currency Index": 16.41
  },
  {
    "Date": "2008-10-31",
    "MQE Strategy": 12.25,
    "HFRI 500": -2.4,
    "HFRI Currency Index": 14.65
  },
  {
    "Date": "2008-11-30",
    "MQE Strategy": 12.05,
    "HFRI 500": -4,
    "HFRI Currency Index": 14.5
  },
  {
    "Date": "2008-12-31",
    "MQE Strategy": 12.05,
    "HFRI 500": -3.86,
    "HFRI Currency Index": 15.18
  },
  {
    "Date": "2009-01-31",
    "MQE Strategy": 12.05,
    "HFRI 500": -3.69,
    "HFRI Currency Index": 14.95
  },
  {
    "Date": "2009-02-28",
    "MQE Strategy": 11.97,
    "HFRI 500": -4.3,
    "HFRI Currency Index": 14.79
  },
  {
    "Date": "2009-03-31",
    "MQE Strategy": 11.97,
    "HFRI 500": -2.92,
    "HFRI Currency Index": 14.2
  },
  {
    "Date": "2009-04-30",
    "MQE Strategy": 11.97,
    "HFRI 500": -0.33,
    "HFRI Currency Index": 13.01
  },
  {
    "Date": "2009-05-31",
    "MQE Strategy": 11.97,
    "HFRI 500": 4.51,
    "HFRI Currency Index": 15.68
  },
  {
    "Date": "2009-06-30",
    "MQE Strategy": 12.06,
    "HFRI 500": 4.74,
    "HFRI Currency Index": 15.15
  },
  {
    "Date": "2009-07-31",
    "MQE Strategy": 12.08,
    "HFRI 500": 7.48,
    "HFRI Currency Index": 14.19
  },
  {
    "Date": "2009-08-31",
    "MQE Strategy": 12.08,
    "HFRI 500": 9,
    "HFRI Currency Index": 14.65
  },
  {
    "Date": "2009-09-30",
    "MQE Strategy": 12.08,
    "HFRI 500": 12,
    "HFRI Currency Index": 16.98
  },
  {
    "Date": "2009-10-31",
    "MQE Strategy": 12.08,
    "HFRI 500": 11.84,
    "HFRI Currency Index": 17.67
  },
  {
    "Date": "2009-11-30",
    "MQE Strategy": 12.06,
    "HFRI 500": 13.21,
    "HFRI Currency Index": 18.45
  },
  {
    "Date": "2009-12-31",
    "MQE Strategy": 12.05,
    "HFRI 500": 14.28,
    "HFRI Currency Index": 16.87
  },
  {
    "Date": "2010-01-31",
    "MQE Strategy": 12.05,
    "HFRI 500": 13.58,
    "HFRI Currency Index": 16.55
  },
  {
    "Date": "2010-02-28",
    "MQE Strategy": 12.05,
    "HFRI 500": 13.89,
    "HFRI Currency Index": 17.54
  },
  {
    "Date": "2010-03-31",
    "MQE Strategy": 12.05,
    "HFRI 500": 16.69,
    "HFRI Currency Index": 18.99
  },
  {
    "Date": "2010-04-30",
    "MQE Strategy": 12,
    "HFRI 500": 17.81,
    "HFRI Currency Index": 20.32
  },
  {
    "Date": "2010-05-31",
    "MQE Strategy": 12.01,
    "HFRI 500": 14.5,
    "HFRI Currency Index": 21.01
  },
  {
    "Date": "2010-06-30",
    "MQE Strategy": 12.63,
    "HFRI 500": 13.66,
    "HFRI Currency Index": 20.04
  },
  {
    "Date": "2010-07-31",
    "MQE Strategy": 12.94,
    "HFRI 500": 15.36,
    "HFRI Currency Index": 21.34
  },
  {
    "Date": "2010-08-31",
    "MQE Strategy": 12.53,
    "HFRI 500": 15.49,
    "HFRI Currency Index": 20.93
  },
  {
    "Date": "2010-09-30",
    "MQE Strategy": 13.51,
    "HFRI 500": 19.24,
    "HFRI Currency Index": 24.31
  },
  {
    "Date": "2010-10-31",
    "MQE Strategy": 14.53,
    "HFRI 500": 21.53,
    "HFRI Currency Index": 24.89
  },
  {
    "Date": "2010-11-30",
    "MQE Strategy": 14.73,
    "HFRI 500": 21.23,
    "HFRI Currency Index": 23.79
  },
  {
    "Date": "2010-12-31",
    "MQE Strategy": 15.46,
    "HFRI 500": 24.51,
    "HFRI Currency Index": 24.75
  },
  {
    "Date": "2011-01-31",
    "MQE Strategy": 16.23,
    "HFRI 500": 25.02,
    "HFRI Currency Index": 23.26
  },
  {
    "Date": "2011-02-28",
    "MQE Strategy": 16.39,
    "HFRI 500": 26.1,
    "HFRI Currency Index": 24.19
  },
  {
    "Date": "2011-03-31",
    "MQE Strategy": 16.64,
    "HFRI 500": 26.18,
    "HFRI Currency Index": 25.14
  },
  {
    "Date": "2011-04-30",
    "MQE Strategy": 17.48,
    "HFRI 500": 28.22,
    "HFRI Currency Index": 28.82
  },
  {
    "Date": "2011-05-31",
    "MQE Strategy": 17.76,
    "HFRI 500": 26.39,
    "HFRI Currency Index": 25.31
  },
  {
    "Date": "2011-06-30",
    "MQE Strategy": 18.21,
    "HFRI 500": 24.95,
    "HFRI Currency Index": 24.8
  },
  {
    "Date": "2011-07-31",
    "MQE Strategy": 18.4,
    "HFRI 500": 25.51,
    "HFRI Currency Index": 26.69
  },
  {
    "Date": "2011-08-31",
    "MQE Strategy": 19.72,
    "HFRI 500": 21.96,
    "HFRI Currency Index": 24.16
  },
  {
    "Date": "2011-09-30",
    "MQE Strategy": 19.48,
    "HFRI 500": 18.07,
    "HFRI Currency Index": 23.05
  },
  {
    "Date": "2011-10-31",
    "MQE Strategy": 20.08,
    "HFRI 500": 20.68,
    "HFRI Currency Index": 22.2
  },
  {
    "Date": "2011-11-30",
    "MQE Strategy": 20.01,
    "HFRI 500": 19.29,
    "HFRI Currency Index": 21.35
  },
  {
    "Date": "2011-12-31",
    "MQE Strategy": 21.07,
    "HFRI 500": 18.85,
    "HFRI Currency Index": 21.81
  },
  {
    "Date": "2012-01-31",
    "MQE Strategy": 21.07,
    "HFRI 500": 21.61,
    "HFRI Currency Index": 23.45
  },
  {
    "Date": "2012-02-29",
    "MQE Strategy": 21.3,
    "HFRI 500": 23.93,
    "HFRI Currency Index": 23.02
  },
  {
    "Date": "2012-03-31",
    "MQE Strategy": 21.22,
    "HFRI 500": 23.65,
    "HFRI Currency Index": 20.1
  },
  {
    "Date": "2012-04-30",
    "MQE Strategy": 21.14,
    "HFRI 500": 23.32,
    "HFRI Currency Index": 19.92
  },
  {
    "Date": "2012-05-31",
    "MQE Strategy": 20.28,
    "HFRI 500": 20.73,
    "HFRI Currency Index": 19.24
  },
  {
    "Date": "2012-06-30",
    "MQE Strategy": 20.05,
    "HFRI 500": 20.67,
    "HFRI Currency Index": 18.62
  },
  {
    "Date": "2012-07-31",
    "MQE Strategy": 19.73,
    "HFRI 500": 21.95,
    "HFRI Currency Index": 20.98
  },
  {
    "Date": "2012-08-31",
    "MQE Strategy": 20.29,
    "HFRI 500": 22.71,
    "HFRI Currency Index": 19.4
  },
  {
    "Date": "2012-09-30",
    "MQE Strategy": 21.2,
    "HFRI 500": 24.03,
    "HFRI Currency Index": 19.48
  },
  {
    "Date": "2012-10-31",
    "MQE Strategy": 22.37,
    "HFRI 500": 23.66,
    "HFRI Currency Index": 17.82
  },
  {
    "Date": "2012-11-30",
    "MQE Strategy": 22.66,
    "HFRI 500": 24.32,
    "HFRI Currency Index": 17.79
  },
  {
    "Date": "2012-12-31",
    "MQE Strategy": 22.51,
    "HFRI 500": 26.22,
    "HFRI Currency Index": 18.27
  },
  {
    "Date": "2013-01-31",
    "MQE Strategy": 22.82,
    "HFRI 500": 29.63,
    "HFRI Currency Index": 17.89
  },
  {
    "Date": "2013-02-28",
    "MQE Strategy": 22.68,
    "HFRI 500": 30.03,
    "HFRI Currency Index": 17.56
  },
  {
    "Date": "2013-03-31",
    "MQE Strategy": 22.91,
    "HFRI 500": 31.24,
    "HFRI Currency Index": 18.11
  },
  {
    "Date": "2013-04-30",
    "MQE Strategy": 23.76,
    "HFRI 500": 32.51,
    "HFRI Currency Index": 17.09
  },
  {
    "Date": "2013-05-31",
    "MQE Strategy": 24.7,
    "HFRI 500": 33.32,
    "HFRI Currency Index": 16.58
  },
  {
    "Date": "2013-06-30",
    "MQE Strategy": 24.7,
    "HFRI 500": 31.15,
    "HFRI Currency Index": 15.45
  },
  {
    "Date": "2013-07-31",
    "MQE Strategy": 24.7,
    "HFRI 500": 32.85,
    "HFRI Currency Index": 14.29
  },
  {
    "Date": "2013-08-31",
    "MQE Strategy": 24.7,
    "HFRI 500": 32.03,
    "HFRI Currency Index": 12.4
  },
  {
    "Date": "2013-09-30",
    "MQE Strategy": 24.72,
    "HFRI 500": 33.99,
    "HFRI Currency Index": 13.04
  },
  {
    "Date": "2013-10-31",
    "MQE Strategy": 25.46,
    "HFRI 500": 36.25,
    "HFRI Currency Index": 12.94
  },
  {
    "Date": "2013-11-30",
    "MQE Strategy": 25.28,
    "HFRI 500": 37.84,
    "HFRI Currency Index": 12.56
  },
  {
    "Date": "2013-12-31",
    "MQE Strategy": 25.75,
    "HFRI 500": 39.37,
    "HFRI Currency Index": 12.27
  },
  {
    "Date": "2014-01-31",
    "MQE Strategy": 26.05,
    "HFRI 500": 38.63,
    "HFRI Currency Index": 11.87
  },
  {
    "Date": "2014-02-28",
    "MQE Strategy": 24.63,
    "HFRI 500": 40.97,
    "HFRI Currency Index": 11.09
  },
  {
    "Date": "2014-03-31",
    "MQE Strategy": 23.19,
    "HFRI 500": 40.18,
    "HFRI Currency Index": 11.37
  },
  {
    "Date": "2014-04-30",
    "MQE Strategy": 23.19,
    "HFRI 500": 39.48,
    "HFRI Currency Index": 10.79
  },
  {
    "Date": "2014-05-31",
    "MQE Strategy": 23.19,
    "HFRI 500": 41.2,
    "HFRI Currency Index": 11.54
  },
  {
    "Date": "2014-06-30",
    "MQE Strategy": 23.19,
    "HFRI 500": 42.78,
    "HFRI Currency Index": 11.22
  },
  {
    "Date": "2014-07-31",
    "MQE Strategy": 23.19,
    "HFRI 500": 42.2,
    "HFRI Currency Index": 11.57
  },
  {
    "Date": "2014-08-31",
    "MQE Strategy": 23.19,
    "HFRI 500": 44.02,
    "HFRI Currency Index": 12.79
  },
  {
    "Date": "2014-09-30",
    "MQE Strategy": 23.19,
    "HFRI 500": 43.8,
    "HFRI Currency Index": 15.31
  },
  {
    "Date": "2014-10-31",
    "MQE Strategy": 23.19,
    "HFRI 500": 43.43,
    "HFRI Currency Index": 16.23
  },
  {
    "Date": "2014-11-30",
    "MQE Strategy": 23.72,
    "HFRI 500": 45.28,
    "HFRI Currency Index": 15.74
  },
  {
    "Date": "2014-12-31",
    "MQE Strategy": 24.72,
    "HFRI 500": 45.39,
    "HFRI Currency Index": 17.43
  },
  {
    "Date": "2015-01-31",
    "MQE Strategy": 23.96,
    "HFRI 500": 46.13,
    "HFRI Currency Index": 18.75
  },
  {
    "Date": "2015-02-28",
    "MQE Strategy": 23.96,
    "HFRI 500": 48.68,
    "HFRI Currency Index": 18.22
  },
  {
    "Date": "2015-03-31",
    "MQE Strategy": 23.37,
    "HFRI 500": 49.58,
    "HFRI Currency Index": 18.9
  },
  {
    "Date": "2015-04-30",
    "MQE Strategy": 23.07,
    "HFRI 500": 50.93,
    "HFRI Currency Index": 17.95
  },
  {
    "Date": "2015-05-31",
    "MQE Strategy": 23.09,
    "HFRI 500": 52.23,
    "HFRI Currency Index": 18.5
  },
  {
    "Date": "2015-06-30",
    "MQE Strategy": 23.24,
    "HFRI 500": 49.75,
    "HFRI Currency Index": 16.6
  },
  {
    "Date": "2015-07-31",
    "MQE Strategy": 23.26,
    "HFRI 500": 49.97,
    "HFRI Currency Index": 17.18
  },
  {
    "Date": "2015-08-31",
    "MQE Strategy": 26.75,
    "HFRI 500": 46.54,
    "HFRI Currency Index": 17.01
  },
  {
    "Date": "2015-09-30",
    "MQE Strategy": 26.65,
    "HFRI 500": 44.7,
    "HFRI Currency Index": 15.4
  },
  {
    "Date": "2015-10-31",
    "MQE Strategy": 26.16,
    "HFRI 500": 46.76,
    "HFRI Currency Index": 15.61
  },
  {
    "Date": "2015-11-30",
    "MQE Strategy": 26.86,
    "HFRI 500": 47.19,
    "HFRI Currency Index": 17.3
  },
  {
    "Date": "2015-12-31",
    "MQE Strategy": 28.64,
    "HFRI 500": 46.18,
    "HFRI Currency Index": 16.11
  },
  {
    "Date": "2016-01-31",
    "MQE Strategy": 30.42,
    "HFRI 500": 42.72,
    "HFRI Currency Index": 17.26
  },
  {
    "Date": "2016-02-29",
    "MQE Strategy": 28.83,
    "HFRI 500": 41.98,
    "HFRI Currency Index": 19.63
  },
  {
    "Date": "2016-03-31",
    "MQE Strategy": 29.1,
    "HFRI 500": 44.06,
    "HFRI Currency Index": 17.68
  },
  {
    "Date": "2016-04-30",
    "MQE Strategy": 28.88,
    "HFRI 500": 44.73,
    "HFRI Currency Index": 18.16
  },
  {
    "Date": "2016-05-31",
    "MQE Strategy": 30.51,
    "HFRI 500": 45.54,
    "HFRI Currency Index": 17.87
  },
  {
    "Date": "2016-06-30",
    "MQE Strategy": 30.09,
    "HFRI 500": 45.45,
    "HFRI Currency Index": 18.71
  },
  {
    "Date": "2016-07-31",
    "MQE Strategy": 30.09,
    "HFRI 500": 47.76,
    "HFRI Currency Index": 18.51
  },
  {
    "Date": "2016-08-31",
    "MQE Strategy": 30.47,
    "HFRI 500": 48.22,
    "HFRI Currency Index": 18.57
  },
  {
    "Date": "2016-09-30",
    "MQE Strategy": 30.24,
    "HFRI 500": 49.01,
    "HFRI Currency Index": 17.02
  },
  {
    "Date": "2016-10-31",
    "MQE Strategy": 32.47,
    "HFRI 500": 48.22,
    "HFRI Currency Index": 18.03
  },
  {
    "Date": "2016-11-30",
    "MQE Strategy": 34.83,
    "HFRI 500": 48.84,
    "HFRI Currency Index": 19.65
  },
  {
    "Date": "2016-12-31",
    "MQE Strategy": 35.58,
    "HFRI 500": 50.12,
    "HFRI Currency Index": 20.19
  },
  {
    "Date": "2017-01-31",
    "MQE Strategy": 33.69,
    "HFRI 500": 51.76,
    "HFRI Currency Index": 19.05
  },
  {
    "Date": "2017-02-28",
    "MQE Strategy": 34.09,
    "HFRI 500": 53.36,
    "HFRI Currency Index": 19.5
  },
  {
    "Date": "2017-03-31",
    "MQE Strategy": 34.45,
    "HFRI 500": 54.02,
    "HFRI Currency Index": 19.47
  },
  {
    "Date": "2017-04-30",
    "MQE Strategy": 34.18,
    "HFRI 500": 55.03,
    "HFRI Currency Index": 19.53
  },
  {
    "Date": "2017-05-31",
    "MQE Strategy": 34.31,
    "HFRI 500": 55.79,
    "HFRI Currency Index": 19.16
  },
  {
    "Date": "2017-06-30",
    "MQE Strategy": 36.38,
    "HFRI 500": 56.1,
    "HFRI Currency Index": 19.28
  },
  {
    "Date": "2017-07-31",
    "MQE Strategy": 36.08,
    "HFRI 500": 57.88,
    "HFRI Currency Index": 18.48
  },
  {
    "Date": "2017-08-31",
    "MQE Strategy": 38.75,
    "HFRI 500": 59.14,
    "HFRI Currency Index": 16.71
  },
  {
    "Date": "2017-09-30",
    "MQE Strategy": 37.31,
    "HFRI 500": 59.93,
    "HFRI Currency Index": 17.45
  },
  {
    "Date": "2017-10-31",
    "MQE Strategy": 37.78,
    "HFRI 500": 62.3,
    "HFRI Currency Index": 18.12
  },
  {
    "Date": "2017-11-30",
    "MQE Strategy": 38.49,
    "HFRI 500": 62.76,
    "HFRI Currency Index": 18.27
  },
  {
    "Date": "2017-12-31",
    "MQE Strategy": 38.35,
    "HFRI 500": 64.09,
    "HFRI Currency Index": 16.51
  },
  {
    "Date": "2018-01-31",
    "MQE Strategy": 34.56,
    "HFRI 500": 68.49,
    "HFRI Currency Index": 14.65
  },
  {
    "Date": "2018-02-28",
    "MQE Strategy": 34.39,
    "HFRI 500": 65.59,
    "HFRI Currency Index": 16
  },
  {
    "Date": "2018-03-31",
    "MQE Strategy": 33.98,
    "HFRI 500": 64.9,
    "HFRI Currency Index": 15.67
  },
  {
    "Date": "2018-04-30",
    "MQE Strategy": 34.81,
    "HFRI 500": 65.27,
    "HFRI Currency Index": 16.08
  },
  {
    "Date": "2018-05-31",
    "MQE Strategy": 36.55,
    "HFRI 500": 65.97,
    "HFRI Currency Index": 18.24
  },
  {
    "Date": "2018-06-30",
    "MQE Strategy": 39.93,
    "HFRI 500": 65.29,
    "HFRI Currency Index": 17.4
  },
  {
    "Date": "2018-07-31",
    "MQE Strategy": 44.92,
    "HFRI 500": 65.78,
    "HFRI Currency Index": 16.36
  },
  {
    "Date": "2018-08-31",
    "MQE Strategy": 45.48,
    "HFRI 500": 65.7,
    "HFRI Currency Index": 15.51
  },
  {
    "Date": "2018-09-30",
    "MQE Strategy": 46.26,
    "HFRI 500": 65.55,
    "HFRI Currency Index": 15.09
  },
  {
    "Date": "2018-10-31",
    "MQE Strategy": 48.07,
    "HFRI 500": 61.16,
    "HFRI Currency Index": 17.85
  },
  {
    "Date": "2018-11-30",
    "MQE Strategy": 47.51,
    "HFRI 500": 60.29,
    "HFRI Currency Index": 15.69
  },
  {
    "Date": "2018-12-31",
    "MQE Strategy": 46.24,
    "HFRI 500": 58.1,
    "HFRI Currency Index": 16.49
  },
  {
    "Date": "2019-01-31",
    "MQE Strategy": 42.61,
    "HFRI 500": 62.65,
    "HFRI Currency Index": 17.64
  },
  {
    "Date": "2019-02-28",
    "MQE Strategy": 41.98,
    "HFRI 500": 64.18,
    "HFRI Currency Index": 17.2
  },
  {
    "Date": "2019-03-31",
    "MQE Strategy": 42.23,
    "HFRI 500": 65.49,
    "HFRI Currency Index": 17.14
  },
  {
    "Date": "2019-04-30",
    "MQE Strategy": 42.23,
    "HFRI 500": 67.58,
    "HFRI Currency Index": 17.61
  },
  {
    "Date": "2019-05-31",
    "MQE Strategy": 42.32,
    "HFRI 500": 65.7,
    "HFRI Currency Index": 18.03
  },
  {
    "Date": "2019-06-30",
    "MQE Strategy": 42.77,
    "HFRI 500": 68.78,
    "HFRI Currency Index": 17.5
  },
  {
    "Date": "2019-07-31",
    "MQE Strategy": 42.68,
    "HFRI 500": 69.81,
    "HFRI Currency Index": 19.59
  },
  {
    "Date": "2019-08-31",
    "MQE Strategy": 37.49,
    "HFRI 500": 68.78,
    "HFRI Currency Index": 21.54
  },
  {
    "Date": "2019-09-30",
    "MQE Strategy": 37.72,
    "HFRI 500": 68.54,
    "HFRI Currency Index": 22.79
  },
  {
    "Date": "2019-10-31",
    "MQE Strategy": 39.02,
    "HFRI 500": 69.4,
    "HFRI Currency Index": 21.87
  },
  {
    "Date": "2019-11-30",
    "MQE Strategy": 39.62,
    "HFRI 500": 70.81,
    "HFRI Currency Index": 22.32
  },
  {
    "Date": "2019-12-31",
    "MQE Strategy": 40.54,
    "HFRI 500": 73.8,
    "HFRI Currency Index": 20.68
  },
  {
    "Date": "2020-01-31",
    "MQE Strategy": 41.73,
    "HFRI 500": 73.4,
    "HFRI Currency Index": 22.25
  },
  {
    "Date": "2020-02-29",
    "MQE Strategy": 39.73,
    "HFRI 500": 70.1,
    "HFRI Currency Index": 24.63
  },
  {
    "Date": "2020-03-31",
    "MQE Strategy": 37.92,
    "HFRI 500": 56.58,
    "HFRI Currency Index": 24.77
  },
  {
    "Date": "2020-04-30",
    "MQE Strategy": 38.2,
    "HFRI 500": 63.06,
    "HFRI Currency Index": 23.85
  },
  {
    "Date": "2020-05-31",
    "MQE Strategy": 36.96,
    "HFRI 500": 66.42,
    "HFRI Currency Index": 23.03
  },
  {
    "Date": "2020-06-30",
    "MQE Strategy": 38.28,
    "HFRI 500": 69.2,
    "HFRI Currency Index": 23.01
  },
  {
    "Date": "2020-07-31",
    "MQE Strategy": 39.66,
    "HFRI 500": 73.63,
    "HFRI Currency Index": 23.15
  },
  {
    "Date": "2020-08-31",
    "MQE Strategy": 42.05,
    "HFRI 500": 76.98,
    "HFRI Currency Index": 22.34
  },
  {
    "Date": "2020-09-30",
    "MQE Strategy": 43.59,
    "HFRI 500": 75.27,
    "HFRI Currency Index": 22.65
  },
  {
    "Date": "2020-10-31",
    "MQE Strategy": 45.97,
    "HFRI 500": 75.42,
    "HFRI Currency Index": 22.48
  },
  {
    "Date": "2020-11-30",
    "MQE Strategy": 48.62,
    "HFRI 500": 84.7,
    "HFRI Currency Index": 22.71
  },
  {
    "Date": "2020-12-31",
    "MQE Strategy": 50.04,
    "HFRI 500": 92.26,
    "HFRI Currency Index": 24.22
  },
  {
    "Date": "2021-01-31",
    "MQE Strategy": 51.76,
    "HFRI 500": 93.86,
    "HFRI Currency Index": 23.66
  },
  {
    "Date": "2021-02-28",
    "MQE Strategy": 51.58,
    "HFRI 500": 100.87,
    "HFRI Currency Index": 23.4
  },
  {
    "Date": "2021-03-31",
    "MQE Strategy": 50.82,
    "HFRI 500": 102.24,
    "HFRI Currency Index": 24.09
  },
  {
    "Date": "2021-04-30",
    "MQE Strategy": 50.82,
    "HFRI 500": 106.91,
    "HFRI Currency Index": 23.46
  },
  {
    "Date": "2021-05-31",
    "MQE Strategy": 50.82,
    "HFRI 500": 109.52,
    "HFRI Currency Index": 23.44
  },
  {
    "Date": "2021-06-30",
    "MQE Strategy": 50.82,
    "HFRI 500": 110.31,
    "HFRI Currency Index": 22.59
  },
  {
    "Date": "2021-07-31",
    "MQE Strategy": 50.36,
    "HFRI 500": 108.82,
    "HFRI Currency Index": 20.43
  },
  {
    "Date": "2021-08-31",
    "MQE Strategy": 50.59,
    "HFRI 500": 109.99,
    "HFRI Currency Index": 20.04
  },
  {
    "Date": "2021-09-30",
    "MQE Strategy": 50.68,
    "HFRI 500": 109.32,
    "HFRI Currency Index": 20.49
  },
  {
    "Date": "2021-10-31",
    "MQE Strategy": 48.83,
    "HFRI 500": 112.56,
    "HFRI Currency Index": 18.36
  },
  {
    "Date": "2021-11-30",
    "MQE Strategy": 48.74,
    "HFRI 500": 109.27,
    "HFRI Currency Index": 16.99
  },
  {
    "Date": "2021-12-31",
    "MQE Strategy": 48.39,
    "HFRI 500": 111.17,
    "HFRI Currency Index": 17.19
  },
  {
    "Date": "2022-01-31",
    "MQE Strategy": 48.25,
    "HFRI 500": 111.17,
    "HFRI Currency Index": 17.19
  },
  {
    "Date": "2022-02-28",
    "MQE Strategy": 47.06,
    "HFRI 500": 111.17,
    "HFRI Currency Index": 17.19
  },
  {
    "Date": "2022-03-31",
    "MQE Strategy": 48.1,
    "HFRI 500": 111.17,
    "HFRI Currency Index": 17.19
  }
 ]

export default data;