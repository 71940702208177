const data = {
  returns: [{
    "Sharpe": 1.32,
    "Annualized Return": "11.154%",
    "Forecast Success Ratio": "50.4%",
    "Avg. Annual Return": 11.154,
    "Market Beta": 0,
    "Monthly Alpha": "0.87%",
    "Correlation with S&P": -0.01,
    "Max Drawdown": "-7.54%",
    "% of Winning Mo.": "63.7%",
    "Gini": 0.436,
    "Forecast Accuracy": "",
    "Up Alpha": "0.49%",
    "Down Alpha": "0.77%",
    "Up Beta": 0.09,
    "Down Beta": -0.07,
    "Highest Monthly Return": 10.81,
    "Lowest Monthly Return": -3.09,
    "Annualized STD of Returns": "7.25%"
  }],
  hedge : [{
    "Sharpe": 0.85,
    "Annualized Return": "6.26%",
    "Avg. Annual Return": 6.26,
    "Market Beta": 0.32,
    "Monthly Alpha": "0.12%",
    "Correlation with S&P": 0.84,
    "Max Drawdown": "-9.91%",
    "% of Winning Mo.": "67.12%",
    "Gini": 0.422,
    "Forecast Accuracy": "",
    "Up Alpha": "0.21%",
    "Down Alpha": "0.15%",
    "Up Beta": 0.3,
    "Down Beta": 0.34,
    "Highest Monthly Return": 5.29,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "5.54%"
  }]
}

export default data;