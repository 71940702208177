import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../firebaseConfig";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { Row, Col } from "reactstrap";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BacktestAreaChart from "./BacktestAreaChart";
import LiveAreaChart from "./LiveAreaChart";
import LiveAreaChartMQE from "./LiveAreaChartMQE";
import BacktestBarChart from "./BacktestBarChart";
import BacktestBarChartMQE from "./BacktestBarChartMQE";
import StatsTables from "./StatsTables";
import StatsTablesMQE from "./StatsTablesMQE";
import RecentForecastTable from "./RecentForecastTable";
import MethodologyText from "./MethodologyText";
import MethodologyTextCommodity from "./MethodologyTextCommodity";
import MethodologyTextIndex from "./MethodologyTextIndex";
import data from "../backtest/portfolioData";
import euroData from "../backtest/euroData.js";
import cadData from "../backtest/cadData.js";
import yenData from "../backtest/yenData.js";
import poundData from "../backtest/poundData.js";
import audData from "../backtest/audData.js";
import goldData from "../backtest/goldData.js";
import sp500data from "../backtest/sp500data.js";
import pesoData from "../backtest/pesoData.js";
import yuanData from "../backtest/yuanData.js";
import euroMetrics from "../backtest/euroMetrics.js";
import yenMetrics from "../backtest/yenMetrics.js";
import poundMetrics from "../backtest/poundMetrics.js";
import audMetrics from "../backtest/audMetrics.js";
import cadMetrics from "../backtest/cadMetrics.js";
import goldMetrics from "../backtest/goldMetrics.js";
import pesoMetrics from "../backtest/pesoMetrics.js";
import yuanMetrics from "../backtest/yuanMetrics.js";
import sp500metrics from "../backtest/sp500Metrics.js";
import portfolioMetrics from "../backtest/portfolioMetrics.js";

import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

function HomeTabs(props) {
  const [tabType, setTabType] = useState("backtest");
  const [selectedTab, setSelectedTab] = useState("DEXJPUS");
  const [open, setOpen] = useState(false);
  const [openCommdity, setOpenCommdity] = useState(false);
  const [openIndex, setOpenIndex] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleComdityClick = () => {
    setOpenCommdity(!openCommdity);
  };

  const handleIndexClick = () => {
    setOpenIndex(!openIndex);
  };

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  const areaChartConstants = {
    DEXJPUS: yenData,
    sample: data,
    DEXUSEU: euroData,
    DEXUSAL: audData,
    CADUSD: cadData,
    MXNUSD: pesoData,
    CNYUSD: yuanData,
    DEXUSUK: poundData,
    SP500: sp500data,
    GOLD: goldData,
  };

  const statsTableConstants = {
    DEXJPUS: yenMetrics,
    sample: euroMetrics,
    DEXUSEU: euroMetrics,
    DEXUSAL: audMetrics,
    CADUSD: cadMetrics,
    MXNUSD: pesoMetrics,
    CNYUSD: yuanMetrics,
    DEXUSUK: poundMetrics,
    GOLD: goldMetrics,
    SP500: sp500metrics
  };

  const [value, loading] = useCollection(db.collection("assets"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  console.log(value && value.doc && value.doc.data());

  const countryOptions = [
    {
      key: "vertical-tab-one",
      text: "MQE Forex Portfolio",
      value: "vertical-tab-one",
      image: {
        avatar: true,
        src: "img/MQE.png",
      },
    },
  ];

  return (
    <div className="mt-4">
      {value && (
        <Row className="toggle-buttons">
          <span
            onClick={() => {
              setTabType("past");
            }}
            className={
              tabType === "past"
                ? "toggle-button-mae-selected"
                : "toggle-button-mae"
            }
          >
            Live Forecast
          </span>{" "}
          <span
            onClick={() => {
              setTabType("backtest");
            }}
            className={
              tabType === "backtest"
                ? "toggle-button-mae-selected"
                : "toggle-button-mae"
            }
          >
            Backtest
          </span>{" "}
          <span
            onClick={() => {
              setTabType("methodology");
            }}
            className={
              tabType === "methodology"
                ? "toggle-button-mae-selected"
                : "toggle-button-mae"
            }
          >
            Methodology
          </span>
        </Row>
      )}
      <p>
        {loading && <ClipLoader css={override} size={45} color={"#005687"} />}
      </p>
      {value && (
        <Tabs defaultTab={selectedTab.toString()} vertical id="flex-wrapper">
          <TabList
            id="tablist"
            style={{
              position: "sticky",
              top: "0",
              alignSelf: "flex-start",
              marginRight: "4rem",
            }}
            className="tab_list_css"
          >
            <List
              sx={{
                width: 260,
                padding: "0px !important",
                bgcolor: "transparent",
                "&:hover": { background: "transparent" },
              }}
              component="nav"
              disablePadding
            >
              <ListItem
                disableRipple={true}
                sx={{
                  padding: "7px",
                  paddingBottom: "0px",
                  bgcolor: "transparent",
                  "&:hover": { background: "transparent" },
                }}
              >
                <Tab  onClick={handleClick} tabFor="vertical-tab-one" style={{ width: "100%" }}>
                  <span className="tab-text">MQE Forex Portfolio</span>
                  <img
                    className="tab-image"
                    width="100%"
                    src="img/MQE.png"
                    alt="Card cap"
                  />
                </Tab>

                <span style={{ display: "inline-block", marginLeft: ".6rem" }}>
                  {" "}
                  {open ? (
                    <ExpandLess
                      onClick={handleClick}
                      sx={{ color: "#005687", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <ExpandMore
                      onClick={handleClick}
                      sx={{ color: "#005687", fontSize: "1.8rem" }}
                    />
                  )}{" "}
                </span>
              </ListItem>

              {value.docs.map((doc) => {
                countryOptions.push({
                  key: doc.id,
                  text: doc.data().name,
                  value: doc.id,
                  image: {
                    avatar: true,
                    src: doc.data().squareImage,
                  },
                });
                return (
                  doc.data().isVisible &&
                  doc.data().parentCategory !== "commodity" && (
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      key={doc.id}
                    >
                      <List
                        component="div"
                        sx={{ paddingLeft: ".8rem" }}
                        disablePadding
                      >
                        <ListItem
                          disableRipple={true}
                          sx={{ "&:hover": { background: "transparent" } }}
                        >
                          <Tab tabFor={doc.id} style={{ width: "90%" }}>
                            <img
                              className="tab-image"
                              width="100%"
                              src={doc.data().squareImage}
                              alt={doc.data().name}
                            />
                            <span className="tab-text">{doc.data().name}</span>
                          </Tab>
                        </ListItem>
                      </List>
                    </Collapse>
                  )
                );
              })}

              <ListItem
                disableRipple={true}
                sx={{
                  padding: "7px",
                  paddingBottom: "0px",
                  bgcolor: "transparent",
                  "&:hover": { background: "transparent" },
                }}
              >
                <Tab onClick={handleComdityClick} style={{ width: "100%" }}>
                  <span className="tab-text">MQE Commodity Portfolio</span>
                  <img
                    className="tab-image"
                    width="100%"
                    src="img/Commodity.png"
                    alt="Card cap"
                  />
                </Tab>

                <span style={{ display: "inline-block", marginLeft: ".6rem" }}>
                  {" "}
                  {openCommdity ? (
                    <ExpandLess
                      onClick={handleComdityClick}
                      sx={{ color: "#005687", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <ExpandMore
                      onClick={handleComdityClick}
                      sx={{ color: "#005687", fontSize: "1.8rem" }}
                    />
                  )}{" "}
                </span>
              </ListItem>
              {value.docs
                .filter((doc) => doc.data().parentCategory === "commodity")
                .map((doc) => {
                  return (
                    doc.data().isVisible && (
                      <Collapse
                        in={openCommdity}
                        timeout="auto"
                        unmountOnExit
                        key={doc.id}
                      >
                        <List
                          component="div"
                          sx={{ paddingLeft: ".8rem" }}
                          disablePadding
                        >
                          <ListItem
                            disableRipple={true}
                            sx={{ "&:hover": { background: "transparent" } }}
                          >
                            <Tab tabFor={doc.id} style={{ width: "90%" }}>
                              <img
                                className="tab-image"
                                width="100%"
                                src={doc.data().squareImage}
                                alt={doc.data().name}
                              />
                              <span className="tab-text">
                                {doc.data().name}
                              </span>
                            </Tab>
                          </ListItem>
                        </List>
                      </Collapse>
                    )
                  );
                })}
                <ListItem
                disableRipple={true}
                sx={{
                  padding: "7px",
                  paddingBottom: "0px",
                  bgcolor: "transparent",
                  "&:hover": { background: "transparent" },
                }}
              >
                <Tab onClick={handleIndexClick} style={{ width: "100%" }}>
                  <span className="tab-text">MQE Index Portfolio</span>
                  <img
                    className="tab-image"
                    width="100%"
                    src="img/Index.png"
                    alt="Card cap"
                  />
                </Tab>

                <span style={{ display: "inline-block", marginLeft: ".6rem" }}>
                  {" "}
                  {openIndex ? (
                    <ExpandLess
                      onClick={handleIndexClick}
                      sx={{ color: "#005687", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <ExpandMore
                      onClick={handleIndexClick}
                      sx={{ color: "#005687", fontSize: "1.8rem" }}
                    />
                  )}{" "}
                </span>
              </ListItem>
              {value.docs
                .filter((doc) => doc.data().parentCategory === "index")
                .map((doc) => {
                  return (
                    doc.data().isVisible && (
                      <Collapse
                        in={openIndex}
                        timeout="auto"
                        unmountOnExit
                        key={doc.id}
                      >
                        <List
                          component="div"
                          sx={{ paddingLeft: ".8rem" }}
                          disablePadding
                        >
                          <ListItem
                            disableRipple={true}
                            sx={{ "&:hover": { background: "transparent" } }}
                          >
                            <Tab tabFor={doc.id} style={{ width: "90%" }}>
                              <img
                                className="tab-image"
                                width="100%"
                                src={doc.data().squareImage}
                                alt={doc.data().name}
                              />
                              <span className="tab-text">
                                {doc.data().name}
                              </span>
                            </Tab>
                          </ListItem>
                        </List>
                      </Collapse>
                    )
                  );
                })}
            </List>
            
          </TabList>
          <TabPanel tabId="vertical-tab-one">
            {tabType === "backtest" ? (
              <>
                <BacktestAreaChart theme={props.theme} data={data} assetName="Portfolio" />
                <br />
                <Row>
                  <Col sm="12" md="12" lg="4">
                    <BacktestBarChartMQE
                      metricName="Sharpe"
                      maeValue={8}
                      hedgeFundValue={4}
                      theme={props.theme}
                    />
                  </Col>
                  <Col sm="12" md="12" lg="4">
                    <BacktestBarChartMQE
                      metricName="Avg. Annual Return"
                      maeValue={10}
                      hedgeFundValue={-4}
                      theme={props.theme}
                    />
                  </Col>
                  <Col sm="12" md="12" lg="4">
                    <BacktestBarChartMQE
                      metricName="Market Beta"
                      maeValue={5}
                      hedgeFundValue={7}
                      theme={props.theme}
                    />
                  </Col>
                </Row>
                <h3
                  className="color-ucla mt-4"
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    marginTop: "15px",
                  }}
                >
                  Evaluation Metrics of MQE Algorithm on Portfolio
                </h3>
                <StatsTables data={portfolioMetrics} />
              </>
            ) : tabType === "past" ? (
              <>
                <h3
                  className="color-ucla"
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    marginTop: "15px",
                  }}
                >
                  Past Forecast Performance of Trading Strategy
                </h3>
                <LiveAreaChartMQE
                  theme={props.theme}
                  assetName="MQE Portfolio"
                  id="MQEPortfolio"
                  data="MQEPortfolio"
                />
                <h3
                  className="color-ucla mt-4"
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    marginTop: "15px",
                  }}
                >
                  Evaluation Metrics of MQE Algorithm on Portfolio
                </h3>
                <StatsTablesMQE />
              </>
            ) : (
              <MethodologyText />
            )}
          </TabPanel>
          {value.docs.map(
            (doc) =>
              doc.data().isVisible && (
                <TabPanel tabId={doc.id} key={doc.id}>
                  {tabType === "backtest" ? (
                    <>
                      <BacktestAreaChart
                        theme={props.theme}
                        assetName={doc.data().name}
                        data={areaChartConstants[doc.id]}
                      />
                      <br />
                      <Row>
                        <Col sm="12" md="12" lg="4">
                          <BacktestBarChart
                            metricName="Sharpe"
                            data={statsTableConstants[doc.id]}
                            theme={props.theme}
                          />
                        </Col>
                        <Col sm="12" md="12" lg="4">
                          <BacktestBarChart
                            metricName="Avg. Annual Return"
                            data={statsTableConstants[doc.id]}
                            theme={props.theme}
                          />
                        </Col>
                        <Col sm="12" md="12" lg="4">
                          <BacktestBarChart
                            metricName="Market Beta"
                            data={statsTableConstants[doc.id]}
                            theme={props.theme}
                          />
                        </Col>
                      </Row>
                      <h3
                        className="color-ucla mt-4"
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        Evaluation Metrics of MQE Algorithm on {doc.data().name}
                      </h3>
                      <StatsTables data={statsTableConstants[doc.id]} />
                      <h3
                        className="color-ucla"
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        Performance of Recent Forecasts
                      </h3>
                      <RecentForecastTable
                        id={doc.id}
                        forecastTerm={props.forecastTerm}
                      />
                    </>
                  ) : tabType === "past" ? (
                    <>
                      <h3
                        className="color-ucla"
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        Past Forecast Performance of Trading Strategy
                      </h3>
                      <LiveAreaChart
                        theme={props.theme}
                        assetName={doc.data().name}
                        id={doc.id}
                        data={areaChartConstants[doc.id]}
                      />
                      <h3
                        className="color-ucla"
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        Performance of Recent Forecasts
                      </h3>
                      <RecentForecastTable
                        id={doc.id}
                        forecastTerm={props.forecastTerm}
                      />
                    </>
                  ) : (
                    <>
                    {doc.data().parentCategory === "commodity" ? <MethodologyTextCommodity /> : doc.data().parentCategory === "index" ? <MethodologyTextIndex /> : <MethodologyText />}
                    
                    <MethodologyText />
                    </>
                  )}
                </TabPanel>
              )
          )}
        </Tabs>
      )}
    </div>
  );
}

export default HomeTabs;
