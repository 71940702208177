const data = {
  returns: [{
    "Sharpe": 0.91,
    "Annualized Return": "10.669%",
    "Forecast Success Ratio": "51.1%",
    "Avg. Annual Return": 10.669,
    "Market Beta": -0.07,
    "Monthly Alpha": "0.87%",
    "Correlation with S&P": -0.11,
    "Max Drawdown": "-13.22%",
    "% of Winning Mo.": "62.13%",
    "Gini": 0.438,
    "Forecast Accuracy": "",
    "Up Alpha": "1.01%",
    "Down Alpha": "0.83%",
    "Up Beta": -0.1,
    "Down Beta": -0.06,
    "Highest Monthly Return": 9.31,
    "Lowest Monthly Return": -5.74,
    "Annualized STD of Returns": "9.69%"
  }],
  hedge : [{
    "Sharpe": 0.43,
    "Annualized Return": "4.464%",
    "Avg. Annual Return": 4.464,
    "Market Beta": 0.33,
    "Monthly Alpha": "0.1%",
    "Correlation with S&P": 0.82,
    "Max Drawdown": "-20.72%",
    "% of Winning Mo.": "65.68%",
    "Gini": 0.423,
    "Forecast Accuracy": "",
    "Up Alpha": "0.19%",
    "Down Alpha": "0.2%",
    "Up Beta": 0.3,
    "Down Beta": 0.36,
    "Highest Monthly Return": 5.29,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "6.21%"
  }]
}

export default data;