import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function BacktestBarChartMQE(props) {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        width={450}
        height={200}
        data={[
          {
            name: props.metricName,
            "MQE Forex Fund": props.maeValue,
            "HFRI 500": props.hedgeFundValue,
          },
        ]}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          style={{ fill: props.theme === "light" ? null : "white" }}
          dataKey="name"
        />
        <YAxis />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="MQE Forex Fund" fill="#005687" />
        <Bar dataKey="HFRI 500" fill="#FBC138" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BacktestBarChartMQE;
