import React, { useState } from "react";
import { Container } from "reactstrap";

import NavbarTop from "../components/NavbarTop";
import TopSlider from "../components/TopSlider";
import HomeTabs from "../components/HomeTabs";
import Footer from "../components/Footer";
//import { RiTimerFlashFill, RiLineChartFill } from "react-icons/ri";

// DarkMode
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "../darkmode/useDarkMode";
import { GlobalStyles } from "../darkmode/GlobalStyle";
import { lightTheme, darkTheme } from "../darkmode/Theme";

function HomePage() {
  const [forecastTerm, setTerm] = useState(1);

  const [theme, themeToggler, mountedComponent] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  if (!mountedComponent) return <div />;

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <div>
          {/* <div className="mae-side-toggle">
            <div
              onClick={() => {
                setTerm(1);
              }}
              className={
                forecastTerm === 1
                  ? "toggle-button-mae-selected-vertical"
                  : "toggle-button-mae-vertical"
              }
            >
              <span className="mae-side-toggle-icon">
                <RiTimerFlashFill />
              </span>
              <br />
              <span className="mae-side-toggle-text">
                {" "}
                Short Term Forecasts
              </span>
            </div>
            <div
              onClick={() => {
                setTerm(12);
              }}
              className={
                forecastTerm === 12
                  ? "toggle-button-mae-yellow-selected-vertical"
                  : "toggle-button-mae-yellow-vertical"
              }
            >
              <span className="mae-side-toggle-icon">
                <RiLineChartFill />
              </span>
              <br />
              <span className="mae-side-toggle-text">
                {" "}
                Long Term Forecasts{" "}
              </span>
            </div>
          </div> */}
          <NavbarTop theme={theme} toggleTheme={themeToggler} />
          <br />
          <Container>
            <div style={{ padding: "0 50px" }}>
              <TopSlider forecastTerm={forecastTerm} />
            </div>
            <div id="padding-container" style={{ padding: "50px" }}>
              <HomeTabs theme={theme} forecastTerm={forecastTerm} />
            </div>
          </Container>
          <Footer />
        </div>
      </>
    </ThemeProvider>
  );
}

export default HomePage;
