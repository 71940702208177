import React from 'react';
import { Table, Row, Col } from 'reactstrap';

function StatsTables(props) {

  const {data} = props;

  return (
    <Row className="p-3">
      <Col>
        <Table striped bordered responsive className="stats-table">
          <thead>
            <tr>
              <th>Metric</th>
              <th>MQE Strategy</th>
              <th>HFRI 500</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sharpe</td>
              <td>{data && data.returns[0]["Sharpe"]}</td>
              <td>{data && data.hedge[0]["Sharpe"]}</td>
            </tr>
            <tr>
              <td>Annualized Return</td>
              <td>{data && data.returns[0]["Annualized Return"]}</td>
              <td>{data && data.hedge[0]["Annualized Return"]}</td>
            </tr>
            <tr>
              <td>Market Beta</td>
              <td>{data && data.returns[0]["Market Beta"]}</td>
              <td>{data && data.hedge[0]["Market Beta"]}</td>
            </tr>
            <tr>
              <td>Monthly Alpha</td>
              <td>{data && data.returns[0]["Monthly Alpha"]}</td>
              <td>{data && data.hedge[0]["Monthly Alpha"]}</td>
            </tr>
            <tr>
              <td>Correlation with S&amp;P</td>
              <td>{data && data.returns[0]["Correlation with S&P"]}</td>
              <td>{data && data.hedge[0]["Correlation with S&P"]}</td>
            </tr>
            <tr>
              <td>Max Drawdown</td>
              <td>{data && data.returns[0]["Max Drawdown"]}</td>
              <td>{data && data.hedge[0]["Max Drawdown"]}</td>
            </tr>
            <tr>
              <td>% of Winning Mo.</td>
              <td>{data && data.returns[0]["% of Winning Mo."]}</td>
              <td>{data && data.hedge[0]["% of Winning Mo."]}</td>
            </tr>
            <tr>
              <td>Gini</td>
              <td>{data && data.returns[0]["Gini"]}</td>
              <td>{data && data.hedge[0]["Gini"]}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col>
        <Table striped bordered responsive className="stats-table">
          <thead>
            <tr>
              <th>Metric</th>
              <th>MQE Strategy</th>
              <th>HFRI 500</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Forecast Success Ratio</td>
              <td>{data && data.returns[0]["Forecast Success Ratio"]}</td>
              <td></td>
            </tr>
            <tr>
              <td>Up Alpha</td>
              <td>{data && data.returns[0]["Up Alpha"]}</td>
              <td>{data && data.hedge[0]["Up Alpha"]}</td>
            </tr>
            <tr>
              <td>Down Alpha</td>
              <td>{data && data.returns[0]["Down Alpha"]}</td>
              <td>{data && data.hedge[0]["Down Alpha"]}</td>
            </tr>
            <tr>
              <td>Up Beta</td>
              <td>{data && data.returns[0]["Up Beta"]}</td>
              <td>{data && data.hedge[0]["Up Beta"]}</td>
            </tr>
            <tr>
              <td>Down Beta</td>
              <td>{data && data.returns[0]["Down Beta"]}</td>
              <td>{data && data.hedge[0]["Down Beta"]}</td>
            </tr>
            <tr>
              <td>Highest Monthly Return</td>
              <td>{data && data.returns[0]["Highest Monthly Return"]}</td>
              <td>{data && data.hedge[0]["Highest Monthly Return"]}</td>
            </tr>
            <tr>
              <td>Lowest Monthly Return</td>
              <td>{data && data.returns[0]["Lowest Monthly Return"]}</td>
              <td>{data && data.hedge[0]["Lowest Monthly Return"]}</td>
            </tr>
            <tr>
              <td>Annualized STD of Returns</td>
              <td>{data && data.returns[0]["Annualized STD of Returns"]}</td>
              <td>{data && data.hedge[0]["Annualized STD of Returns"]}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    );
  }

  export default StatsTables;
