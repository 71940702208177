import React from 'react';
import {
  AreaChart, Label, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, ResponsiveContainer
} from 'recharts';
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../firebaseConfig";

function LiveAreaChartMQE(props) {
  const colorYaxis = props.theme === "light" ? null : "white";
  const [value] = useCollection(
    db.collection('assets/MQEPortfolio/graphdata'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  
  var filtered = value && value.docs;
  console.log(value && filtered.map(doc => (doc.data())));
  return (
    <>
       <ResponsiveContainer width="95%" height={400}>
        <AreaChart width={600} height={200} data={value && filtered.map(doc => (doc.data()))} margin={{top: 10, right: 30, left: 30, bottom: 30}}>
          <CartesianGrid strokeDasharray="3 3" />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="45%" stopColor="#FBC138" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#FBC138" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorKv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#005687" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#005687" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="Date" tick={{ textAnchor: 'end', angle: -30 }} height={80} style={{ fill: props.theme === "light" ? null : "white" }} >
            <Label value="Date" offset={-20} position="bottom"  style={{ fill: props.theme === "light" ? null : "white" }} />
          </XAxis>
          <YAxis style={{ fill: props.theme === "light" ? null : "white" }} label={{ fill: colorYaxis, value: 'Cumulative Profits in %', angle: -90, position: 'insideBottomLeft', x: -10 }}></YAxis>
          <Tooltip/>
          <Legend />
          <Brush dataKey="Date" height={25} stroke="#005687" />
          <Area connectNulls={true} type='monotone' dataKey='MQE Forex Portfolio' stroke='#005687'  fill='url(#colorKv)' />
          <Area connectNulls={true} type='monotone' dataKey='HFRI 500' stroke='#5a2cf4' fill='url(#colorUv)' />
        </AreaChart>
      </ResponsiveContainer>
    </>
    );
  }

  export default LiveAreaChartMQE;
