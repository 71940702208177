const data = [
  {
    "Date": "2007-01-01",
    "MQE Strategy": -0.41,
    "HFRI 500": 0.73,
    "S&P 500": 1.09
  },
  {
    "Date": "2007-02-01",
    "MQE Strategy": -0.83,
    "HFRI 500": 1.1,
    "S&P 500": -1.31
  },
  {
    "Date": "2007-03-01",
    "MQE Strategy": -1.73,
    "HFRI 500": 1.83,
    "S&P 500": -0.96
  },
  {
    "Date": "2007-04-01",
    "MQE Strategy": -1.87,
    "HFRI 500": 3.25,
    "S&P 500": 3.04
  },
  {
    "Date": "2007-05-01",
    "MQE Strategy": -2.92,
    "HFRI 500": 5.19,
    "S&P 500": 6.14
  },
  {
    "Date": "2007-06-01",
    "MQE Strategy": -3.24,
    "HFRI 500": 6.03,
    "S&P 500": 3.73
  },
  {
    "Date": "2007-07-01",
    "MQE Strategy": -3.84,
    "HFRI 500": 6.24,
    "S&P 500": 0.06
  },
  {
    "Date": "2007-08-01",
    "MQE Strategy": -4.89,
    "HFRI 500": 3.8,
    "S&P 500": 1.02
  },
  {
    "Date": "2007-09-01",
    "MQE Strategy": -5.1,
    "HFRI 500": 6.07,
    "S&P 500": 4.12
  },
  {
    "Date": "2007-10-01",
    "MQE Strategy": -5.34,
    "HFRI 500": 8.78,
    "S&P 500": 5.2
  },
  {
    "Date": "2007-11-01",
    "MQE Strategy": -4.8,
    "HFRI 500": 6.09,
    "S&P 500": 0.86
  },
  {
    "Date": "2007-12-01",
    "MQE Strategy": -5.66,
    "HFRI 500": 6.25,
    "S&P 500": -1.06
  },
  {
    "Date": "2008-01-01",
    "MQE Strategy": -6.32,
    "HFRI 500": 2.96,
    "S&P 500": -7.2
  },
  {
    "Date": "2008-02-01",
    "MQE Strategy": -6.35,
    "HFRI 500": 4.55,
    "S&P 500": -9.74
  },
  {
    "Date": "2008-03-01",
    "MQE Strategy": -6.28,
    "HFRI 500": 1.94,
    "S&P 500": -11.08
  },
  {
    "Date": "2008-04-01",
    "MQE Strategy": -6.4,
    "HFRI 500": 3.44,
    "S&P 500": -6.94
  },
  {
    "Date": "2008-05-01",
    "MQE Strategy": -6.97,
    "HFRI 500": 5.13,
    "S&P 500": -5.68
  },
  {
    "Date": "2008-06-01",
    "MQE Strategy": -6.64,
    "HFRI 500": 3.6,
    "S&P 500": -14.12
  },
  {
    "Date": "2008-07-01",
    "MQE Strategy": -7.5,
    "HFRI 500": 0.84,
    "S&P 500": -15.01
  },
  {
    "Date": "2008-08-01",
    "MQE Strategy": -7.16,
    "HFRI 500": -1.08,
    "S&P 500": -13.82
  },
  {
    "Date": "2008-09-01",
    "MQE Strategy": -4.04,
    "HFRI 500": -7.14,
    "S&P 500": -22.45
  },
  {
    "Date": "2008-10-01",
    "MQE Strategy": -1.83,
    "HFRI 500": -13.57,
    "S&P 500": -35.29
  },
  {
    "Date": "2008-11-01",
    "MQE Strategy": -1.7,
    "HFRI 500": -14.99,
    "S&P 500": -39.79
  },
  {
    "Date": "2008-12-01",
    "MQE Strategy": -3.04,
    "HFRI 500": -14.87,
    "S&P 500": -39.7
  },
  {
    "Date": "2009-01-01",
    "MQE Strategy": -3.13,
    "HFRI 500": -14.74,
    "S&P 500": -44.66
  },
  {
    "Date": "2009-02-01",
    "MQE Strategy": -3.34,
    "HFRI 500": -15.29,
    "S&P 500": -50.62
  },
  {
    "Date": "2009-03-01",
    "MQE Strategy": -4.19,
    "HFRI 500": -14.09,
    "S&P 500": -46.89
  },
  {
    "Date": "2009-04-01",
    "MQE Strategy": -4.35,
    "HFRI 500": -11.8,
    "S&P 500": -41.62
  },
  {
    "Date": "2009-05-01",
    "MQE Strategy": -4.59,
    "HFRI 500": -7.53,
    "S&P 500": -38.22
  },
  {
    "Date": "2009-06-01",
    "MQE Strategy": -6.11,
    "HFRI 500": -7.34,
    "S&P 500": -38.61
  },
  {
    "Date": "2009-07-01",
    "MQE Strategy": -8.77,
    "HFRI 500": -4.93,
    "S&P 500": -34.04
  },
  {
    "Date": "2009-08-01",
    "MQE Strategy": -9.52,
    "HFRI 500": -3.6,
    "S&P 500": -31.61
  },
  {
    "Date": "2009-09-01",
    "MQE Strategy": -7.76,
    "HFRI 500": -0.95,
    "S&P 500": -29.53
  },
  {
    "Date": "2009-10-01",
    "MQE Strategy": -7.77,
    "HFRI 500": -1.1,
    "S&P 500": -30.89
  },
  {
    "Date": "2009-11-01",
    "MQE Strategy": -6.7,
    "HFRI 500": 0.11,
    "S&P 500": -26.63
  },
  {
    "Date": "2009-12-01",
    "MQE Strategy": -4.96,
    "HFRI 500": 1.05,
    "S&P 500": -25.64
  },
  {
    "Date": "2010-01-01",
    "MQE Strategy": -3.42,
    "HFRI 500": 0.43,
    "S&P 500": -28.34
  },
  {
    "Date": "2010-02-01",
    "MQE Strategy": -3.65,
    "HFRI 500": 0.69,
    "S&P 500": -26.11
  },
  {
    "Date": "2010-03-01",
    "MQE Strategy": -3.36,
    "HFRI 500": 3.15,
    "S&P 500": -21.95
  },
  {
    "Date": "2010-04-01",
    "MQE Strategy": -3.38,
    "HFRI 500": 4.13,
    "S&P 500": -20.75
  },
  {
    "Date": "2010-05-01",
    "MQE Strategy": -3.01,
    "HFRI 500": 1.19,
    "S&P 500": -27.06
  },
  {
    "Date": "2010-06-01",
    "MQE Strategy": 0.68,
    "HFRI 500": 0.44,
    "S&P 500": -31.17
  },
  {
    "Date": "2010-07-01",
    "MQE Strategy": 0.57,
    "HFRI 500": 1.92,
    "S&P 500": -26.47
  },
  {
    "Date": "2010-08-01",
    "MQE Strategy": 2.76,
    "HFRI 500": 2.03,
    "S&P 500": -29.79
  },
  {
    "Date": "2010-09-01",
    "MQE Strategy": 3.28,
    "HFRI 500": 5.33,
    "S&P 500": -23.92
  },
  {
    "Date": "2010-10-01",
    "MQE Strategy": 6.73,
    "HFRI 500": 7.34,
    "S&P 500": -21.02
  },
  {
    "Date": "2010-11-01",
    "MQE Strategy": 9.19,
    "HFRI 500": 7.07,
    "S&P 500": -21.03
  },
  {
    "Date": "2010-12-01",
    "MQE Strategy": 10.39,
    "HFRI 500": 9.95,
    "S&P 500": -16.2
  },
  {
    "Date": "2011-01-01",
    "MQE Strategy": 9.56,
    "HFRI 500": 10.38,
    "S&P 500": -14.26
  },
  {
    "Date": "2011-02-01",
    "MQE Strategy": 13.59,
    "HFRI 500": 11.33,
    "S&P 500": -11.29
  },
  {
    "Date": "2011-03-01",
    "MQE Strategy": 15.72,
    "HFRI 500": 11.39,
    "S&P 500": -11.67
  },
  {
    "Date": "2011-04-01",
    "MQE Strategy": 17.64,
    "HFRI 500": 13.19,
    "S&P 500": -9.11
  },
  {
    "Date": "2011-05-01",
    "MQE Strategy": 17.02,
    "HFRI 500": 11.57,
    "S&P 500": -10.13
  },
  {
    "Date": "2011-06-01",
    "MQE Strategy": 18.1,
    "HFRI 500": 10.3,
    "S&P 500": -12.09
  },
  {
    "Date": "2011-07-01",
    "MQE Strategy": 18.61,
    "HFRI 500": 10.78,
    "S&P 500": -13.85
  },
  {
    "Date": "2011-08-01",
    "MQE Strategy": 20.77,
    "HFRI 500": 7.65,
    "S&P 500": -18.59
  },
  {
    "Date": "2011-09-01",
    "MQE Strategy": 21.69,
    "HFRI 500": 4.21,
    "S&P 500": -24.63
  },
  {
    "Date": "2011-10-01",
    "MQE Strategy": 24.42,
    "HFRI 500": 6.52,
    "S&P 500": -16.41
  },
  {
    "Date": "2011-11-01",
    "MQE Strategy": 23.14,
    "HFRI 500": 5.29,
    "S&P 500": -16.75
  },
  {
    "Date": "2011-12-01",
    "MQE Strategy": 24.03,
    "HFRI 500": 4.9,
    "S&P 500": -16.41
  },
  {
    "Date": "2012-01-01",
    "MQE Strategy": 25.28,
    "HFRI 500": 7.33,
    "S&P 500": -12.53
  },
  {
    "Date": "2012-02-01",
    "MQE Strategy": 26.8,
    "HFRI 500": 9.37,
    "S&P 500": -8.74
  },
  {
    "Date": "2012-03-01",
    "MQE Strategy": 27.01,
    "HFRI 500": 9.11,
    "S&P 500": -6.22
  },
  {
    "Date": "2012-04-01",
    "MQE Strategy": 25.93,
    "HFRI 500": 8.82,
    "S&P 500": -6.86
  },
  {
    "Date": "2012-05-01",
    "MQE Strategy": 26.37,
    "HFRI 500": 6.53,
    "S&P 500": -12.46
  },
  {
    "Date": "2012-06-01",
    "MQE Strategy": 28.08,
    "HFRI 500": 6.47,
    "S&P 500": -9.38
  },
  {
    "Date": "2012-07-01",
    "MQE Strategy": 29.02,
    "HFRI 500": 7.59,
    "S&P 500": -8.31
  },
  {
    "Date": "2012-08-01",
    "MQE Strategy": 29.16,
    "HFRI 500": 8.25,
    "S&P 500": -6.02
  },
  {
    "Date": "2012-09-01",
    "MQE Strategy": 29.43,
    "HFRI 500": 9.41,
    "S&P 500": -4.16
  },
  {
    "Date": "2012-10-01",
    "MQE Strategy": 30.75,
    "HFRI 500": 9.07,
    "S&P 500": -5.91
  },
  {
    "Date": "2012-11-01",
    "MQE Strategy": 30.4,
    "HFRI 500": 9.64,
    "S&P 500": -5.39
  },
  {
    "Date": "2012-12-01",
    "MQE Strategy": 30.04,
    "HFRI 500": 11.32,
    "S&P 500": -5.22
  },
  {
    "Date": "2013-01-01",
    "MQE Strategy": 30.81,
    "HFRI 500": 14.32,
    "S&P 500": -0.37
  },
  {
    "Date": "2013-02-01",
    "MQE Strategy": 32.38,
    "HFRI 500": 14.66,
    "S&P 500": 0.89
  },
  {
    "Date": "2013-03-01",
    "MQE Strategy": 31.41,
    "HFRI 500": 15.72,
    "S&P 500": 4.26
  },
  {
    "Date": "2013-04-01",
    "MQE Strategy": 33.12,
    "HFRI 500": 16.84,
    "S&P 500": 6.26
  },
  {
    "Date": "2013-05-01",
    "MQE Strategy": 31,
    "HFRI 500": 17.55,
    "S&P 500": 8.76
  },
  {
    "Date": "2013-06-01",
    "MQE Strategy": 32.49,
    "HFRI 500": 15.63,
    "S&P 500": 6.74
  },
  {
    "Date": "2013-07-01",
    "MQE Strategy": 35.31,
    "HFRI 500": 17.13,
    "S&P 500": 12.26
  },
  {
    "Date": "2013-08-01",
    "MQE Strategy": 34.61,
    "HFRI 500": 16.4,
    "S&P 500": 8.89
  },
  {
    "Date": "2013-09-01",
    "MQE Strategy": 34.97,
    "HFRI 500": 18.14,
    "S&P 500": 11.79
  },
  {
    "Date": "2013-10-01",
    "MQE Strategy": 34.91,
    "HFRI 500": 20.12,
    "S&P 500": 16.96
  },
  {
    "Date": "2013-11-01",
    "MQE Strategy": 35.45,
    "HFRI 500": 21.52,
    "S&P 500": 20.42
  },
  {
    "Date": "2013-12-01",
    "MQE Strategy": 35.86,
    "HFRI 500": 22.86,
    "S&P 500": 22.87
  },
  {
    "Date": "2014-01-01",
    "MQE Strategy": 37.09,
    "HFRI 500": 22.21,
    "S&P 500": 18.54
  },
  {
    "Date": "2014-02-01",
    "MQE Strategy": 35.39,
    "HFRI 500": 24.27,
    "S&P 500": 23.93
  },
  {
    "Date": "2014-03-01",
    "MQE Strategy": 36.43,
    "HFRI 500": 23.57,
    "S&P 500": 24.41
  },
  {
    "Date": "2014-04-01",
    "MQE Strategy": 35.65,
    "HFRI 500": 22.95,
    "S&P 500": 25.27
  },
  {
    "Date": "2014-05-01",
    "MQE Strategy": 36.03,
    "HFRI 500": 24.46,
    "S&P 500": 28.17
  },
  {
    "Date": "2014-06-01",
    "MQE Strategy": 35.62,
    "HFRI 500": 25.85,
    "S&P 500": 30.19
  },
  {
    "Date": "2014-07-01",
    "MQE Strategy": 35.81,
    "HFRI 500": 25.34,
    "S&P 500": 28.44
  },
  {
    "Date": "2014-08-01",
    "MQE Strategy": 37.05,
    "HFRI 500": 26.94,
    "S&P 500": 33.51
  },
  {
    "Date": "2014-09-01",
    "MQE Strategy": 37.78,
    "HFRI 500": 26.75,
    "S&P 500": 31.05
  },
  {
    "Date": "2014-10-01",
    "MQE Strategy": 41.87,
    "HFRI 500": 26.42,
    "S&P 500": 34.14
  },
  {
    "Date": "2014-11-01",
    "MQE Strategy": 43.37,
    "HFRI 500": 28.05,
    "S&P 500": 37.82
  },
  {
    "Date": "2014-12-01",
    "MQE Strategy": 46,
    "HFRI 500": 28.14,
    "S&P 500": 36.71
  },
  {
    "Date": "2015-01-01",
    "MQE Strategy": 48.24,
    "HFRI 500": 28.8,
    "S&P 500": 32.66
  },
  {
    "Date": "2015-02-01",
    "MQE Strategy": 48.81,
    "HFRI 500": 31.04,
    "S&P 500": 40.12
  },
  {
    "Date": "2015-03-01",
    "MQE Strategy": 49.61,
    "HFRI 500": 31.83,
    "S&P 500": 37.3
  },
  {
    "Date": "2015-04-01",
    "MQE Strategy": 51.41,
    "HFRI 500": 33.02,
    "S&P 500": 38.65
  },
  {
    "Date": "2015-05-01",
    "MQE Strategy": 50.29,
    "HFRI 500": 34.16,
    "S&P 500": 40.43
  },
  {
    "Date": "2015-06-01",
    "MQE Strategy": 50.67,
    "HFRI 500": 31.98,
    "S&P 500": 36.91
  },
  {
    "Date": "2015-07-01",
    "MQE Strategy": 51.33,
    "HFRI 500": 32.17,
    "S&P 500": 40
  },
  {
    "Date": "2015-08-01",
    "MQE Strategy": 52.26,
    "HFRI 500": 29.14,
    "S&P 500": 31.47
  },
  {
    "Date": "2015-09-01",
    "MQE Strategy": 53.38,
    "HFRI 500": 27.53,
    "S&P 500": 27.45
  },
  {
    "Date": "2015-10-01",
    "MQE Strategy": 53.11,
    "HFRI 500": 29.33,
    "S&P 500": 38.28
  },
  {
    "Date": "2015-11-01",
    "MQE Strategy": 55.95,
    "HFRI 500": 29.69,
    "S&P 500": 38.77
  },
  {
    "Date": "2015-12-01",
    "MQE Strategy": 59.07,
    "HFRI 500": 28.79,
    "S&P 500": 35.55
  },
  {
    "Date": "2016-01-01",
    "MQE Strategy": 61.34,
    "HFRI 500": 25.7,
    "S&P 500": 28.76
  },
  {
    "Date": "2016-02-01",
    "MQE Strategy": 61.83,
    "HFRI 500": 25.02,
    "S&P 500": 28.62
  },
  {
    "Date": "2016-03-01",
    "MQE Strategy": 62.79,
    "HFRI 500": 26.84,
    "S&P 500": 36.55
  },
  {
    "Date": "2016-04-01",
    "MQE Strategy": 67.69,
    "HFRI 500": 27.4,
    "S&P 500": 37.07
  },
  {
    "Date": "2016-05-01",
    "MQE Strategy": 69.43,
    "HFRI 500": 28.08,
    "S&P 500": 39.37
  },
  {
    "Date": "2016-06-01",
    "MQE Strategy": 70.86,
    "HFRI 500": 27.98,
    "S&P 500": 39.1
  },
  {
    "Date": "2016-07-01",
    "MQE Strategy": 76.21,
    "HFRI 500": 29.99,
    "S&P 500": 44.14
  },
  {
    "Date": "2016-08-01",
    "MQE Strategy": 77.02,
    "HFRI 500": 30.36,
    "S&P 500": 44.28
  },
  {
    "Date": "2016-09-01",
    "MQE Strategy": 78.01,
    "HFRI 500": 31.02,
    "S&P 500": 43.53
  },
  {
    "Date": "2016-10-01",
    "MQE Strategy": 75.92,
    "HFRI 500": 30.29,
    "S&P 500": 41.01
  },
  {
    "Date": "2016-11-01",
    "MQE Strategy": 80.19,
    "HFRI 500": 30.79,
    "S&P 500": 46.15
  },
  {
    "Date": "2016-12-01",
    "MQE Strategy": 86.26,
    "HFRI 500": 31.86,
    "S&P 500": 48.18
  },
  {
    "Date": "2017-01-01",
    "MQE Strategy": 88.75,
    "HFRI 500": 33.24,
    "S&P 500": 50.77
  },
  {
    "Date": "2017-02-01",
    "MQE Strategy": 93.84,
    "HFRI 500": 34.6,
    "S&P 500": 56.63
  },
  {
    "Date": "2017-03-01",
    "MQE Strategy": 94.89,
    "HFRI 500": 35.09,
    "S&P 500": 56.05
  },
  {
    "Date": "2017-04-01",
    "MQE Strategy": 98.44,
    "HFRI 500": 35.88,
    "S&P 500": 57.49
  },
  {
    "Date": "2017-05-01",
    "MQE Strategy": 99.85,
    "HFRI 500": 36.44,
    "S&P 500": 59.59
  },
  {
    "Date": "2017-06-01",
    "MQE Strategy": 101.89,
    "HFRI 500": 36.6,
    "S&P 500": 59.7
  },
  {
    "Date": "2017-07-01",
    "MQE Strategy": 107.14,
    "HFRI 500": 38.04,
    "S&P 500": 62.84
  },
  {
    "Date": "2017-08-01",
    "MQE Strategy": 113.55,
    "HFRI 500": 39.03,
    "S&P 500": 63.18
  },
  {
    "Date": "2017-09-01",
    "MQE Strategy": 113.78,
    "HFRI 500": 39.61,
    "S&P 500": 65.51
  },
  {
    "Date": "2017-10-01",
    "MQE Strategy": 116.75,
    "HFRI 500": 41.54,
    "S&P 500": 69.25
  },
  {
    "Date": "2017-11-01",
    "MQE Strategy": 118.82,
    "HFRI 500": 41.79,
    "S&P 500": 74.25
  },
  {
    "Date": "2017-12-01",
    "MQE Strategy": 118.43,
    "HFRI 500": 42.8,
    "S&P 500": 75.27
  },
  {
    "Date": "2018-01-01",
    "MQE Strategy": 121.17,
    "HFRI 500": 46.45,
    "S&P 500": 84.94
  },
  {
    "Date": "2018-02-01",
    "MQE Strategy": 121.36,
    "HFRI 500": 43.74,
    "S&P 500": 77.97
  },
  {
    "Date": "2018-03-01",
    "MQE Strategy": 121.26,
    "HFRI 500": 42.93,
    "S&P 500": 72.15
  },
  {
    "Date": "2018-04-01",
    "MQE Strategy": 121.04,
    "HFRI 500": 43.05,
    "S&P 500": 72.79
  },
  {
    "Date": "2018-05-01",
    "MQE Strategy": 126.04,
    "HFRI 500": 43.43,
    "S&P 500": 76.72
  },
  {
    "Date": "2018-06-01",
    "MQE Strategy": 129.94,
    "HFRI 500": 42.62,
    "S&P 500": 76.67
  },
  {
    "Date": "2018-07-01",
    "MQE Strategy": 131.51,
    "HFRI 500": 42.81,
    "S&P 500": 82.92
  },
  {
    "Date": "2018-08-01",
    "MQE Strategy": 132.32,
    "HFRI 500": 42.5,
    "S&P 500": 88.45
  },
  {
    "Date": "2018-09-01",
    "MQE Strategy": 133.03,
    "HFRI 500": 42.11,
    "S&P 500": 88.37
  },
  {
    "Date": "2018-10-01",
    "MQE Strategy": 133.45,
    "HFRI 500": 38.08,
    "S&P 500": 75
  },
  {
    "Date": "2018-11-01",
    "MQE Strategy": 135.84,
    "HFRI 500": 37.07,
    "S&P 500": 77.91
  },
  {
    "Date": "2018-12-01",
    "MQE Strategy": 137.53,
    "HFRI 500": 34.93,
    "S&P 500": 60.96
  },
  {
    "Date": "2019-01-01",
    "MQE Strategy": 140.87,
    "HFRI 500": 38.55,
    "S&P 500": 73.54
  },
  {
    "Date": "2019-02-01",
    "MQE Strategy": 143.78,
    "HFRI 500": 39.58,
    "S&P 500": 78.82
  },
  {
    "Date": "2019-03-01",
    "MQE Strategy": 143.94,
    "HFRI 500": 40.42,
    "S&P 500": 80.91
  },
  {
    "Date": "2019-04-01",
    "MQE Strategy": 143.21,
    "HFRI 500": 41.92,
    "S&P 500": 87.95
  },
  {
    "Date": "2019-05-01",
    "MQE Strategy": 143.88,
    "HFRI 500": 40.06,
    "S&P 500": 75.6
  },
  {
    "Date": "2019-06-01",
    "MQE Strategy": 143.43,
    "HFRI 500": 42.43,
    "S&P 500": 86.61
  },
  {
    "Date": "2019-07-01",
    "MQE Strategy": 145.83,
    "HFRI 500": 43.05,
    "S&P 500": 89.12
  },
  {
    "Date": "2019-08-01",
    "MQE Strategy": 150.04,
    "HFRI 500": 41.95,
    "S&P 500": 85.65
  },
  {
    "Date": "2019-09-01",
    "MQE Strategy": 150.67,
    "HFRI 500": 41.54,
    "S&P 500": 88.12
  },
  {
    "Date": "2019-10-01",
    "MQE Strategy": 152.6,
    "HFRI 500": 42.09,
    "S&P 500": 92.04
  },
  {
    "Date": "2019-11-01",
    "MQE Strategy": 156.12,
    "HFRI 500": 43.09,
    "S&P 500": 98.75
  },
  {
    "Date": "2019-12-01",
    "MQE Strategy": 156.02,
    "HFRI 500": 45.41,
    "S&P 500": 103.27
  },
  {
    "Date": "2020-01-01",
    "MQE Strategy": 155.56,
    "HFRI 500": 44.89,
    "S&P 500": 102.94
  },
  {
    "Date": "2020-02-01",
    "MQE Strategy": 156.46,
    "HFRI 500": 41.99,
    "S&P 500": 86.66
  },
  {
    "Date": "2020-03-01",
    "MQE Strategy": 153.36,
    "HFRI 500": 30.7,
    "S&P 500": 62.39
  },
  {
    "Date": "2020-04-01",
    "MQE Strategy": 180.98,
    "HFRI 500": 36.1,
    "S&P 500": 83
  },
  {
    "Date": "2020-05-01",
    "MQE Strategy": 184.04,
    "HFRI 500": 38.89,
    "S&P 500": 91.7
  },
  {
    "Date": "2020-06-01",
    "MQE Strategy": 183.32,
    "HFRI 500": 41.19,
    "S&P 500": 94.23
  },
  {
    "Date": "2020-07-01",
    "MQE Strategy": 181.66,
    "HFRI 500": 44.88,
    "S&P 500": 105.65
  },
  {
    "Date": "2020-08-01",
    "MQE Strategy": 185.62,
    "HFRI 500": 47.67,
    "S&P 500": 119.99
  },
  {
    "Date": "2020-09-01",
    "MQE Strategy": 187.81,
    "HFRI 500": 46.23,
    "S&P 500": 110.89
  },
  {
    "Date": "2020-10-01",
    "MQE Strategy": 191.77,
    "HFRI 500": 46.35,
    "S&P 500": 105.62
  },
  {
    "Date": "2020-11-01",
    "MQE Strategy": 194.42,
    "HFRI 500": 54.08,
    "S&P 500": 127.97
  },
  {
    "Date": "2020-12-01",
    "MQE Strategy": 190.47,
    "HFRI 500": 60.37,
    "S&P 500": 135.4
  },
  {
    "Date": "2021-01-01",
    "MQE Strategy": 194.77,
    "HFRI 500": 61.75,
    "S&P 500": 133
  },
  {
    "Date": "2021-02-01",
    "MQE Strategy": 195.19,
    "HFRI 500": 67.08,
    "S&P 500": 139.47
  },
  {
    "Date": "2021-03-01",
    "MQE Strategy": 201.36,
    "HFRI 500": 68.15,
    "S&P 500": 149.52
  }
]

export default data;