import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import { useCollection } from 'react-firebase-hooks/firestore';
import {db} from '../firebaseConfig'

function RecentForecastTable(props) {

  const [value] = useCollection(
    db.collection('assets/'+props.id+'/forecasts').orderBy("createdAt","desc"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  var filtered = value && value.docs.filter(doc => doc.data().horizon === (props.forecastTerm === 12 ? 12 : props.forecastTerm === 1 ? 1 : null));

  return (
    <Row className="p-3">
      <Col>
        <Table striped bordered responsive className="forecasts-table">
          <thead>
            <tr>
              <th>Signal</th>
              <th>Return</th>
              <th>Date of Signal</th>
              <th>End of Signal</th>
            </tr>
          </thead>
          <tbody>
          {value && filtered.length > 0 ? (
            <>
                {filtered.map(doc => (
                  <tr key={doc.id}>
                    <td><span className={doc.data().signal === 1 ? "mb-4 green-text" : doc.data().signal === -1 ? "mb-4 red-text" : "mb-4"}>{doc.data().signal === 1 ? "LONG" : doc.data().signal === -1 ? "SHORT" : "NA"}</span></td>
                    <td><span className={parseFloat(doc.data().interest) >= 0 ? "green-text" : "red-text"}>{doc.data().interest}%</span></td>
                    <td>{doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000).toLocaleDateString("en-US") : 'NA'}</td>
                    <td>{doc.data().exitedAt ? new Date(doc.data().exitedAt.seconds * 1000).toLocaleDateString("en-US") : ''}</td>
                  </tr>
                ))}
            </>
          ) : (
            <tr>
              <td colSpan="4">Coming Soon</td>
            </tr>
          )}
          </tbody>
        </Table>
      </Col>
    </Row>
    );
  }

  export default RecentForecastTable;
