const data = {
  returns: [{
    "Sharpe": 1.46,
    "Annualized Return": "8.261%",
    "Forecast Success Ratio": "54.4%",
    "Avg. Annual Return": 8.261,
    "Market Beta": 0.03,
    "Monthly Alpha": "0.64%",
    "Correlation with S&P": 0.1,
    "Max Drawdown": "-9.52%",
    "% of Winning Mo.": "67.25%",
    "Gini": 0.433,
    "Forecast Accuracy": "",
    "Up Alpha": "0.29%",
    "Down Alpha": "0.41%",
    "Up Beta": 0.13,
    "Down Beta": -0.04,
    "Highest Monthly Return": 10.9,
    "Lowest Monthly Return": -2.83,
    "Annualized STD of Returns": "4.81%"
  }],
  hedge : [{
    "Sharpe": 0.44,
    "Annualized Return": "3.792%",
    "Avg. Annual Return": 3.792,
    "Market Beta": 0.33,
    "Monthly Alpha": "0.11%",
    "Correlation with S&P": 0.82,
    "Max Drawdown": "-22.13%",
    "% of Winning Mo.": "66.08%",
    "Gini": 0.426,
    "Forecast Accuracy": "",
    "Up Alpha": "0.19%",
    "Down Alpha": "0.22%",
    "Up Beta": 0.3,
    "Down Beta": 0.36,
    "Highest Monthly Return": 5.28,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "6.19%"
  }]
}

export default data;