const data = {
  returns: [{
    "Sharpe": 0.22,
    "Annualized Return": "1.87%",
    "Forecast Success Ratio": 0.5,
    "Avg. Annual Return": 1.87,
    "Market Beta": 0.0,
    "Monthly Alpha": "0.14%",
    "Correlation with S&P": -0.02,
    "Max Drawdown": "-9.62%",
    "% of Winning Mo.": "49.46%",
    "Gini": 0.416,
    "Forecast Accuracy": "0.5",
    "Up Alpha": "-0.01%",
    "Down Alpha": "0.40%",
    "Up Beta": 0.03,
    "Down Beta": 0.03,
    "Highest Monthly Return": 3.0,
    "Lowest Monthly Return": -4.16,
    "Annualized STD of Returns": "2.97%"
  }],
  hedge : [{
    "Sharpe": 0.5,
    "Annualized Return": "3.93%",
    "Avg. Annual Return": 3.93,
    "Market Beta": 0.33,
    "Monthly Alpha": "0.11%",
    "Correlation with S&P": 0.81,
    "Max Drawdown": "-22.13%",
    "% of Winning Mo.": "66.13%",
    "Gini": 0.433,
    "Forecast Accuracy": "",
    "Up Alpha": "0.20%",
    "Down Alpha": "0.19%",
    "Up Beta": 0.3,
    "Down Beta": 0.35,
    "Highest Monthly Return": 5.28,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "6.04%"
  }]
}

export default data;