export const lightTheme = {
  body: "#FFF",
  text: "#363537",
  equation: "#363537",
  tabText: "black",
  heading: "005687",
  table: "#212529",
  nav: "linear-gradient(-180deg, #284476 0, #506489 100%) !important",
  toggleBorder: "#FFF",
  background: "linear-gradient(#39598A, #79D7ED)",
  footer: "background: linear-gradient(360deg, #284476 0, #506489 100%)",
  arrowColor: "#005687",
  cardBody: "white",
  cardSub: "black",
  card: "white",
  maeCurrencyName: "#5A5959",
  colorTh: "black",
  backgroundThOdd: "#FFFFFF",
  colorThOdd: "#f2f5f9",
  tableHeading: "white",
  colorPrimary: 'black',
  toggleButton: '#005687'
};

export const darkTheme = {
  body: "#233447",
  text: "#FAFAFA",
  equation: "#FAFAFA",
  tabText: "#FAFAFA",
  heading: "white",
  table: "black",
  nav: "#1d2b3a",
  toggleBorder: "#6B8096",
  background: "#999",
  footer: "#1d2b3a",
  arrowColor: "#FAFAFA",
  cardBody: "#1d2b3a",
  cardSub: "white",
  card: "#1d2b3a",
  maeCurrencyName: "white",
  colorTh: "white",
  colorThOdd: "#243546",
  tableHeading: "#005687",
  colorPrimary: "white",
  toggleButton: 'white'
};
