const data = [
  {
    "Date": "2009-08-01",
    "MQE Strategy": 0,
    "HFRI 500": 1.41,
    "S&P 500": 3.69
  },
  {
    "Date": "2009-09-01",
    "MQE Strategy": 0,
    "HFRI 500": 4.21,
    "S&P 500": 6.86
  },
  {
    "Date": "2009-10-01",
    "MQE Strategy": 0,
    "HFRI 500": 4.05,
    "S&P 500": 4.81
  },
  {
    "Date": "2009-11-01",
    "MQE Strategy": -0.38,
    "HFRI 500": 5.33,
    "S&P 500": 11.26
  },
  {
    "Date": "2009-12-01",
    "MQE Strategy": 2.15,
    "HFRI 500": 6.32,
    "S&P 500": 12.78
  },
  {
    "Date": "2010-01-01",
    "MQE Strategy": 0.18,
    "HFRI 500": 5.67,
    "S&P 500": 8.68
  },
  {
    "Date": "2010-02-01",
    "MQE Strategy": -1.42,
    "HFRI 500": 5.96,
    "S&P 500": 12.07
  },
  {
    "Date": "2010-03-01",
    "MQE Strategy": -3.63,
    "HFRI 500": 8.57,
    "S&P 500": 18.41
  },
  {
    "Date": "2010-04-01",
    "MQE Strategy": -1.93,
    "HFRI 500": 9.61,
    "S&P 500": 20.24
  },
  {
    "Date": "2010-05-01",
    "MQE Strategy": -5.65,
    "HFRI 500": 6.53,
    "S&P 500": 10.69
  },
  {
    "Date": "2010-06-01",
    "MQE Strategy": -5.93,
    "HFRI 500": 5.75,
    "S&P 500": 4.46
  },
  {
    "Date": "2010-07-01",
    "MQE Strategy": -7.99,
    "HFRI 500": 7.33,
    "S&P 500": 11.6
  },
  {
    "Date": "2010-08-01",
    "MQE Strategy": -8.41,
    "HFRI 500": 7.45,
    "S&P 500": 6.58
  },
  {
    "Date": "2010-09-01",
    "MQE Strategy": -5.74,
    "HFRI 500": 10.94,
    "S&P 500": 15.5
  },
  {
    "Date": "2010-10-01",
    "MQE Strategy": 1.25,
    "HFRI 500": 13.07,
    "S&P 500": 19.92
  },
  {
    "Date": "2010-11-01",
    "MQE Strategy": 5.67,
    "HFRI 500": 12.8,
    "S&P 500": 19.92
  },
  {
    "Date": "2010-12-01",
    "MQE Strategy": 6.78,
    "HFRI 500": 15.84,
    "S&P 500": 27.26
  },
  {
    "Date": "2011-01-01",
    "MQE Strategy": 4.14,
    "HFRI 500": 16.32,
    "S&P 500": 30.23
  },
  {
    "Date": "2011-02-01",
    "MQE Strategy": 6.92,
    "HFRI 500": 17.33,
    "S&P 500": 34.75
  },
  {
    "Date": "2011-03-01",
    "MQE Strategy": 10.33,
    "HFRI 500": 17.4,
    "S&P 500": 34.19
  },
  {
    "Date": "2011-04-01",
    "MQE Strategy": 15.84,
    "HFRI 500": 19.3,
    "S&P 500": 38.07
  },
  {
    "Date": "2011-05-01",
    "MQE Strategy": 16.07,
    "HFRI 500": 17.59,
    "S&P 500": 36.52
  },
  {
    "Date": "2011-06-01",
    "MQE Strategy": 17.42,
    "HFRI 500": 16.25,
    "S&P 500": 33.56
  },
  {
    "Date": "2011-07-01",
    "MQE Strategy": 19.02,
    "HFRI 500": 16.78,
    "S&P 500": 30.89
  },
  {
    "Date": "2011-08-01",
    "MQE Strategy": 25.21,
    "HFRI 500": 13.47,
    "S&P 500": 23.69
  },
  {
    "Date": "2011-09-01",
    "MQE Strategy": 27.29,
    "HFRI 500": 9.85,
    "S&P 500": 14.51
  },
  {
    "Date": "2011-10-01",
    "MQE Strategy": 25.72,
    "HFRI 500": 12.28,
    "S&P 500": 27.01
  },
  {
    "Date": "2011-11-01",
    "MQE Strategy": 25.83,
    "HFRI 500": 10.99,
    "S&P 500": 26.5
  },
  {
    "Date": "2011-12-01",
    "MQE Strategy": 28.22,
    "HFRI 500": 10.58,
    "S&P 500": 27.01
  },
  {
    "Date": "2012-01-01",
    "MQE Strategy": 27.61,
    "HFRI 500": 13.14,
    "S&P 500": 32.9
  },
  {
    "Date": "2012-02-01",
    "MQE Strategy": 29.98,
    "HFRI 500": 15.3,
    "S&P 500": 38.67
  },
  {
    "Date": "2012-03-01",
    "MQE Strategy": 29.58,
    "HFRI 500": 15.04,
    "S&P 500": 42.51
  },
  {
    "Date": "2012-04-01",
    "MQE Strategy": 30.32,
    "HFRI 500": 14.74,
    "S&P 500": 41.55
  },
  {
    "Date": "2012-05-01",
    "MQE Strategy": 25.52,
    "HFRI 500": 12.33,
    "S&P 500": 33.05
  },
  {
    "Date": "2012-06-01",
    "MQE Strategy": 24.45,
    "HFRI 500": 12.27,
    "S&P 500": 37.74
  },
  {
    "Date": "2012-07-01",
    "MQE Strategy": 23.98,
    "HFRI 500": 13.46,
    "S&P 500": 39.37
  },
  {
    "Date": "2012-08-01",
    "MQE Strategy": 25.24,
    "HFRI 500": 14.17,
    "S&P 500": 42.86
  },
  {
    "Date": "2012-09-01",
    "MQE Strategy": 24.89,
    "HFRI 500": 15.4,
    "S&P 500": 45.7
  },
  {
    "Date": "2012-10-01",
    "MQE Strategy": 24.21,
    "HFRI 500": 15.05,
    "S&P 500": 43.05
  },
  {
    "Date": "2012-11-01",
    "MQE Strategy": 25.54,
    "HFRI 500": 15.66,
    "S&P 500": 43.86
  },
  {
    "Date": "2012-12-01",
    "MQE Strategy": 25.54,
    "HFRI 500": 17.43,
    "S&P 500": 44.13
  },
  {
    "Date": "2013-01-01",
    "MQE Strategy": 24.73,
    "HFRI 500": 20.6,
    "S&P 500": 51.5
  },
  {
    "Date": "2013-02-01",
    "MQE Strategy": 27.56,
    "HFRI 500": 20.98,
    "S&P 500": 53.44
  },
  {
    "Date": "2013-03-01",
    "MQE Strategy": 28.62,
    "HFRI 500": 22.1,
    "S&P 500": 58.56
  },
  {
    "Date": "2013-04-01",
    "MQE Strategy": 32.62,
    "HFRI 500": 23.29,
    "S&P 500": 61.6
  },
  {
    "Date": "2013-05-01",
    "MQE Strategy": 29.62,
    "HFRI 500": 24.04,
    "S&P 500": 65.42
  },
  {
    "Date": "2013-06-01",
    "MQE Strategy": 32.65,
    "HFRI 500": 22.02,
    "S&P 500": 62.35
  },
  {
    "Date": "2013-07-01",
    "MQE Strategy": 38.57,
    "HFRI 500": 23.6,
    "S&P 500": 70.74
  },
  {
    "Date": "2013-08-01",
    "MQE Strategy": 36.47,
    "HFRI 500": 22.84,
    "S&P 500": 65.62
  },
  {
    "Date": "2013-09-01",
    "MQE Strategy": 38.34,
    "HFRI 500": 24.67,
    "S&P 500": 70.03
  },
  {
    "Date": "2013-10-01",
    "MQE Strategy": 38.17,
    "HFRI 500": 26.76,
    "S&P 500": 77.91
  },
  {
    "Date": "2013-11-01",
    "MQE Strategy": 38.17,
    "HFRI 500": 28.25,
    "S&P 500": 83.18
  },
  {
    "Date": "2013-12-01",
    "MQE Strategy": 38.17,
    "HFRI 500": 29.67,
    "S&P 500": 86.91
  },
  {
    "Date": "2014-01-01",
    "MQE Strategy": 41.35,
    "HFRI 500": 28.98,
    "S&P 500": 80.33
  },
  {
    "Date": "2014-02-01",
    "MQE Strategy": 38.4,
    "HFRI 500": 31.16,
    "S&P 500": 88.53
  },
  {
    "Date": "2014-03-01",
    "MQE Strategy": 37.89,
    "HFRI 500": 30.43,
    "S&P 500": 89.26
  },
  {
    "Date": "2014-04-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 29.78,
    "S&P 500": 90.58
  },
  {
    "Date": "2014-05-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 31.37,
    "S&P 500": 95
  },
  {
    "Date": "2014-06-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 32.84,
    "S&P 500": 98.08
  },
  {
    "Date": "2014-07-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 32.3,
    "S&P 500": 95.42
  },
  {
    "Date": "2014-08-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 33.99,
    "S&P 500": 103.13
  },
  {
    "Date": "2014-09-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 33.79,
    "S&P 500": 99.39
  },
  {
    "Date": "2014-10-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 33.44,
    "S&P 500": 104.09
  },
  {
    "Date": "2014-11-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 35.16,
    "S&P 500": 109.7
  },
  {
    "Date": "2014-12-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 35.27,
    "S&P 500": 108.02
  },
  {
    "Date": "2015-01-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 35.96,
    "S&P 500": 101.85
  },
  {
    "Date": "2015-02-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 38.33,
    "S&P 500": 113.2
  },
  {
    "Date": "2015-03-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 39.17,
    "S&P 500": 108.92
  },
  {
    "Date": "2015-04-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 40.42,
    "S&P 500": 110.97
  },
  {
    "Date": "2015-05-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 41.63,
    "S&P 500": 113.68
  },
  {
    "Date": "2015-06-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 39.32,
    "S&P 500": 108.33
  },
  {
    "Date": "2015-07-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 39.53,
    "S&P 500": 113.04
  },
  {
    "Date": "2015-08-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 36.34,
    "S&P 500": 100.05
  },
  {
    "Date": "2015-09-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 34.63,
    "S&P 500": 93.94
  },
  {
    "Date": "2015-10-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 36.54,
    "S&P 500": 110.43
  },
  {
    "Date": "2015-11-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 36.94,
    "S&P 500": 111.2
  },
  {
    "Date": "2015-12-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 36.01,
    "S&P 500": 106.33
  },
  {
    "Date": "2016-01-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 32.79,
    "S&P 500": 96.05
  },
  {
    "Date": "2016-02-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 32.1,
    "S&P 500": 95.89
  },
  {
    "Date": "2016-03-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 34.04,
    "S&P 500": 108
  },
  {
    "Date": "2016-04-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 34.65,
    "S&P 500": 108.81
  },
  {
    "Date": "2016-05-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 35.41,
    "S&P 500": 112.37
  },
  {
    "Date": "2016-06-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 35.33,
    "S&P 500": 112
  },
  {
    "Date": "2016-07-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 37.48,
    "S&P 500": 119.73
  },
  {
    "Date": "2016-08-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 37.9,
    "S&P 500": 120
  },
  {
    "Date": "2016-09-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 38.64,
    "S&P 500": 118.9
  },
  {
    "Date": "2016-10-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 37.9,
    "S&P 500": 115.11
  },
  {
    "Date": "2016-11-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 38.48,
    "S&P 500": 123.03
  },
  {
    "Date": "2016-12-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 39.67,
    "S&P 500": 126.22
  },
  {
    "Date": "2017-01-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 41.19,
    "S&P 500": 130.27
  },
  {
    "Date": "2017-02-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 42.69,
    "S&P 500": 139.32
  },
  {
    "Date": "2017-03-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 43.3,
    "S&P 500": 138.58
  },
  {
    "Date": "2017-04-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 44.24,
    "S&P 500": 140.95
  },
  {
    "Date": "2017-05-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 44.94,
    "S&P 500": 144.35
  },
  {
    "Date": "2017-06-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 45.23,
    "S&P 500": 144.71
  },
  {
    "Date": "2017-07-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 46.89,
    "S&P 500": 149.74
  },
  {
    "Date": "2017-08-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 48.06,
    "S&P 500": 150.47
  },
  {
    "Date": "2017-09-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 48.8,
    "S&P 500": 154.26
  },
  {
    "Date": "2017-10-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 51.01,
    "S&P 500": 160.25
  },
  {
    "Date": "2017-11-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 51.43,
    "S&P 500": 168.2
  },
  {
    "Date": "2017-12-01",
    "MQE Strategy": 39.7,
    "HFRI 500": 52.67,
    "S&P 500": 170.07
  },
  {
    "Date": "2018-01-01",
    "MQE Strategy": 52.6,
    "HFRI 500": 56.76,
    "S&P 500": 185.3
  },
  {
    "Date": "2018-02-01",
    "MQE Strategy": 54.29,
    "HFRI 500": 54.06,
    "S&P 500": 174.92
  },
  {
    "Date": "2018-03-01",
    "MQE Strategy": 50.58,
    "HFRI 500": 53.42,
    "S&P 500": 166.32
  },
  {
    "Date": "2018-04-01",
    "MQE Strategy": 50.9,
    "HFRI 500": 53.77,
    "S&P 500": 167.7
  },
  {
    "Date": "2018-05-01",
    "MQE Strategy": 53.54,
    "HFRI 500": 54.42,
    "S&P 500": 174.2
  },
  {
    "Date": "2018-06-01",
    "MQE Strategy": 57.18,
    "HFRI 500": 53.78,
    "S&P 500": 174.55
  },
  {
    "Date": "2018-07-01",
    "MQE Strategy": 55.71,
    "HFRI 500": 54.24,
    "S&P 500": 184.72
  },
  {
    "Date": "2018-08-01",
    "MQE Strategy": 54.88,
    "HFRI 500": 54.17,
    "S&P 500": 193.81
  },
  {
    "Date": "2018-09-01",
    "MQE Strategy": 53.35,
    "HFRI 500": 54.03,
    "S&P 500": 194.22
  },
  {
    "Date": "2018-10-01",
    "MQE Strategy": 56.97,
    "HFRI 500": 49.95,
    "S&P 500": 173.89
  },
  {
    "Date": "2018-11-01",
    "MQE Strategy": 55.53,
    "HFRI 500": 49.14,
    "S&P 500": 178.97
  },
  {
    "Date": "2018-12-01",
    "MQE Strategy": 58.44,
    "HFRI 500": 47.09,
    "S&P 500": 152.93
  },
  {
    "Date": "2019-01-01",
    "MQE Strategy": 65.61,
    "HFRI 500": 51.33,
    "S&P 500": 173.18
  },
  {
    "Date": "2019-02-01",
    "MQE Strategy": 64.44,
    "HFRI 500": 52.75,
    "S&P 500": 182.04
  },
  {
    "Date": "2019-03-01",
    "MQE Strategy": 60.72,
    "HFRI 500": 53.97,
    "S&P 500": 185.88
  },
  {
    "Date": "2019-04-01",
    "MQE Strategy": 61.63,
    "HFRI 500": 55.92,
    "S&P 500": 197.56
  },
  {
    "Date": "2019-05-01",
    "MQE Strategy": 62.28,
    "HFRI 500": 54.17,
    "S&P 500": 178.59
  },
  {
    "Date": "2019-06-01",
    "MQE Strategy": 64,
    "HFRI 500": 57.04,
    "S&P 500": 196.53
  },
  {
    "Date": "2019-07-01",
    "MQE Strategy": 63.63,
    "HFRI 500": 57.99,
    "S&P 500": 201.01
  },
  {
    "Date": "2019-08-01",
    "MQE Strategy": 65.04,
    "HFRI 500": 57.03,
    "S&P 500": 195.97
  },
  {
    "Date": "2019-09-01",
    "MQE Strategy": 69.12,
    "HFRI 500": 56.81,
    "S&P 500": 200.34
  },
  {
    "Date": "2019-10-01",
    "MQE Strategy": 69.39,
    "HFRI 500": 57.61,
    "S&P 500": 206.98
  },
  {
    "Date": "2019-11-01",
    "MQE Strategy": 70.4,
    "HFRI 500": 58.92,
    "S&P 500": 218.1
  },
  {
    "Date": "2019-12-01",
    "MQE Strategy": 70.4,
    "HFRI 500": 61.7,
    "S&P 500": 225.74
  },
  {
    "Date": "2020-01-01",
    "MQE Strategy": 70.78,
    "HFRI 500": 61.33,
    "S&P 500": 225.6
  },
  {
    "Date": "2020-02-01",
    "MQE Strategy": 70.14,
    "HFRI 500": 58.26,
    "S&P 500": 199.83
  },
  {
    "Date": "2020-03-01",
    "MQE Strategy": 71.2,
    "HFRI 500": 45.68,
    "S&P 500": 160.85
  },
  {
    "Date": "2020-04-01",
    "MQE Strategy": 93.44,
    "HFRI 500": 51.71,
    "S&P 500": 193.98
  },
  {
    "Date": "2020-05-01",
    "MQE Strategy": 93.65,
    "HFRI 500": 54.84,
    "S&P 500": 207.99
  },
  {
    "Date": "2020-06-01",
    "MQE Strategy": 93.65,
    "HFRI 500": 57.42,
    "S&P 500": 212.07
  },
  {
    "Date": "2020-07-01",
    "MQE Strategy": 91.57,
    "HFRI 500": 61.55,
    "S&P 500": 230.45
  },
  {
    "Date": "2020-08-01",
    "MQE Strategy": 95.41,
    "HFRI 500": 64.67,
    "S&P 500": 253.52
  },
  {
    "Date": "2020-09-01",
    "MQE Strategy": 95.92,
    "HFRI 500": 63.07,
    "S&P 500": 238.92
  },
  {
    "Date": "2020-10-01",
    "MQE Strategy": 96.12,
    "HFRI 500": 63.21,
    "S&P 500": 230.47
  },
  {
    "Date": "2020-11-01",
    "MQE Strategy": 97.63,
    "HFRI 500": 71.85,
    "S&P 500": 266.42
  },
  {
    "Date": "2020-12-01",
    "MQE Strategy": 98.65,
    "HFRI 500": 78.88,
    "S&P 500": 278.38
  },
  {
    "Date": "2021-01-01",
    "MQE Strategy": 100.99,
    "HFRI 500": 80.42,
    "S&P 500": 274.53
  },
  {
    "Date": "2021-02-01",
    "MQE Strategy": 101.31,
    "HFRI 500": 86.37,
    "S&P 500": 284.94
  },
  {
    "Date": "2021-03-01",
    "MQE Strategy": 104.66,
    "HFRI 500": 87.56,
    "S&P 500": 301.1
  }
]

export default data;