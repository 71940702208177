const data = [
  {
    "Date": "2009-02-01",
    "MQE Strategy": 0,
    "HFRI 500": -0.63,
    "S&P 500": -10.74
  },
  {
    "Date": "2009-03-01",
    "MQE Strategy": 0,
    "HFRI 500": 0.8,
    "S&P 500": -4
  },
  {
    "Date": "2009-04-01",
    "MQE Strategy": -1.56,
    "HFRI 500": 3.49,
    "S&P 500": 5.54
  },
  {
    "Date": "2009-05-01",
    "MQE Strategy": -2.51,
    "HFRI 500": 8.51,
    "S&P 500": 11.71
  },
  {
    "Date": "2009-06-01",
    "MQE Strategy": -5.03,
    "HFRI 500": 8.75,
    "S&P 500": 11.01
  },
  {
    "Date": "2009-07-01",
    "MQE Strategy": -7.54,
    "HFRI 500": 11.6,
    "S&P 500": 19.29
  },
  {
    "Date": "2009-08-01",
    "MQE Strategy": -6.19,
    "HFRI 500": 13.17,
    "S&P 500": 23.7
  },
  {
    "Date": "2009-09-01",
    "MQE Strategy": -4.84,
    "HFRI 500": 16.3,
    "S&P 500": 27.48
  },
  {
    "Date": "2009-10-01",
    "MQE Strategy": -5.47,
    "HFRI 500": 16.12,
    "S&P 500": 25.03
  },
  {
    "Date": "2009-11-01",
    "MQE Strategy": -1.18,
    "HFRI 500": 17.55,
    "S&P 500": 32.73
  },
  {
    "Date": "2009-12-01",
    "MQE Strategy": 0.94,
    "HFRI 500": 18.66,
    "S&P 500": 34.54
  },
  {
    "Date": "2010-01-01",
    "MQE Strategy": 3.2,
    "HFRI 500": 17.93,
    "S&P 500": 29.65
  },
  {
    "Date": "2010-02-01",
    "MQE Strategy": 3.01,
    "HFRI 500": 18.25,
    "S&P 500": 33.7
  },
  {
    "Date": "2010-03-01",
    "MQE Strategy": 0.53,
    "HFRI 500": 21.16,
    "S&P 500": 41.25
  },
  {
    "Date": "2010-04-01",
    "MQE Strategy": 1.33,
    "HFRI 500": 22.32,
    "S&P 500": 43.44
  },
  {
    "Date": "2010-05-01",
    "MQE Strategy": 2.59,
    "HFRI 500": 18.89,
    "S&P 500": 32.04
  },
  {
    "Date": "2010-06-01",
    "MQE Strategy": 13.67,
    "HFRI 500": 18.02,
    "S&P 500": 24.62
  },
  {
    "Date": "2010-07-01",
    "MQE Strategy": 13.79,
    "HFRI 500": 19.78,
    "S&P 500": 33.13
  },
  {
    "Date": "2010-08-01",
    "MQE Strategy": 19.69,
    "HFRI 500": 19.92,
    "S&P 500": 27.14
  },
  {
    "Date": "2010-09-01",
    "MQE Strategy": 18.48,
    "HFRI 500": 23.8,
    "S&P 500": 37.79
  },
  {
    "Date": "2010-10-01",
    "MQE Strategy": 23.41,
    "HFRI 500": 26.18,
    "S&P 500": 43.05
  },
  {
    "Date": "2010-11-01",
    "MQE Strategy": 26.61,
    "HFRI 500": 25.88,
    "S&P 500": 43.05
  },
  {
    "Date": "2010-12-01",
    "MQE Strategy": 29.23,
    "HFRI 500": 29.28,
    "S&P 500": 51.82
  },
  {
    "Date": "2011-01-01",
    "MQE Strategy": 27.14,
    "HFRI 500": 29.81,
    "S&P 500": 55.35
  },
  {
    "Date": "2011-02-01",
    "MQE Strategy": 37.99,
    "HFRI 500": 30.94,
    "S&P 500": 60.75
  },
  {
    "Date": "2011-03-01",
    "MQE Strategy": 41.03,
    "HFRI 500": 31.02,
    "S&P 500": 60.07
  },
  {
    "Date": "2011-04-01",
    "MQE Strategy": 41.37,
    "HFRI 500": 33.14,
    "S&P 500": 64.71
  },
  {
    "Date": "2011-05-01",
    "MQE Strategy": 41.59,
    "HFRI 500": 31.23,
    "S&P 500": 62.86
  },
  {
    "Date": "2011-06-01",
    "MQE Strategy": 41.01,
    "HFRI 500": 29.74,
    "S&P 500": 59.33
  },
  {
    "Date": "2011-07-01",
    "MQE Strategy": 42.71,
    "HFRI 500": 30.32,
    "S&P 500": 56.14
  },
  {
    "Date": "2011-08-01",
    "MQE Strategy": 46.51,
    "HFRI 500": 26.63,
    "S&P 500": 47.56
  },
  {
    "Date": "2011-09-01",
    "MQE Strategy": 48.01,
    "HFRI 500": 22.59,
    "S&P 500": 36.61
  },
  {
    "Date": "2011-10-01",
    "MQE Strategy": 58.81,
    "HFRI 500": 25.3,
    "S&P 500": 51.52
  },
  {
    "Date": "2011-11-01",
    "MQE Strategy": 55.87,
    "HFRI 500": 23.86,
    "S&P 500": 50.9
  },
  {
    "Date": "2011-12-01",
    "MQE Strategy": 55.16,
    "HFRI 500": 23.4,
    "S&P 500": 51.52
  },
  {
    "Date": "2012-01-01",
    "MQE Strategy": 56.32,
    "HFRI 500": 26.27,
    "S&P 500": 58.54
  },
  {
    "Date": "2012-02-01",
    "MQE Strategy": 56.75,
    "HFRI 500": 28.68,
    "S&P 500": 65.42
  },
  {
    "Date": "2012-03-01",
    "MQE Strategy": 58.8,
    "HFRI 500": 28.38,
    "S&P 500": 70
  },
  {
    "Date": "2012-04-01",
    "MQE Strategy": 55.99,
    "HFRI 500": 28.05,
    "S&P 500": 68.86
  },
  {
    "Date": "2012-05-01",
    "MQE Strategy": 59.36,
    "HFRI 500": 25.36,
    "S&P 500": 58.72
  },
  {
    "Date": "2012-06-01",
    "MQE Strategy": 57.72,
    "HFRI 500": 25.3,
    "S&P 500": 64.31
  },
  {
    "Date": "2012-07-01",
    "MQE Strategy": 59.14,
    "HFRI 500": 26.63,
    "S&P 500": 66.26
  },
  {
    "Date": "2012-08-01",
    "MQE Strategy": 60.84,
    "HFRI 500": 27.41,
    "S&P 500": 70.42
  },
  {
    "Date": "2012-09-01",
    "MQE Strategy": 60.48,
    "HFRI 500": 28.79,
    "S&P 500": 73.81
  },
  {
    "Date": "2012-10-01",
    "MQE Strategy": 62.86,
    "HFRI 500": 28.4,
    "S&P 500": 70.65
  },
  {
    "Date": "2012-11-01",
    "MQE Strategy": 61.85,
    "HFRI 500": 29.08,
    "S&P 500": 71.62
  },
  {
    "Date": "2012-12-01",
    "MQE Strategy": 59.6,
    "HFRI 500": 31.06,
    "S&P 500": 71.93
  },
  {
    "Date": "2013-01-01",
    "MQE Strategy": 57.25,
    "HFRI 500": 34.59,
    "S&P 500": 80.73
  },
  {
    "Date": "2013-02-01",
    "MQE Strategy": 65.5,
    "HFRI 500": 35.01,
    "S&P 500": 83.04
  },
  {
    "Date": "2013-03-01",
    "MQE Strategy": 60.38,
    "HFRI 500": 36.27,
    "S&P 500": 89.15
  },
  {
    "Date": "2013-04-01",
    "MQE Strategy": 63.15,
    "HFRI 500": 37.59,
    "S&P 500": 92.78
  },
  {
    "Date": "2013-05-01",
    "MQE Strategy": 63.54,
    "HFRI 500": 38.43,
    "S&P 500": 97.33
  },
  {
    "Date": "2013-06-01",
    "MQE Strategy": 62.53,
    "HFRI 500": 36.17,
    "S&P 500": 93.67
  },
  {
    "Date": "2013-07-01",
    "MQE Strategy": 64.49,
    "HFRI 500": 37.94,
    "S&P 500": 103.68
  },
  {
    "Date": "2013-08-01",
    "MQE Strategy": 62.07,
    "HFRI 500": 37.09,
    "S&P 500": 97.57
  },
  {
    "Date": "2013-09-01",
    "MQE Strategy": 61.11,
    "HFRI 500": 39.13,
    "S&P 500": 102.84
  },
  {
    "Date": "2013-10-01",
    "MQE Strategy": 61.76,
    "HFRI 500": 41.47,
    "S&P 500": 112.23
  },
  {
    "Date": "2013-11-01",
    "MQE Strategy": 64.83,
    "HFRI 500": 43.12,
    "S&P 500": 118.52
  },
  {
    "Date": "2013-12-01",
    "MQE Strategy": 65.06,
    "HFRI 500": 44.71,
    "S&P 500": 122.97
  },
  {
    "Date": "2014-01-01",
    "MQE Strategy": 65.29,
    "HFRI 500": 43.94,
    "S&P 500": 115.12
  },
  {
    "Date": "2014-02-01",
    "MQE Strategy": 63.13,
    "HFRI 500": 46.38,
    "S&P 500": 124.91
  },
  {
    "Date": "2014-03-01",
    "MQE Strategy": 64.73,
    "HFRI 500": 45.56,
    "S&P 500": 125.78
  },
  {
    "Date": "2014-04-01",
    "MQE Strategy": 63.76,
    "HFRI 500": 44.83,
    "S&P 500": 127.35
  },
  {
    "Date": "2014-05-01",
    "MQE Strategy": 62.86,
    "HFRI 500": 46.61,
    "S&P 500": 132.62
  },
  {
    "Date": "2014-06-01",
    "MQE Strategy": 61.71,
    "HFRI 500": 48.25,
    "S&P 500": 136.29
  },
  {
    "Date": "2014-07-01",
    "MQE Strategy": 61.42,
    "HFRI 500": 47.64,
    "S&P 500": 133.12
  },
  {
    "Date": "2014-08-01",
    "MQE Strategy": 63.62,
    "HFRI 500": 49.53,
    "S&P 500": 142.32
  },
  {
    "Date": "2014-09-01",
    "MQE Strategy": 65.14,
    "HFRI 500": 49.31,
    "S&P 500": 137.86
  },
  {
    "Date": "2014-10-01",
    "MQE Strategy": 71.57,
    "HFRI 500": 48.92,
    "S&P 500": 143.46
  },
  {
    "Date": "2014-11-01",
    "MQE Strategy": 71.51,
    "HFRI 500": 50.84,
    "S&P 500": 150.15
  },
  {
    "Date": "2014-12-01",
    "MQE Strategy": 71.51,
    "HFRI 500": 50.96,
    "S&P 500": 148.15
  },
  {
    "Date": "2015-01-01",
    "MQE Strategy": 76.23,
    "HFRI 500": 51.73,
    "S&P 500": 140.79
  },
  {
    "Date": "2015-02-01",
    "MQE Strategy": 79.66,
    "HFRI 500": 54.37,
    "S&P 500": 154.33
  },
  {
    "Date": "2015-03-01",
    "MQE Strategy": 81.19,
    "HFRI 500": 55.32,
    "S&P 500": 149.22
  },
  {
    "Date": "2015-04-01",
    "MQE Strategy": 90.97,
    "HFRI 500": 56.71,
    "S&P 500": 151.67
  },
  {
    "Date": "2015-05-01",
    "MQE Strategy": 90.97,
    "HFRI 500": 58.06,
    "S&P 500": 154.91
  },
  {
    "Date": "2015-06-01",
    "MQE Strategy": 90.97,
    "HFRI 500": 55.48,
    "S&P 500": 148.52
  },
  {
    "Date": "2015-07-01",
    "MQE Strategy": 95.86,
    "HFRI 500": 55.72,
    "S&P 500": 154.13
  },
  {
    "Date": "2015-08-01",
    "MQE Strategy": 99.11,
    "HFRI 500": 52.15,
    "S&P 500": 138.65
  },
  {
    "Date": "2015-09-01",
    "MQE Strategy": 103.49,
    "HFRI 500": 50.25,
    "S&P 500": 131.35
  },
  {
    "Date": "2015-10-01",
    "MQE Strategy": 101.48,
    "HFRI 500": 52.38,
    "S&P 500": 151.03
  },
  {
    "Date": "2015-11-01",
    "MQE Strategy": 108.59,
    "HFRI 500": 52.83,
    "S&P 500": 151.95
  },
  {
    "Date": "2015-12-01",
    "MQE Strategy": 117.4,
    "HFRI 500": 51.79,
    "S&P 500": 146.13
  },
  {
    "Date": "2016-01-01",
    "MQE Strategy": 125.7,
    "HFRI 500": 48.19,
    "S&P 500": 133.88
  },
  {
    "Date": "2016-02-01",
    "MQE Strategy": 129.03,
    "HFRI 500": 47.42,
    "S&P 500": 133.68
  },
  {
    "Date": "2016-03-01",
    "MQE Strategy": 132.48,
    "HFRI 500": 49.59,
    "S&P 500": 148.12
  },
  {
    "Date": "2016-04-01",
    "MQE Strategy": 138.75,
    "HFRI 500": 50.27,
    "S&P 500": 149.1
  },
  {
    "Date": "2016-05-01",
    "MQE Strategy": 139.29,
    "HFRI 500": 51.11,
    "S&P 500": 153.34
  },
  {
    "Date": "2016-06-01",
    "MQE Strategy": 143.11,
    "HFRI 500": 51.02,
    "S&P 500": 152.9
  },
  {
    "Date": "2016-07-01",
    "MQE Strategy": 145.15,
    "HFRI 500": 53.43,
    "S&P 500": 162.13
  },
  {
    "Date": "2016-08-01",
    "MQE Strategy": 143.72,
    "HFRI 500": 53.9,
    "S&P 500": 162.44
  },
  {
    "Date": "2016-09-01",
    "MQE Strategy": 143.39,
    "HFRI 500": 54.72,
    "S&P 500": 161.14
  },
  {
    "Date": "2016-10-01",
    "MQE Strategy": 144.45,
    "HFRI 500": 53.9,
    "S&P 500": 156.61
  },
  {
    "Date": "2016-11-01",
    "MQE Strategy": 147.32,
    "HFRI 500": 54.54,
    "S&P 500": 166.06
  },
  {
    "Date": "2016-12-01",
    "MQE Strategy": 157.16,
    "HFRI 500": 55.87,
    "S&P 500": 169.87
  },
  {
    "Date": "2017-01-01",
    "MQE Strategy": 156.08,
    "HFRI 500": 57.57,
    "S&P 500": 174.7
  },
  {
    "Date": "2017-02-01",
    "MQE Strategy": 160.29,
    "HFRI 500": 59.24,
    "S&P 500": 185.49
  },
  {
    "Date": "2017-03-01",
    "MQE Strategy": 160.75,
    "HFRI 500": 59.93,
    "S&P 500": 184.61
  },
  {
    "Date": "2017-04-01",
    "MQE Strategy": 164.63,
    "HFRI 500": 60.97,
    "S&P 500": 187.43
  },
  {
    "Date": "2017-05-01",
    "MQE Strategy": 159.9,
    "HFRI 500": 61.75,
    "S&P 500": 191.49
  },
  {
    "Date": "2017-06-01",
    "MQE Strategy": 160.6,
    "HFRI 500": 62.08,
    "S&P 500": 191.92
  },
  {
    "Date": "2017-07-01",
    "MQE Strategy": 168.45,
    "HFRI 500": 63.93,
    "S&P 500": 197.92
  },
  {
    "Date": "2017-08-01",
    "MQE Strategy": 177.15,
    "HFRI 500": 65.24,
    "S&P 500": 198.79
  },
  {
    "Date": "2017-09-01",
    "MQE Strategy": 177.48,
    "HFRI 500": 66.06,
    "S&P 500": 203.31
  },
  {
    "Date": "2017-10-01",
    "MQE Strategy": 180.76,
    "HFRI 500": 68.52,
    "S&P 500": 210.46
  },
  {
    "Date": "2017-11-01",
    "MQE Strategy": 184.36,
    "HFRI 500": 68.99,
    "S&P 500": 219.94
  },
  {
    "Date": "2017-12-01",
    "MQE Strategy": 178.38,
    "HFRI 500": 70.38,
    "S&P 500": 222.18
  },
  {
    "Date": "2018-01-01",
    "MQE Strategy": 181.77,
    "HFRI 500": 74.94,
    "S&P 500": 240.34
  },
  {
    "Date": "2018-02-01",
    "MQE Strategy": 179.34,
    "HFRI 500": 71.94,
    "S&P 500": 227.96
  },
  {
    "Date": "2018-03-01",
    "MQE Strategy": 177.71,
    "HFRI 500": 71.21,
    "S&P 500": 217.7
  },
  {
    "Date": "2018-04-01",
    "MQE Strategy": 174.79,
    "HFRI 500": 71.61,
    "S&P 500": 219.34
  },
  {
    "Date": "2018-05-01",
    "MQE Strategy": 180.03,
    "HFRI 500": 72.33,
    "S&P 500": 227.1
  },
  {
    "Date": "2018-06-01",
    "MQE Strategy": 189.16,
    "HFRI 500": 71.62,
    "S&P 500": 227.51
  },
  {
    "Date": "2018-07-01",
    "MQE Strategy": 192.74,
    "HFRI 500": 72.14,
    "S&P 500": 239.65
  },
  {
    "Date": "2018-08-01",
    "MQE Strategy": 197.77,
    "HFRI 500": 72.05,
    "S&P 500": 250.49
  },
  {
    "Date": "2018-09-01",
    "MQE Strategy": 198.76,
    "HFRI 500": 71.9,
    "S&P 500": 250.98
  },
  {
    "Date": "2018-10-01",
    "MQE Strategy": 195.28,
    "HFRI 500": 67.34,
    "S&P 500": 226.73
  },
  {
    "Date": "2018-11-01",
    "MQE Strategy": 202.59,
    "HFRI 500": 66.44,
    "S&P 500": 232.79
  },
  {
    "Date": "2018-12-01",
    "MQE Strategy": 206.78,
    "HFRI 500": 64.16,
    "S&P 500": 201.73
  },
  {
    "Date": "2019-01-01",
    "MQE Strategy": 209.97,
    "HFRI 500": 68.88,
    "S&P 500": 225.88
  },
  {
    "Date": "2019-02-01",
    "MQE Strategy": 212.02,
    "HFRI 500": 70.47,
    "S&P 500": 236.45
  },
  {
    "Date": "2019-03-01",
    "MQE Strategy": 209.96,
    "HFRI 500": 71.84,
    "S&P 500": 241.04
  },
  {
    "Date": "2019-04-01",
    "MQE Strategy": 207.98,
    "HFRI 500": 74,
    "S&P 500": 254.97
  },
  {
    "Date": "2019-05-01",
    "MQE Strategy": 210.86,
    "HFRI 500": 72.05,
    "S&P 500": 232.33
  },
  {
    "Date": "2019-06-01",
    "MQE Strategy": 211.86,
    "HFRI 500": 75.25,
    "S&P 500": 253.74
  },
  {
    "Date": "2019-07-01",
    "MQE Strategy": 217.18,
    "HFRI 500": 76.32,
    "S&P 500": 259.08
  },
  {
    "Date": "2019-08-01",
    "MQE Strategy": 221.18,
    "HFRI 500": 75.25,
    "S&P 500": 253.07
  },
  {
    "Date": "2019-09-01",
    "MQE Strategy": 220.21,
    "HFRI 500": 75,
    "S&P 500": 258.29
  },
  {
    "Date": "2019-10-01",
    "MQE Strategy": 221.51,
    "HFRI 500": 75.89,
    "S&P 500": 266.21
  },
  {
    "Date": "2019-11-01",
    "MQE Strategy": 219.09,
    "HFRI 500": 77.35,
    "S&P 500": 279.46
  },
  {
    "Date": "2019-12-01",
    "MQE Strategy": 218.28,
    "HFRI 500": 80.46,
    "S&P 500": 288.58
  },
  {
    "Date": "2020-01-01",
    "MQE Strategy": 219.37,
    "HFRI 500": 80.04,
    "S&P 500": 288.42
  },
  {
    "Date": "2020-02-01",
    "MQE Strategy": 216.72,
    "HFRI 500": 76.62,
    "S&P 500": 257.67
  },
  {
    "Date": "2020-03-01",
    "MQE Strategy": 215.62,
    "HFRI 500": 62.58,
    "S&P 500": 211.18
  },
  {
    "Date": "2020-04-01",
    "MQE Strategy": 239.67,
    "HFRI 500": 69.31,
    "S&P 500": 250.69
  },
  {
    "Date": "2020-05-01",
    "MQE Strategy": 234.06,
    "HFRI 500": 72.8,
    "S&P 500": 267.4
  },
  {
    "Date": "2020-06-01",
    "MQE Strategy": 234.06,
    "HFRI 500": 75.68,
    "S&P 500": 272.28
  },
  {
    "Date": "2020-07-01",
    "MQE Strategy": 235.04,
    "HFRI 500": 80.29,
    "S&P 500": 294.2
  },
  {
    "Date": "2020-08-01",
    "MQE Strategy": 238.86,
    "HFRI 500": 83.77,
    "S&P 500": 321.72
  },
  {
    "Date": "2020-09-01",
    "MQE Strategy": 243.85,
    "HFRI 500": 81.98,
    "S&P 500": 304.31
  },
  {
    "Date": "2020-10-01",
    "MQE Strategy": 247.87,
    "HFRI 500": 82.15,
    "S&P 500": 294.23
  },
  {
    "Date": "2020-11-01",
    "MQE Strategy": 250.11,
    "HFRI 500": 91.78,
    "S&P 500": 337.11
  },
  {
    "Date": "2020-12-01",
    "MQE Strategy": 243.51,
    "HFRI 500": 99.63,
    "S&P 500": 351.38
  },
  {
    "Date": "2021-01-01",
    "MQE Strategy": 248.3,
    "HFRI 500": 101.34,
    "S&P 500": 346.78
  },
  {
    "Date": "2021-02-01",
    "MQE Strategy": 254.11,
    "HFRI 500": 107.99,
    "S&P 500": 359.21
  },
  {
    "Date": "2021-03-01",
    "MQE Strategy": 262.03,
    "HFRI 500": 109.32,
    "S&P 500": 378.49
  }
]

export default data;