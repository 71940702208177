import React from 'react';
import { Table, Row, Col } from 'reactstrap';

function StatsTablesMQE(props) {
  return (
    <Row className="p-3">
      <Col>
        <Table striped bordered responsive className="stats-table">
          <thead>
            <tr>
              <th>Metric</th>
              <th>MQE Strategy</th>
              <th>HFRI 500</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Monthly Alpha</td>
              <td>0.06</td>
              <td>0.13</td>
            </tr>
            <tr>
              <td>Sharpe</td>
              <td>0.15</td>
              <td>0.03</td>
            </tr>
            <tr>
              <td>Correlation with S&amp;P</td>
              <td>0.39</td>
              <td>-0.17</td>
            </tr>
            <tr>
              <td>Up Alpha</td>
              <td>0.10</td>
              <td>0.16</td>
            </tr>
            <tr>
              <td>Up Beta</td>
              <td>0.26</td>
              <td>-0.03</td>
            </tr>
            <tr>
              <td>Avg. Annual Return</td>
              <td>0.08</td>
              <td>0.01</td>
            </tr>
            <tr>
              <td>Max Monthly Drawdown</td>
              <td>8.39</td>
              <td>8.02</td>
            </tr>
            <tr>
              <td>% of Winning Mo.</td>
              <td>52.94</td>
              <td>49.67</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col>
        <Table striped bordered responsive className="stats-table">
          <thead>
            <tr>
              <th>Metric</th>
              <th>MQE Strategy</th>
              <th>HFRI 500</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Down Alpha</td>
              <td>-0.12</td>
              <td>-0.25</td>
            </tr>
            <tr>
              <td>Down Beta</td>
              <td>0.10</td>
              <td>-0.10</td>
            </tr>
            <tr>
              <td>Market Beta</td>
              <td>0.01</td>
              <td>0.13</td>
            </tr>
            <tr>
              <td>Standard Deviation of Returns</td>
              <td>0.9</td>
              <td>1.31</td>
            </tr>
            <tr>
              <td>Highest Monthly Return</td>
              <td>2.41</td>
              <td>4.22</td>
            </tr>
            <tr>
              <td>Lowest Monthly Return</td>
              <td>-2.18</td>
              <td>-3.7</td>
            </tr>
            <tr>
              <td>Gini</td>
              <td>1.2</td>
              <td>1.75</td>
            </tr>
            <tr>
              <td>Annualized STD</td>
              <td>3.11</td>
              <td>-4.55</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    );
  }

  export default StatsTablesMQE;
