const data = {
  returns: [{
    "Sharpe": 1.38,
    "Annualized Return": "12.065%",
    "Forecast Success Ratio": "53.9%",
    "Avg. Annual Return": 12.065,
    "Market Beta": 0.08,
    "Monthly Alpha": "0.91%",
    "Correlation with S&P": 0.14,
    "Max Drawdown": "-6.45%",
    "% of Winning Mo.": "66.43%",
    "Gini": 0.441,
    "Forecast Accuracy": "",
    "Up Alpha": "0.42%",
    "Down Alpha": "0.63%",
    "Up Beta": 0.22,
    "Down Beta": -0.04,
    "Highest Monthly Return": 14.65,
    "Lowest Monthly Return": -6.45,
    "Annualized STD of Returns": "8.15%"
  }],
  hedge : [{
    "Sharpe": 0.74,
    "Annualized Return": "4.538%",
    "Avg. Annual Return": 4.538,
    "Market Beta": 0.34,
    "Monthly Alpha": "0.07%",
    "Correlation with S&P": 0.86,
    "Max Drawdown": "-10.76%",
    "% of Winning Mo.": "66.43%",
    "Gini": 0.421,
    "Forecast Accuracy": "",
    "Up Alpha": "0.19%",
    "Down Alpha": "0.26%",
    "Up Beta": 0.3,
    "Down Beta": 0.39,
    "Highest Monthly Return": 5.28,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "5.44%"
  }]
}

export default data;