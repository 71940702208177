import React from 'react';
import {
  Card,
  CardImg,
  CardBody,
  CardSubtitle
} from 'reactstrap';
import HeroCardBody from './HeroCardBody'
import { useCollection } from 'react-firebase-hooks/firestore';
import {db} from '../firebaseConfig'

function HeroCard(props) {

  const [value] = useCollection(
    db.collection('assets/'+props.id+'/forecasts').orderBy("createdAt","desc"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  var filtered = value && value.docs.filter(doc => doc.data().horizon === (props.forecastTerm === 12 ? 12 : props.forecastTerm === 1 ? 1 : null));

  return (
      <Card className="center text-center" key={props.id}>
        <div  className="container-img">
          <CardImg style={{opacity: 0.35}} top width="100%" src={props.image} alt="Card image cap" />
          <h4 className="centered mae-currency-name maeCurrencyName">{props.title}</h4>
        </div>
        {value && filtered.length > 0 ? (
          <span>
              {
                filtered.slice(0,1).map(doc => (
                    <HeroCardBody key={doc.id} signal={doc.data().signal} interest={doc.data().interest} createdAt={doc.data().createdAt} exitedAt={doc.data().exitedAt}/>
              ))}
          </span>
        ) : (
          <CardBody className="no-data-card">
            <CardSubtitle className="mb-4">Coming Soon</CardSubtitle>
          </CardBody>
        )}

      </Card>
  );
}

export default HeroCard;
