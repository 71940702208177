const data = [
  {
    "Date": "2005-01-01",
    "MQE Strategy": 99.1520525111293,
    "HFRI 500": 100.25
  },
  {
    "Date": "2005-02-01",
    "MQE Strategy": 100.5809183664189,
    "HFRI 500": 102.13469999999998
  },
  {
    "Date": "2005-03-01",
    "MQE Strategy": 99.09011050139816,
    "HFRI 500": 101.2154877
  },
  {
    "Date": "2005-04-01",
    "MQE Strategy": 95.92599983671506,
    "HFRI 500": 100.1021173353
  },
  {
    "Date": "2005-05-01",
    "MQE Strategy": 97.57585213945679,
    "HFRI 500": 100.81284236838061
  },
  {
    "Date": "2005-06-01",
    "MQE Strategy": 101.79550083568576,
    "HFRI 500": 102.32503500390632
  },
  {
    "Date": "2005-07-01",
    "MQE Strategy": 101.79550083568576,
    "HFRI 500": 104.46362823548796
  },
  {
    "Date": "2005-08-01",
    "MQE Strategy": 102.85752836867923,
    "HFRI 500": 105.40380088960734
  },
  {
    "Date": "2005-09-01",
    "MQE Strategy": 102.61994949421056,
    "HFRI 500": 107.79646716980142
  },
  {
    "Date": "2005-10-01",
    "MQE Strategy": 99.20878413837187,
    "HFRI 500": 106.395113096594
  },
  {
    "Date": "2005-11-01",
    "MQE Strategy": 104.60645568256031,
    "HFRI 500": 108.26766708709405
  },
  {
    "Date": "2005-12-01",
    "MQE Strategy": 105.02910531515471,
    "HFRI 500": 110.4979810290882
  },
  {
    "Date": "2006-01-01",
    "MQE Strategy": 107.33738653196959,
    "HFRI 500": 114.10021521063646
  },
  {
    "Date": "2006-02-01",
    "MQE Strategy": 107.30654310161385,
    "HFRI 500": 114.7163563727739
  },
  {
    "Date": "2006-03-01",
    "MQE Strategy": 108.79371338512883,
    "HFRI 500": 116.8041940587584
  },
  {
    "Date": "2006-04-01",
    "MQE Strategy": 107.147319361998,
    "HFRI 500": 119.15195835933945
  },
  {
    "Date": "2006-05-01",
    "MQE Strategy": 107.91175002242382,
    "HFRI 500": 116.88807115051199
  },
  {
    "Date": "2006-06-01",
    "MQE Strategy": 103.379373566799,
    "HFRI 500": 116.65429500821097
  },
  {
    "Date": "2006-07-01",
    "MQE Strategy": 102.90921222970748,
    "HFRI 500": 116.65429500821097
  },
  {
    "Date": "2006-08-01",
    "MQE Strategy": 108.16350405310679,
    "HFRI 500": 117.84416881729472
  },
  {
    "Date": "2006-09-01",
    "MQE Strategy": 110.13584526123378,
    "HFRI 500": 118.06807273804758
  },
  {
    "Date": "2006-10-01",
    "MQE Strategy": 110.13584526123378,
    "HFRI 500": 120.27594569824906
  },
  {
    "Date": "2006-11-01",
    "MQE Strategy": 112.66507288715538,
    "HFRI 500": 122.74160258506316
  },
  {
    "Date": "2006-12-01",
    "MQE Strategy": 114.43328145664475,
    "HFRI 500": 125.17188631624741
  },
  {
    "Date": "2007-01-01",
    "MQE Strategy": 115.09696360681905,
    "HFRI 500": 126.59884582025265
  },
  {
    "Date": "2007-02-01",
    "MQE Strategy": 117.09284155026735,
    "HFRI 500": 127.58631681765063
  },
  {
    "Date": "2007-03-01",
    "MQE Strategy": 112.79057386193085,
    "HFRI 500": 129.0280421976901
  },
  {
    "Date": "2007-04-01",
    "MQE Strategy": 118.1217433211674,
    "HFRI 500": 131.33764415302872
  },
  {
    "Date": "2007-05-01",
    "MQE Strategy": 122.68868254879573,
    "HFRI 500": 134.30587491088718
  },
  {
    "Date": "2007-06-01",
    "MQE Strategy": 123.16734405219805,
    "HFRI 500": 135.90411482232673
  },
  {
    "Date": "2007-07-01",
    "MQE Strategy": 124.65318524855024,
    "HFRI 500": 136.70594909977848
  },
  {
    "Date": "2007-08-01",
    "MQE Strategy": 116.28918358675588,
    "HFRI 500": 134.1085360668827
  },
  {
    "Date": "2007-09-01",
    "MQE Strategy": 118.79106221308817,
    "HFRI 500": 137.44783861494804
  },
  {
    "Date": "2007-10-01",
    "MQE Strategy": 124.5880190146997,
    "HFRI 500": 141.39259158319706
  },
  {
    "Date": "2007-11-01",
    "MQE Strategy": 115.30130040518584,
    "HFRI 500": 138.28195456836673
  },
  {
    "Date": "2007-12-01",
    "MQE Strategy": 116.31733783516604,
    "HFRI 500": 138.848910582097
  },
  {
    "Date": "2008-01-01",
    "MQE Strategy": 106.60665572142827,
    "HFRI 500": 134.8222921752162
  },
  {
    "Date": "2008-02-01",
    "MQE Strategy": 108.6017284160557,
    "HFRI 500": 137.10078891297735
  },
  {
    "Date": "2008-03-01",
    "MQE Strategy": 108.6017284160557,
    "HFRI 500": 133.94747076797887
  },
  {
    "Date": "2008-04-01",
    "MQE Strategy": 118.0931137325596,
    "HFRI 500": 136.06384080611292
  },
  {
    "Date": "2008-05-01",
    "MQE Strategy": 118.0931137325596,
    "HFRI 500": 138.49938355654234
  },
  {
    "Date": "2008-06-01",
    "MQE Strategy": 118.0931137325596,
    "HFRI 500": 136.6988915703073
  },
  {
    "Date": "2008-07-01",
    "MQE Strategy": 118.0931137325596,
    "HFRI 500": 133.2814192810496
  },
  {
    "Date": "2008-08-01",
    "MQE Strategy": 118.0931137325596,
    "HFRI 500": 131.01563515327177
  },
  {
    "Date": "2008-09-01",
    "MQE Strategy": 118.0931137325596,
    "HFRI 500": 123.15469704407546
  },
  {
    "Date": "2008-10-01",
    "MQE Strategy": 139.14470687310376,
    "HFRI 500": 114.78017764507833
  },
  {
    "Date": "2008-11-01",
    "MQE Strategy": 120.13127302557405,
    "HFRI 500": 112.94369480275708
  },
  {
    "Date": "2008-12-01",
    "MQE Strategy": 117.66660103928439,
    "HFRI 500": 113.10181597548092
  },
  {
    "Date": "2009-01-01",
    "MQE Strategy": 120.2202996206657,
    "HFRI 500": 113.3053992442368
  },
  {
    "Date": "2009-02-01",
    "MQE Strategy": 123.60514392994179,
    "HFRI 500": 112.62556684877138
  },
  {
    "Date": "2009-03-01",
    "MQE Strategy": 135.56577025916303,
    "HFRI 500": 114.24737501139369
  },
  {
    "Date": "2009-04-01",
    "MQE Strategy": 122.78100256845623,
    "HFRI 500": 117.2977799241979
  },
  {
    "Date": "2009-05-01",
    "MQE Strategy": 112.34533868813318,
    "HFRI 500": 122.98672225052147
  },
  {
    "Date": "2009-06-01",
    "MQE Strategy": 110.64141056592334,
    "HFRI 500": 123.25729303947264
  },
  {
    "Date": "2009-07-01",
    "MQE Strategy": 113.92453201125959,
    "HFRI 500": 126.48663411710682
  },
  {
    "Date": "2009-08-01",
    "MQE Strategy": 117.35018236130061,
    "HFRI 500": 128.270095658158
  },
  {
    "Date": "2009-09-01",
    "MQE Strategy": 127.52294168235872,
    "HFRI 500": 131.8103502983232
  },
  {
    "Date": "2009-10-01",
    "MQE Strategy": 131.50556305896134,
    "HFRI 500": 131.67853994802488
  },
  {
    "Date": "2009-11-01",
    "MQE Strategy": 132.86983721892167,
    "HFRI 500": 133.29818598938556
  },
  {
    "Date": "2009-12-01",
    "MQE Strategy": 133.4387831298649,
    "HFRI 500": 134.5511889376858
  },
  {
    "Date": "2010-01-01",
    "MQE Strategy": 136.07150313203488,
    "HFRI 500": 133.7438818040597
  },
  {
    "Date": "2010-02-01",
    "MQE Strategy": 128.82253099676984,
    "HFRI 500": 134.10499028493064
  },
  {
    "Date": "2010-03-01",
    "MQE Strategy": 137.8058874853471,
    "HFRI 500": 137.40397304593995
  },
  {
    "Date": "2010-04-01",
    "MQE Strategy": 143.4366565020951,
    "HFRI 500": 138.72305118718097
  },
  {
    "Date": "2010-05-01",
    "MQE Strategy": 136.18049066339205,
    "HFRI 500": 134.83880575393988
  },
  {
    "Date": "2010-06-01",
    "MQE Strategy": 133.336960059249,
    "HFRI 500": 133.89493411366232
  },
  {
    "Date": "2010-07-01",
    "MQE Strategy": 128.31227517851082,
    "HFRI 500": 135.88996863195587
  },
  {
    "Date": "2010-08-01",
    "MQE Strategy": 129.2860716067265,
    "HFRI 500": 136.05303659431422
  },
  {
    "Date": "2010-09-01",
    "MQE Strategy": 136.87161256199562,
    "HFRI 500": 140.46115497997
  },
  {
    "Date": "2010-10-01",
    "MQE Strategy": 141.9022921955988,
    "HFRI 500": 143.15800915558546
  },
  {
    "Date": "2010-11-01",
    "MQE Strategy": 143.4642031225777,
    "HFRI 500": 142.8716931372743
  },
  {
    "Date": "2010-12-01",
    "MQE Strategy": 149.37285086664576,
    "HFRI 500": 146.72922885198068
  },
  {
    "Date": "2011-01-01",
    "MQE Strategy": 154.9018114341262,
    "HFRI 500": 147.33081869027382
  },
  {
    "Date": "2011-02-01",
    "MQE Strategy": 160.86316913936176,
    "HFRI 500": 148.61259681287916
  },
  {
    "Date": "2011-03-01",
    "MQE Strategy": 155.51747255337185,
    "HFRI 500": 148.70176437096688
  },
  {
    "Date": "2011-04-01",
    "MQE Strategy": 156.32717358791672,
    "HFRI 500": 151.11073295377656
  },
  {
    "Date": "2011-05-01",
    "MQE Strategy": 159.2413691691258,
    "HFRI 500": 148.99518269242367
  },
  {
    "Date": "2011-06-01",
    "MQE Strategy": 153.1195129219385,
    "HFRI 500": 147.356235682807
  },
  {
    "Date": "2011-07-01",
    "MQE Strategy": 156.36309824106206,
    "HFRI 500": 148.01933874337962
  },
  {
    "Date": "2011-08-01",
    "MQE Strategy": 156.36309824106206,
    "HFRI 500": 143.874797258565
  },
  {
    "Date": "2011-09-01",
    "MQE Strategy": 156.3111683310903,
    "HFRI 500": 139.41467854354948
  },
  {
    "Date": "2011-10-01",
    "MQE Strategy": 156.3111683310903,
    "HFRI 500": 142.49574293936195
  },
  {
    "Date": "2011-11-01",
    "MQE Strategy": 156.3111683310903,
    "HFRI 500": 140.92828976702896
  },
  {
    "Date": "2011-12-01",
    "MQE Strategy": 156.3111683310903,
    "HFRI 500": 140.50550489772786
  },
  {
    "Date": "2012-01-01",
    "MQE Strategy": 156.3111683310903,
    "HFRI 500": 143.76523261135517
  },
  {
    "Date": "2012-02-01",
    "MQE Strategy": 156.3111683310903,
    "HFRI 500": 146.51114855423205
  },
  {
    "Date": "2012-03-01",
    "MQE Strategy": 152.35723209746484,
    "HFRI 500": 146.21812625712357
  },
  {
    "Date": "2012-04-01",
    "MQE Strategy": 148.01481552081952,
    "HFRI 500": 145.92569000460932
  },
  {
    "Date": "2012-05-01",
    "MQE Strategy": 142.22421874806113,
    "HFRI 500": 142.86125051451253
  },
  {
    "Date": "2012-06-01",
    "MQE Strategy": 145.3356716039154,
    "HFRI 500": 142.86125051451253
  },
  {
    "Date": "2012-07-01",
    "MQE Strategy": 144.38440545668604,
    "HFRI 500": 144.37557976996635
  },
  {
    "Date": "2012-08-01",
    "MQE Strategy": 137.81847083444586,
    "HFRI 500": 145.27070836454016
  },
  {
    "Date": "2012-09-01",
    "MQE Strategy": 133.7570860426938,
    "HFRI 500": 146.83963201487717
  },
  {
    "Date": "2012-10-01",
    "MQE Strategy": 131.82926292963018,
    "HFRI 500": 146.39911311883253
  },
  {
    "Date": "2012-11-01",
    "MQE Strategy": 126.95568301171892,
    "HFRI 500": 147.17502841836236
  },
  {
    "Date": "2012-12-01",
    "MQE Strategy": 130.93491695444334,
    "HFRI 500": 149.4268063531633
  },
  {
    "Date": "2013-01-01",
    "MQE Strategy": 136.026362126468,
    "HFRI 500": 153.46133012469872
  },
  {
    "Date": "2013-02-01",
    "MQE Strategy": 139.12132930922903,
    "HFRI 500": 153.9370602480853
  },
  {
    "Date": "2013-03-01",
    "MQE Strategy": 142.0789755652935,
    "HFRI 500": 155.3686749083925
  },
  {
    "Date": "2013-04-01",
    "MQE Strategy": 142.10277681631987,
    "HFRI 500": 156.87575105500392
  },
  {
    "Date": "2013-05-01",
    "MQE Strategy": 152.53191512697418,
    "HFRI 500": 157.83269313643945
  },
  {
    "Date": "2013-06-01",
    "MQE Strategy": 150.03745462503866,
    "HFRI 500": 155.30737004625638
  },
  {
    "Date": "2013-07-01",
    "MQE Strategy": 154.01577227547006,
    "HFRI 500": 157.3263658568577
  },
  {
    "Date": "2013-08-01",
    "MQE Strategy": 150.68006605415857,
    "HFRI 500": 156.38240766171657
  },
  {
    "Date": "2013-09-01",
    "MQE Strategy": 155.3980215481054,
    "HFRI 500": 158.71250553587612
  },
  {
    "Date": "2013-10-01",
    "MQE Strategy": 159.70589154373403,
    "HFRI 500": 161.37887562887886
  },
  {
    "Date": "2013-11-01",
    "MQE Strategy": 163.9963631600913,
    "HFRI 500": 163.26700847373675
  },
  {
    "Date": "2013-12-01",
    "MQE Strategy": 163.53958027771725,
    "HFRI 500": 165.07927226779523
  },
  {
    "Date": "2014-01-01",
    "MQE Strategy": 168.31429002235078,
    "HFRI 500": 164.25387590645627
  },
  {
    "Date": "2014-02-01",
    "MQE Strategy": 168.30788715059583,
    "HFRI 500": 167.02976640927537
  },
  {
    "Date": "2014-03-01",
    "MQE Strategy": 170.15698734653233,
    "HFRI 500": 166.19461757722897
  },
  {
    "Date": "2014-04-01",
    "MQE Strategy": 171.35250295442484,
    "HFRI 500": 165.36364448934285
  },
  {
    "Date": "2014-05-01",
    "MQE Strategy": 172.55990801353437,
    "HFRI 500": 167.39761731656176
  },
  {
    "Date": "2014-06-01",
    "MQE Strategy": 177.3840640191031,
    "HFRI 500": 169.27247063050726
  },
  {
    "Date": "2014-07-01",
    "MQE Strategy": 180.66576486660674,
    "HFRI 500": 168.59538074798522
  },
  {
    "Date": "2014-08-01",
    "MQE Strategy": 180.49275323766705,
    "HFRI 500": 170.75340162155942
  },
  {
    "Date": "2014-09-01",
    "MQE Strategy": 181.62693313340765,
    "HFRI 500": 170.58264821993785
  },
  {
    "Date": "2014-10-01",
    "MQE Strategy": 174.29276200551428,
    "HFRI 500": 170.24148292349798
  },
  {
    "Date": "2014-11-01",
    "MQE Strategy": 186.8620909295873,
    "HFRI 500": 172.43759805321108
  },
  {
    "Date": "2014-12-01",
    "MQE Strategy": 182.1304022255415,
    "HFRI 500": 172.57554813165365
  },
  {
    "Date": "2015-01-01",
    "MQE Strategy": 184.85737748398265,
    "HFRI 500": 173.45568342712508
  },
  {
    "Date": "2015-02-01",
    "MQE Strategy": 191.95811756307734,
    "HFRI 500": 176.47381231875707
  },
  {
    "Date": "2015-03-01",
    "MQE Strategy": 190.51178370141088,
    "HFRI 500": 177.5503025739015
  },
  {
    "Date": "2015-04-01",
    "MQE Strategy": 192.27026035470692,
    "HFRI 500": 179.14825529706658
  },
  {
    "Date": "2015-05-01",
    "MQE Strategy": 194.90661207050778,
    "HFRI 500": 180.68893029262134
  },
  {
    "Date": "2015-06-01",
    "MQE Strategy": 190.80837187265138,
    "HFRI 500": 177.7979074079394
  },
  {
    "Date": "2015-07-01",
    "MQE Strategy": 194.82240257391865,
    "HFRI 500": 178.06460426905133
  },
  {
    "Date": "2015-08-01",
    "MQE Strategy": 192.4570051937459,
    "HFRI 500": 174.14718297513218
  },
  {
    "Date": "2015-09-01",
    "MQE Strategy": 180.05610643714735,
    "HFRI 500": 172.0574167794306
  },
  {
    "Date": "2015-10-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 174.5006320976985
  },
  {
    "Date": "2015-11-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 175.0066839307818
  },
  {
    "Date": "2015-12-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 173.9566438271971
  },
  {
    "Date": "2016-01-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 169.95564101917157
  },
  {
    "Date": "2016-02-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 169.10586281407572
  },
  {
    "Date": "2016-03-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 171.5917189974426
  },
  {
    "Date": "2016-04-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 172.38104090483085
  },
  {
    "Date": "2016-05-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 173.3463747338979
  },
  {
    "Date": "2016-06-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 173.3463747338979
  },
  {
    "Date": "2016-07-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 176.1025820921669
  },
  {
    "Date": "2016-08-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 176.64850009665264
  },
  {
    "Date": "2016-09-01",
    "MQE Strategy": 186.16090383949089,
    "HFRI 500": 177.5847371471649
  },
  {
    "Date": "2016-10-01",
    "MQE Strategy": 185.06261468625064,
    "HFRI 500": 176.69681346142906
  },
  {
    "Date": "2016-11-01",
    "MQE Strategy": 191.3006939573278,
    "HFRI 500": 177.43894007796706
  },
  {
    "Date": "2016-12-01",
    "MQE Strategy": 196.900880803122,
    "HFRI 500": 178.96491496263755
  },
  {
    "Date": "2017-01-01",
    "MQE Strategy": 197.9547641618433,
    "HFRI 500": 180.91563253573028
  },
  {
    "Date": "2017-02-01",
    "MQE Strategy": 204.61406107710133,
    "HFRI 500": 182.833338240609
  },
  {
    "Date": "2017-03-01",
    "MQE Strategy": 206.5556353903686,
    "HFRI 500": 183.61952159504364
  },
  {
    "Date": "2017-04-01",
    "MQE Strategy": 204.42696182995607,
    "HFRI 500": 184.8130484854114
  },
  {
    "Date": "2017-05-01",
    "MQE Strategy": 209.06636875797696,
    "HFRI 500": 185.71863242298988
  },
  {
    "Date": "2017-06-01",
    "MQE Strategy": 213.5169129551469,
    "HFRI 500": 186.09006968783586
  },
  {
    "Date": "2017-07-01",
    "MQE Strategy": 214.01121956255497,
    "HFRI 500": 188.2114964822772
  },
  {
    "Date": "2017-08-01",
    "MQE Strategy": 211.33342267231376,
    "HFRI 500": 189.7171884541354
  },
  {
    "Date": "2017-09-01",
    "MQE Strategy": 217.90395049211716,
    "HFRI 500": 190.66577439640605
  },
  {
    "Date": "2017-10-01",
    "MQE Strategy": 222.5833651287885,
    "HFRI 500": 193.48762785747286
  },
  {
    "Date": "2017-11-01",
    "MQE Strategy": 224.71552316302933,
    "HFRI 500": 194.0293932154738
  },
  {
    "Date": "2017-12-01",
    "MQE Strategy": 234.11616612231106,
    "HFRI 500": 195.62043423984065
  },
  {
    "Date": "2018-01-01",
    "MQE Strategy": 242.47771348985023,
    "HFRI 500": 200.86306187746837
  },
  {
    "Date": "2018-02-01",
    "MQE Strategy": 237.77652089175209,
    "HFRI 500": 197.44838982555143
  },
  {
    "Date": "2018-03-01",
    "MQE Strategy": 236.09689827256318,
    "HFRI 500": 196.6585962662492
  },
  {
    "Date": "2018-04-01",
    "MQE Strategy": 233.04401052278288,
    "HFRI 500": 197.11091103766157
  },
  {
    "Date": "2018-05-01",
    "MQE Strategy": 237.84527550948576,
    "HFRI 500": 197.93877686401973
  },
  {
    "Date": "2018-06-01",
    "MQE Strategy": 241.39074887655164,
    "HFRI 500": 197.14702175656365
  },
  {
    "Date": "2018-07-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 197.7384628218333
  },
  {
    "Date": "2018-08-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 197.7384628218333
  },
  {
    "Date": "2018-09-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 197.7384628218333
  },
  {
    "Date": "2018-10-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 192.59726278846566
  },
  {
    "Date": "2018-11-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 191.63427647452332
  },
  {
    "Date": "2018-12-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 189.1430308803545
  },
  {
    "Date": "2019-01-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 194.59035016970873
  },
  {
    "Date": "2019-02-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 196.41949946130399
  },
  {
    "Date": "2019-03-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 197.9908554569944
  },
  {
    "Date": "2019-04-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 200.48554023575255
  },
  {
    "Date": "2019-05-01",
    "MQE Strategy": 243.5385657962281,
    "HFRI 500": 198.28019929315928
  },
  {
    "Date": "2019-06-01",
    "MQE Strategy": 247.77784659599646,
    "HFRI 500": 201.96821100001202
  },
  {
    "Date": "2019-07-01",
    "MQE Strategy": 247.77784659599646,
    "HFRI 500": 203.20021708711207
  },
  {
    "Date": "2019-08-01",
    "MQE Strategy": 240.32633290271218,
    "HFRI 500": 201.98101578458937
  },
  {
    "Date": "2019-09-01",
    "MQE Strategy": 246.43467845575026,
    "HFRI 500": 201.7790347688048
  },
  {
    "Date": "2019-10-01",
    "MQE Strategy": 246.43467845575026,
    "HFRI 500": 202.80810784612572
  },
  {
    "Date": "2019-11-01",
    "MQE Strategy": 246.43467845575026,
    "HFRI 500": 204.49141514124855
  },
  {
    "Date": "2019-12-01",
    "MQE Strategy": 246.43467845575026,
    "HFRI 500": 208.07001490622042
  },
  {
    "Date": "2020-01-01",
    "MQE Strategy": 257.10378527402725,
    "HFRI 500": 207.653874876408
  },
  {
    "Date": "2020-02-01",
    "MQE Strategy": 261.00632433976403,
    "HFRI 500": 203.70845125375624
  },
  {
    "Date": "2020-03-01",
    "MQE Strategy": 184.25015570387907,
    "HFRI 500": 187.61548360470948
  },
  {
    "Date": "2020-04-01",
    "MQE Strategy": 217.9963702436798,
    "HFRI 500": 195.38276462594445
  },
  {
    "Date": "2020-05-01",
    "MQE Strategy": 208.34711679403512,
    "HFRI 500": 199.4076495772389
  },
  {
    "Date": "2020-06-01",
    "MQE Strategy": 200.6915159243353,
    "HFRI 500": 202.7377573251788
  },
  {
    "Date": "2020-07-01",
    "MQE Strategy": 189.25857263065083,
    "HFRI 500": 208.04948656709846
  },
  {
    "Date": "2020-08-01",
    "MQE Strategy": 196.83335616238807,
    "HFRI 500": 212.0648416578435
  },
  {
    "Date": "2020-09-01",
    "MQE Strategy": 190.95918822001377,
    "HFRI 500": 210.1562580829229
  },
  {
    "Date": "2020-10-01",
    "MQE Strategy": 199.44829862599894,
    "HFRI 500": 210.34539871519752
  },
  {
    "Date": "2020-11-01",
    "MQE Strategy": 211.08780704866766,
    "HFRI 500": 221.47267030723143
  },
  {
    "Date": "2020-12-01",
    "MQE Strategy": 215.04602056007957,
    "HFRI 500": 230.6194915909201
  },
  {
    "Date": "2021-01-01",
    "MQE Strategy": 219.31386447052037,
    "HFRI 500": 232.53363337112472
  },
  {
    "Date": "2021-02-01",
    "MQE Strategy": 229.00890080765154,
    "HFRI 500": 240.95135089915942
  },
  {
    "Date": "2021-03-01",
    "MQE Strategy": 230.99411036985492,
    "HFRI 500": 242.5898200852737
  },
  {
    "Date": "2021-04-01",
    "MQE Strategy": 242.30361255061413,
    "HFRI 500": 248.19364492924345
  },
  {
    "Date": "2021-05-01",
    "MQE Strategy": 242.30537447845043,
    "HFRI 500": 251.32088485535195
  },
  {
    "Date": "2021-06-01",
    "MQE Strategy": 245.884702459592,
    "HFRI 500": 252.2759042178023
  },
  {
    "Date": "2021-07-01",
    "MQE Strategy": 247.84606915962414,
    "HFRI 500": 250.50997288827767
  },
  {
    "Date": "2021-08-01",
    "MQE Strategy": 260.7211578956508,
    "HFRI 500": 251.91282873645204
  },
  {
    "Date": "2021-09-01",
    "MQE Strategy": 260.7211578956508,
    "HFRI 500": 251.15709025024267
  },
  {
    "Date": "2021-10-01",
    "MQE Strategy": 268.4230186612611,
    "HFRI 500": 255.05002514912144
  },
  {
    "Date": "2021-11-01",
    "MQE Strategy": 280.1699492037646,
    "HFRI 500": 251.22427477188464
  },
  {
    "Date": "2021-12-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 253.68627266464912
  },
  {
    "Date": "2022-01-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 249.62729230201472
  },
  {
    "Date": "2022-02-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 249.92684505277717
  },
  {
    "Date": "2022-03-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 254.1256160496638
  },
  {
    "Date": "2022-04-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 251.58435988916716
  },
  {
    "Date": "2022-05-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 250.32643808972134
  },
  {
    "Date": "2022-06-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 243.81795069938858
  },
  {
    "Date": "2022-07-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 246.3536573866622
  },
  {
    "Date": "2022-08-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 247.14198909029955
  },
  {
    "Date": "2022-09-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 241.7048653303129
  },
  {
    "Date": "2022-10-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 244.50864176814457
  },
  {
    "Date": "2022-11-01",
    "MQE Strategy": 273.3027227755376,
    "HFRI 500": 246.97817905000284
  },
  {
    "Date": "2022-12-01",
    "MQE Strategy": 291.83786008122485,
    "HFRI 500": 245.7432881547528
  },
  {
    "Date": "2023-01-01",
    "MQE Strategy": 278.59779969486624,
    "HFRI 500": 251.22336348060378
  },
  {
    "Date": "2023-02-01",
    "MQE Strategy": 273.13387918922365,
    "HFRI 500": 250.21847002668136
  },
  {
    "Date": "2023-03-01",
    "MQE Strategy": 264.5952009893346,
    "HFRI 500": 246.7154114463078
  },
  {
    "Date": "2023-04-01",
    "MQE Strategy": 277.9703470413759,
    "HFRI 500": 247.11015610462192
  },
  {
    "Date": "2023-05-01",
    "MQE Strategy": 276.9632733745075,
    "HFRI 500": 246.36882563630803
  },
  {
    "Date": "2023-06-01",
    "MQE Strategy": 259.7968585476063,
    "HFRI 500": 251.56720785723414
  }
]

export default data;