const data = [
  {
    "Date": "2016-08-01",
    "MQE Strategy": 0,
    "HFRI 500": 0.31,
    "S&P 500": 0.12
  },
  {
    "Date": "2016-09-01",
    "MQE Strategy": 0.72,
    "HFRI 500": 0.84,
    "S&P 500": -0.38
  },
  {
    "Date": "2016-10-01",
    "MQE Strategy": -1.2,
    "HFRI 500": 0.31,
    "S&P 500": -2.1
  },
  {
    "Date": "2016-11-01",
    "MQE Strategy": 0.8,
    "HFRI 500": 0.73,
    "S&P 500": 1.5
  },
  {
    "Date": "2016-12-01",
    "MQE Strategy": 1.56,
    "HFRI 500": 1.59,
    "S&P 500": 2.95
  },
  {
    "Date": "2017-01-01",
    "MQE Strategy": 5.01,
    "HFRI 500": 2.7,
    "S&P 500": 4.79
  },
  {
    "Date": "2017-02-01",
    "MQE Strategy": 7.08,
    "HFRI 500": 3.79,
    "S&P 500": 8.91
  },
  {
    "Date": "2017-03-01",
    "MQE Strategy": 8.75,
    "HFRI 500": 4.24,
    "S&P 500": 8.58
  },
  {
    "Date": "2017-04-01",
    "MQE Strategy": 10.05,
    "HFRI 500": 4.91,
    "S&P 500": 9.65
  },
  {
    "Date": "2017-05-01",
    "MQE Strategy": 11.82,
    "HFRI 500": 5.43,
    "S&P 500": 11.2
  },
  {
    "Date": "2017-06-01",
    "MQE Strategy": 12.16,
    "HFRI 500": 5.64,
    "S&P 500": 11.37
  },
  {
    "Date": "2017-07-01",
    "MQE Strategy": 16.67,
    "HFRI 500": 6.84,
    "S&P 500": 13.66
  },
  {
    "Date": "2017-08-01",
    "MQE Strategy": 21.04,
    "HFRI 500": 7.7,
    "S&P 500": 13.99
  },
  {
    "Date": "2017-09-01",
    "MQE Strategy": 19.52,
    "HFRI 500": 8.24,
    "S&P 500": 15.71
  },
  {
    "Date": "2017-10-01",
    "MQE Strategy": 21.2,
    "HFRI 500": 9.84,
    "S&P 500": 18.44
  },
  {
    "Date": "2017-11-01",
    "MQE Strategy": 21.69,
    "HFRI 500": 10.15,
    "S&P 500": 22.06
  },
  {
    "Date": "2017-12-01",
    "MQE Strategy": 22.58,
    "HFRI 500": 11.05,
    "S&P 500": 22.91
  },
  {
    "Date": "2018-01-01",
    "MQE Strategy": 25.61,
    "HFRI 500": 14.03,
    "S&P 500": 29.84
  },
  {
    "Date": "2018-02-01",
    "MQE Strategy": 26.55,
    "HFRI 500": 12.06,
    "S&P 500": 25.12
  },
  {
    "Date": "2018-03-01",
    "MQE Strategy": 28.95,
    "HFRI 500": 11.59,
    "S&P 500": 21.2
  },
  {
    "Date": "2018-04-01",
    "MQE Strategy": 33.34,
    "HFRI 500": 11.85,
    "S&P 500": 21.83
  },
  {
    "Date": "2018-05-01",
    "MQE Strategy": 35.13,
    "HFRI 500": 12.32,
    "S&P 500": 24.79
  },
  {
    "Date": "2018-06-01",
    "MQE Strategy": 36.21,
    "HFRI 500": 11.86,
    "S&P 500": 24.94
  },
  {
    "Date": "2018-07-01",
    "MQE Strategy": 41.38,
    "HFRI 500": 12.2,
    "S&P 500": 29.57
  },
  {
    "Date": "2018-08-01",
    "MQE Strategy": 42.11,
    "HFRI 500": 12.14,
    "S&P 500": 33.71
  },
  {
    "Date": "2018-09-01",
    "MQE Strategy": 43.97,
    "HFRI 500": 12.04,
    "S&P 500": 33.9
  },
  {
    "Date": "2018-10-01",
    "MQE Strategy": 46.91,
    "HFRI 500": 9.07,
    "S&P 500": 24.65
  },
  {
    "Date": "2018-11-01",
    "MQE Strategy": 44.82,
    "HFRI 500": 8.48,
    "S&P 500": 26.96
  },
  {
    "Date": "2018-12-01",
    "MQE Strategy": 46.2,
    "HFRI 500": 6.99,
    "S&P 500": 15.11
  },
  {
    "Date": "2019-01-01",
    "MQE Strategy": 51.07,
    "HFRI 500": 10.08,
    "S&P 500": 24.32
  },
  {
    "Date": "2019-02-01",
    "MQE Strategy": 52.64,
    "HFRI 500": 11.11,
    "S&P 500": 28.35
  },
  {
    "Date": "2019-03-01",
    "MQE Strategy": 52.64,
    "HFRI 500": 12,
    "S&P 500": 30.1
  },
  {
    "Date": "2019-04-01",
    "MQE Strategy": 54.93,
    "HFRI 500": 13.41,
    "S&P 500": 35.42
  },
  {
    "Date": "2019-05-01",
    "MQE Strategy": 57.09,
    "HFRI 500": 12.14,
    "S&P 500": 26.78
  },
  {
    "Date": "2019-06-01",
    "MQE Strategy": 56.6,
    "HFRI 500": 14.23,
    "S&P 500": 34.95
  },
  {
    "Date": "2019-07-01",
    "MQE Strategy": 61.76,
    "HFRI 500": 14.92,
    "S&P 500": 36.99
  },
  {
    "Date": "2019-08-01",
    "MQE Strategy": 62.45,
    "HFRI 500": 14.22,
    "S&P 500": 34.7
  },
  {
    "Date": "2019-09-01",
    "MQE Strategy": 60.94,
    "HFRI 500": 14.06,
    "S&P 500": 36.68
  },
  {
    "Date": "2019-10-01",
    "MQE Strategy": 62.75,
    "HFRI 500": 14.64,
    "S&P 500": 39.71
  },
  {
    "Date": "2019-11-01",
    "MQE Strategy": 64.72,
    "HFRI 500": 15.6,
    "S&P 500": 44.76
  },
  {
    "Date": "2019-12-01",
    "MQE Strategy": 66.16,
    "HFRI 500": 17.62,
    "S&P 500": 48.24
  },
  {
    "Date": "2020-01-01",
    "MQE Strategy": 65.6,
    "HFRI 500": 17.35,
    "S&P 500": 48.18
  },
  {
    "Date": "2020-02-01",
    "MQE Strategy": 66.04,
    "HFRI 500": 15.12,
    "S&P 500": 36.45
  },
  {
    "Date": "2020-03-01",
    "MQE Strategy": 63.61,
    "HFRI 500": 5.97,
    "S&P 500": 18.71
  },
  {
    "Date": "2020-04-01",
    "MQE Strategy": 67.24,
    "HFRI 500": 10.35,
    "S&P 500": 33.79
  },
  {
    "Date": "2020-05-01",
    "MQE Strategy": 68.82,
    "HFRI 500": 12.63,
    "S&P 500": 40.16
  },
  {
    "Date": "2020-06-01",
    "MQE Strategy": 68.82,
    "HFRI 500": 14.51,
    "S&P 500": 42.02
  },
  {
    "Date": "2020-07-01",
    "MQE Strategy": 70.26,
    "HFRI 500": 17.51,
    "S&P 500": 50.39
  },
  {
    "Date": "2020-08-01",
    "MQE Strategy": 72.59,
    "HFRI 500": 19.78,
    "S&P 500": 60.88
  },
  {
    "Date": "2020-09-01",
    "MQE Strategy": 74.95,
    "HFRI 500": 18.61,
    "S&P 500": 54.24
  },
  {
    "Date": "2020-10-01",
    "MQE Strategy": 74.95,
    "HFRI 500": 18.72,
    "S&P 500": 50.4
  },
  {
    "Date": "2020-11-01",
    "MQE Strategy": 73.79,
    "HFRI 500": 25,
    "S&P 500": 66.76
  },
  {
    "Date": "2020-12-01",
    "MQE Strategy": 74.5,
    "HFRI 500": 30.11,
    "S&P 500": 72.2
  },
  {
    "Date": "2021-01-01",
    "MQE Strategy": 76.97,
    "HFRI 500": 31.23,
    "S&P 500": 70.44
  },
  {
    "Date": "2021-02-01",
    "MQE Strategy": 75.92,
    "HFRI 500": 35.56,
    "S&P 500": 75.18
  },
  {
    "Date": "2021-03-01",
    "MQE Strategy": 74.31,
    "HFRI 500": 36.43,
    "S&P 500": 82.54
  }
]

export default data;