import React from 'react';
import MathJax from 'react-mathjax-preview'
import {Table} from 'reactstrap'

function MethodologyTextCommodity() {

  return (
    <div className="px-4">
      <h2 className="center text-center mt-2 color-ucla">
          Economic Theory Commodity
        </h2>
        <p className="center text-center methodology-text">
          Most funds use momentum-based forecasting strategies. In contrast, the QuantLab forecasts are based on economic fundamentals, not on the exchange rates’ past behavior. While exchange rates may contain short-run stochastic noise, over time they tend to respond to interest rate news, which in turn reflect macroeconomic fundamentals across countries. In the QuantLab we strive to understand these mechanisms using quantitative methods.
        </p>
        <h2 className="center text-center mt-2 color-ucla">
          Hedge Fund Index
        </h2>
        <p className="center text-center methodology-text">
         The HFRI 500 FWC Index is a global, equal-weighted index of the largest hedge funds that report to the Hedge Fund Research Database. The Index constituents are classified into Equity Hedge, Event Driven, Macro or Relative Value strategies (HFR). The MQE portfolio is classified under “Macro strategies” which, in the aggregate, typically underperforms Hedge funds. A lower return is associated with the Market-Neutral character of Macro strategies. Notice that hedge fund returns are net of fees. 
        </p>
        <h2 className="center text-center mt-2 color-ucla">
        Methodology
        </h2>
      <h3 className="center text-center mt-2 color-ucla">Profit Curves</h3>
      <p className="center text-center methodology-text">We don’t use leverage. For each currency, the percent return is based on the equity resulting from fully investing into that currency’s signals. The returns of the MQE portfolio are obtained by using the following weights on each currency. These weights correspond to the following formula. </p>
      <h3 className="center text-center mt-2 color-ucla">Portfolio Weighting</h3>
      <p className="center text-center methodology-text">Weights of currencies in the portfolio are derived as follows:<br />
        <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <msub>
    <mrow>
      <mi mathcolor="#000000">w</mi>
    </mrow>
    <mrow>
      <mi mathcolor="#000000">c</mi>
      <mi mathcolor="#000000"> </mi>
    </mrow>
  </msub>
</math>`} /> : Weight in portfolio for currency c<br />
        <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <msub>
    <mrow>
      <mi mathcolor="#000000">r</mi>
    </mrow>
    <mrow>
      <mi mathcolor="#000000">i</mi>
    </mrow>
  </msub>
  <mi mathcolor="#000000"> </mi>
  <mo mathcolor="#000000">:</mo>
</math>`} /> The daily percent change of strategy currency i for the last 5 years<br />
        <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <msub>
    <mrow>
      <mi mathcolor="#000000">w</mi>
    </mrow>
    <mrow>
      <mi mathcolor="#000000">c</mi>
      <mi mathcolor="#000000"> </mi>
    </mrow>
  </msub>
  <mo mathcolor="#000000">=</mo>
  <mfrac mathcolor="#000000">
    <mrow>
      <mi mathcolor="#000000">f</mi>
      <mo stretchy="false" mathcolor="#000000">(</mo>
      <msub>
        <mrow>
          <mi mathcolor="#000000">r</mi>
        </mrow>
        <mrow>
          <mi mathcolor="#000000">i</mi>
        </mrow>
      </msub>
      <mo stretchy="false" mathcolor="#000000">)</mo>
    </mrow>
    <mrow>
      <msubsup mathcolor="#000000">
        <mrow>
          <mo mathcolor="#000000">∑</mo>
        </mrow>
        <mrow>
          <mi mathcolor="#000000">j</mi>
          <mo mathcolor="#000000">=</mo>
          <mn mathcolor="#000000">0</mn>
        </mrow>
        <mrow>
          <mi mathcolor="#000000">n</mi>
        </mrow>
      </msubsup>
      <mi mathcolor="#000000"> </mi>
      <mi mathcolor="#000000">f</mi>
      <mo stretchy="false" mathcolor="#000000">(</mo>
      <msub>
        <mrow>
          <mi mathcolor="#000000">r</mi>
        </mrow>
        <mrow>
          <mi mathcolor="#000000">j</mi>
        </mrow>
      </msub>
      <mo stretchy="false" mathcolor="#000000">)</mo>
    </mrow>
  </mfrac>
</math>`} /> <br />
        <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi mathcolor="#000000">m</mi>
  <mo mathcolor="#000000">:</mo>
</math>`} /> Number of observations of currency price<br />
        <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi mathcolor="#000000">n</mi>
  <mo mathcolor="#000000">:</mo>
</math>`} /> Number of currency pairs in portfolio <br />
       Where <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi mathcolor="#000000">f</mi>
  <mo stretchy="false" mathcolor="#000000">(</mo>
  <msub>
    <mrow>
      <mi mathcolor="#000000">r</mi>
    </mrow>
    <mrow>
      <mi mathcolor="#000000">i</mi>
    </mrow>
  </msub>
  <mo stretchy="false" mathcolor="#000000">)</mo>
  <mi mathcolor="#000000"> </mi>
  <mo mathcolor="#000000">:</mo>
  <mi mathcolor="#000000"> </mi>
  <mfrac mathcolor="#000000">
    <mrow>
      <mi mathcolor="#000000">m</mi>
      <mi mathcolor="#000000"> </mi>
      <mo mathcolor="#000000">-</mo>
      <mi mathcolor="#000000"> </mi>
      <mn mathcolor="#000000">1</mn>
    </mrow>
    <mrow>
      <msubsup mathcolor="#000000">
        <mrow>
          <mo mathcolor="#000000">∑</mo>
        </mrow>
        <mrow>
          <mi mathcolor="#000000">i</mi>
          <mo mathcolor="#000000">=</mo>
          <mn mathcolor="#000000">1</mn>
        </mrow>
        <mrow>
          <mi mathcolor="#000000">m</mi>
        </mrow>
      </msubsup>
      <mo stretchy="false" mathcolor="#000000">(</mo>
      <msub>
        <mrow>
          <mi mathcolor="#000000">r</mi>
        </mrow>
        <mrow>
          <mi mathcolor="#000000">i</mi>
          <mi mathcolor="#000000"> </mi>
        </mrow>
      </msub>
      <mo mathcolor="#000000">-</mo>
      <mi mathcolor="#000000"> </mi>
      <mover accentunder="false">
        <mrow>
          <mi mathcolor="#000000">r</mi>
        </mrow>
        <mo mathcolor="#000000">_</mo>
      </mover>
      <msup>
        <mrow>
          <mo stretchy="false" mathcolor="#000000">)</mo>
        </mrow>
        <mrow>
          <mn mathcolor="#000000">2</mn>
        </mrow>
      </msup>
      <mi mathcolor="#000000"> </mi>
    </mrow>
  </mfrac>
</math>`} /> <br />
      <Table striped bordered responsive>
          <thead>
            <tr>
              <th>EURUSD</th>
              <th>JPYUSD</th>
              <th>CADUSD</th>
              <th>GBPUSD</th>
              <th>AUDUSD</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>23%</td>
              <td>23%</td>
              <td>16%</td>
              <td>13%</td>
              <td>25%</td>
            </tr>
          </tbody>
        </Table>
      
      </p>
      <h3 className="center text-center mt-2 color-ucla">Sharpe</h3>
      <p className="center text-center methodology-text">Using annual returns <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi>R</mi>
  <msub>
    <mrow/>
    <mrow>
      <mi>t</mi>
    </mrow>
  </msub>
</math>`} />, the excess return is this average annual return less the risk-free rate <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
<mi>D</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>t</mi>
  </mrow>
</msub>
<mo>=</mo>
<mi>R</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>t</mi>
  </mrow>
</msub>
<mo>-</mo>
<mi>R</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>f</mi>
    <mi>t</mi>
  </mrow>
</msub>
</math>`} />.  Furthermore, the average excess return is calculated <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
<mover accentunder="false">
  <mrow>
    <mi>D</mi>
  </mrow>
  <mo>_</mo>
</mover>
<mo>=</mo>
<mfrac>
  <mrow>
    <mn>1</mn>
  </mrow>
  <mrow>
    <mi>T</mi>
  </mrow>
</mfrac>
<mrow>
  <munderover>
    <mo stretchy="false">∑</mo>
    <mrow>
      <mi>t</mi>
      <mo>=</mo>
      <mn>1</mn>
    </mrow>
    <mrow>
      <mi>T</mi>
    </mrow>
  </munderover>
  <mrow/>
</mrow>
<mi>D</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>t</mi>
  </mrow>
</msub>
<mi> </mi>
</math>`} /> in which T is the total number of whole years of available data. This average is then divided by the annualized standard deviation of excess return <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
<mi>σ</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>a</mi>
  </mrow>
</msub>
<mo>=</mo>
<msqrt>
  <mfrac>
    <mrow>
      <mrow>
        <munderover>
          <mo stretchy="false">∑</mo>
          <mrow>
            <mi>t</mi>
            <mo>=</mo>
            <mn>1</mn>
          </mrow>
          <mrow>
            <mi>T</mi>
          </mrow>
        </munderover>
        <mrow/>
      </mrow>
      <mo stretchy="false">(</mo>
      <msub>
        <mrow>
          <mi>D</mi>
        </mrow>
        <mrow>
          <mi>t</mi>
        </mrow>
      </msub>
      <mo>-</mo>
      <munder accentunder="false">
        <mrow>
          <mi>D</mi>
        </mrow>
        <mo>_</mo>
      </munder>
      <mo stretchy="false">)</mo>
      <msup>
        <mrow/>
        <mrow>
          <mn>2</mn>
        </mrow>
      </msup>
    </mrow>
    <mrow>
      <mi>T</mi>
      <mo>-</mo>
      <mn>1</mn>
    </mrow>
  </mfrac>
</msqrt>
</math>`} /> to yield the historical Sharpe.<br /><MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
<mi>S</mi>
<mo>=</mo>
<mfrac>
  <mrow>
    <munder accentunder="false">
      <mrow>
        <mi>D</mi>
      </mrow>
      <mo>_</mo>
    </munder>
  </mrow>
  <mrow>
    <mi>σ</mi>
    <msub>
      <mrow/>
      <mrow>
        <mi>a</mi>
      </mrow>
    </msub>
  </mrow>
</mfrac>
</math>`} /></p>
      <h3 className="center text-center mt-2 color-ucla">Risk-Free Rate</h3>
      <p className="center text-center methodology-text">The Risk-Free rate used is the 1-year U.S. treasury yield, <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi>R</mi>
  <msub>
    <mrow/>
    <mrow>
      <mi>f</mi>
    </mrow>
  </msub>
</math>`} /> (ticker: ^US1Y).</p>
      
      <h3 className="center text-center mt-2 color-ucla">Beta</h3>
      <p className="center text-center methodology-text">The excess return of the market <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi>r</mi>
  <msub>
    <mrow/>
    <mrow>
      <mi>m</mi>
    </mrow>
  </msub>
</math>`} /> is an identical calculation as the excess return of the portfolio described above <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
<mi>r</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>p</mi>
  </mrow>
</msub>
<mo>=</mo>
<mi>R</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>t</mi>
  </mrow>
</msub>
<mo>-</mo>
<mi> </mi>
<mi>R</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>f</mi>
  </mrow>
</msub>
</math>`} />. Market Beta is subsequently calculated as <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
<mi>β</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>p</mi>
  </mrow>
</msub>
<mo>=</mo>
<mfrac>
  <mrow>
    <mi>c</mi>
    <mi>o</mi>
    <mi>v</mi>
    <mo stretchy="false">(</mo>
    <mi>r</mi>
    <msub>
      <mrow/>
      <mrow>
        <mi>m</mi>
      </mrow>
    </msub>
    <mo>,</mo>
    <mi> </mi>
    <mi>r</mi>
    <msub>
      <mrow/>
      <mrow>
        <mi>p</mi>
      </mrow>
    </msub>
    <mo stretchy="false">)</mo>
  </mrow>
  <mrow>
    <mi>v</mi>
    <mi>a</mi>
    <mi>r</mi>
    <mo stretchy="false">(</mo>
    <mi>r</mi>
    <msub>
      <mrow/>
      <mrow>
        <mi>m</mi>
      </mrow>
    </msub>
    <mo stretchy="false">)</mo>
  </mrow>
</mfrac>
</math>`} /> which by definition is equivalent to the regression of a portfolio's excess returns on the market.</p>
      <h3 className="center text-center mt-2 color-ucla">Alpha</h3>
      <p className="center text-center methodology-text">Alpha is often associated with the competency of an actively managed portfolio. This metric measures the  “abnormal” rate of return for a given volatility. It is generated by regressing a portfolio's excess returns on the S &amp; P 500 returns. <br /> <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi>α</mi>
  <msub>
    <mrow/>
    <mrow>
      <mi>p</mi>
    </mrow>
  </msub>
  <mo>=</mo>
  <mi>r</mi>
  <msub>
    <mrow/>
    <mrow>
      <mi>p</mi>
    </mrow>
  </msub>
  <mo>+</mo>
  <mi>β</mi>
  <mo>⋅</mo>
  <mi>r</mi>
  <msub>
    <mrow/>
    <mrow>
      <mi>m</mi>
    </mrow>
  </msub>
</math>`} /></p>
      
      <h3 className="center text-center mt-2 color-ucla">Up/Down Alpha/Beta</h3>
      <p className="center text-center methodology-text">The calculation of these metrics is identical to Alpha and Beta above but using the following subset of data:<br />
      <b>Up:</b> months when the S&amp;P yielded a higher than 0% return<br />
      <b>Down:</b> months when the S&amp;P yielded a lower than 0% return
</p>
      <h3 className="center text-center mt-2 color-ucla">Max Drawdown</h3>
      <p className="center text-center methodology-text">A maximum drawdown (MDD) is the maximum observed loss from a peak to a trough of a portfolio, before a new peak is attained. Maximum drawdown is an indicator of downside risk over a specified time period:
      <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi>M</mi>
  <mi>D</mi>
  <mi>D</mi>
  <mi> </mi>
  <mo>=</mo>
  <mi> </mi>
  <mfrac>
    <mrow>
      <mi>T</mi>
      <mi>r</mi>
      <mi>o</mi>
      <mi>u</mi>
      <mi>g</mi>
      <mi>h</mi>
      <mi> </mi>
      <mi>V</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi>u</mi>
      <mi>e</mi>
      <mo>-</mo>
      <mi>P</mi>
      <mi>e</mi>
      <mi>a</mi>
      <mi>k</mi>
      <mi> </mi>
      <mi>V</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi>u</mi>
      <mi>e</mi>
    </mrow>
    <mrow>
      <mi>P</mi>
      <mi>e</mi>
      <mi>a</mi>
      <mi>k</mi>
      <mi> </mi>
      <mi>V</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi>u</mi>
      <mi>e</mi>
    </mrow>
  </mfrac>
</math>`} />
      </p>
      <h3 className="center text-center mt-2 color-ucla">Gini</h3>
      <p className="center text-center methodology-text">The Gini Coefficient, <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi>G</mi>
</math>`} />, is typically used to measure income inequality. Here we use it to measure how returns are evenly spread across months (the smaller the Gini coefficient, the more evenly spread). To begin, every month’s return is ordered from lowest to highest <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
<mo stretchy="false">{</mo>
<mi>y</mi>
<msub>
  <mrow/>
  <mrow>
    <mn>1</mn>
  </mrow>
</msub>
<mo>,</mo>
<mi>y</mi>
<msub>
  <mrow/>
  <mrow>
    <mn>2</mn>
  </mrow>
</msub>
<mo>,</mo>
<mo>.</mo>
<mo>.</mo>
<mo>.</mo>
<mo>,</mo>
<mi>y</mi>
<msub>
  <mrow/>
  <mrow>
    <mi>n</mi>
  </mrow>
</msub>
<mo stretchy="false">}</mo>
</math>`} />. These ordered returns are then segmented into 10 deciles. The returns are summed together to get a point value associated with each decile. Still ordered, these deciles are cumulatively summed to generate the Lorenz Curve. A straight line is constructed from 0 to the final cumulative value. The straight line represents perfectly homogenous returns. The more similar the Lorenz Curve is to a straight line, the more consistent returns over time. The consistency is quantified by measuring the area between the Lorenz Curve and a straight line in relation to the entire area under the same straight line.<br />
        <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mi>G</mi>
  <mo>=</mo>
  <mfrac>
    <mrow>
      <mi>A</mi>
      <mo>+</mo>
      <mi>B</mi>
    </mrow>
    <mrow>
      <mo stretchy="false">(</mo>
      <mi>A</mi>
      <mo>+</mo>
      <mi>B</mi>
      <mo stretchy="false">)</mo>
      <mo>+</mo>
      <mi>C</mi>
    </mrow>
  </mfrac>
</math>`} />
    </p>
      <h3 className="center text-center mt-2 color-ucla">Forecast Success Ratio</h3>
      <p className="center text-center methodology-text">Forecast Success Ratio is calculated as <MathJax className="equation" wrapperTag="span" math={`<math xmlns="http://www.w3.org/1998/Math/MathML">
  <mfrac>
    <mrow>
      <mi>N</mi>
      <mi>u</mi>
      <mi>m</mi>
      <mi>b</mi>
      <mi>e</mi>
      <mi>r</mi>
      <mi> </mi>
      <mi>o</mi>
      <mi>f</mi>
      <mi> </mi>
      <mi>C</mi>
      <mi>o</mi>
      <mi>r</mi>
      <mi>r</mi>
      <mi>e</mi>
      <mi>c</mi>
      <mi>t</mi>
      <mi> </mi>
      <mi>D</mi>
      <mi>i</mi>
      <mi>r</mi>
      <mi>e</mi>
      <mi>c</mi>
      <mi>t</mi>
      <mi>i</mi>
      <mi>o</mi>
      <mi>n</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi> </mi>
      <mi>S</mi>
      <mi>i</mi>
      <mi>g</mi>
      <mi>n</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi>s</mi>
    </mrow>
    <mrow>
      <mi>T</mi>
      <mi>o</mi>
      <mi>t</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi> </mi>
      <mi>N</mi>
      <mi>u</mi>
      <mi>m</mi>
      <mi>b</mi>
      <mi>e</mi>
      <mi>r</mi>
      <mi> </mi>
      <mi>o</mi>
      <mi>f</mi>
      <mi> </mi>
      <mi>D</mi>
      <mi>i</mi>
      <mi>r</mi>
      <mi>e</mi>
      <mi>c</mi>
      <mi>t</mi>
      <mi>i</mi>
      <mi>o</mi>
      <mi>n</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi> </mi>
      <mi>S</mi>
      <mi>i</mi>
      <mi>g</mi>
      <mi>n</mi>
      <mi>a</mi>
      <mi>l</mi>
      <mi>s</mi>
    </mrow>
  </mfrac>
</math>`} /></p>
      <h2 className="center text-center mt-2 color-ucla">
        Portfolio Construction
        </h2>
        <p className="center text-center">
        The portfolio is constructed by a recursive variance weighting. The inverse of the underlying asset with respect to the sum of the assets’ variances, gives a proportion of the portfolio held in the corresponding strategy. The variances are calculated recursively from the beginning of the available data.
        </p>
        <h2 className="center text-center mt-2 color-ucla">
          Real-Time Forecasts
        </h2>
        <p className="center text-center">
        All signals disseminated through the MQE lab are published daily at 1:30 pm PT. In order to prevent look-ahead bias of our signals, we employ two methods of digital time stamps. First, all signal entry and exits are validated on Amazon QLDB, a Cryptographic Database, which is a fully managed ledger database that provides transparent, immutable and verified transaction logs owned by a central authority (AWS). Colloquially known as centralized blockchain. Second, everyday along with website updates, our algorithm posts the current signals to the Quant Lab Twitter.
        </p>
        <p className="center text-center">Twitter: @UCLAQuantLab</p>
      </div>
  );
}

export default MethodologyTextCommodity;
