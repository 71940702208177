const data = {
  returns: [{
    "Sharpe": 2.21,
    "Annualized Return": "12.645%",
    "Forecast Success Ratio": "57.2%",
    "Avg. Annual Return": 12.645,
    "Market Beta": 0.05,
    "Monthly Alpha": "0.85%",
    "Correlation with S&P": 0.17,
    "Max Drawdown": "-1.91%",
    "% of Winning Mo.": "75.0%",
    "Gini": 0.413,
    "Forecast Accuracy": "",
    "Up Alpha": "1.0%",
    "Down Alpha": "0.75%",
    "Up Beta": 0.02,
    "Down Beta": 0.05,
    "Highest Monthly Return": 4.03,
    "Lowest Monthly Return": -1.91,
    "Annualized STD of Returns": "4.73%"
  }],
  hedge : [{
    "Sharpe": 0.72,
    "Annualized Return": "6.883%",
    "Avg. Annual Return": 6.883,
    "Market Beta": 0.36,
    "Monthly Alpha": "0.08%",
    "Correlation with S&P": 0.86,
    "Max Drawdown": "-9.91%",
    "% of Winning Mo.": "71.43%",
    "Gini": 0.413,
    "Forecast Accuracy": "",
    "Up Alpha": "0.08%",
    "Down Alpha": "0.47%",
    "Up Beta": 0.35,
    "Down Beta": 0.44,
    "Highest Monthly Return": 5.29,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "6.41%"
  }]
}

export default data;