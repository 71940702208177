const data = [
  {
    "Date": "2009-12-31",
    "MQE Strategy": 0.00921238405138958,
    "S&P 500": 0.00452213240844568,
    "Buy & Hold Gold": -0.0629706490259341
  },
  {
    "Date": "2010-01-31",
    "MQE Strategy": 0.00921238405138958,
    "S&P 500": -0.0319846586935539,
    "Buy & Hold Gold": -0.0734086629395264
  },
  {
    "Date": "2010-02-28",
    "MQE Strategy": 0.0292796773529473,
    "S&P 500": -0.00178781899388625,
    "Buy & Hold Gold": -0.0432067059294131
  },
  {
    "Date": "2010-03-31",
    "MQE Strategy": 0.023316540075109,
    "S&P 500": 0.0589832350566875,
    "Buy & Hold Gold": -0.0474845976055927
  },
  {
    "Date": "2010-04-30",
    "MQE Strategy": -0.0278058364586792,
    "S&P 500": 0.0753656386778083,
    "Buy & Hold Gold": 0.00966797252373652
  },
  {
    "Date": "2010-05-31",
    "MQE Strategy": -0.028046439674754,
    "S&P 500": -0.0100769270350514,
    "Buy & Hold Gold": 0.0371320161966655
  },
  {
    "Date": "2010-06-30",
    "MQE Strategy": -0.0155643617744469,
    "S&P 500": -0.0612963505249498,
    "Buy & Hold Gold": 0.0656228165363064
  },
  {
    "Date": "2010-07-31",
    "MQE Strategy": -0.0291818893083757,
    "S&P 500": 0.00281767979235248,
    "Buy & Hold Gold": 0.0110368769719706
  },
  {
    "Date": "2010-08-31",
    "MQE Strategy": -0.00573165741453952,
    "S&P 500": -0.0422897508816004,
    "Buy & Hold Gold": 0.0680184776512529
  },
  {
    "Date": "2010-09-30",
    "MQE Strategy": 0.0255307209851963,
    "S&P 500": 0.0434774806481346,
    "Buy & Hold Gold": 0.118925388597788
  },
  {
    "Date": "2010-10-31",
    "MQE Strategy": 0.0334210849628167,
    "S&P 500": 0.0823188438666143,
    "Buy & Hold Gold": 0.161105337860489
  },
  {
    "Date": "2010-11-30",
    "MQE Strategy": 0.0589607694885617,
    "S&P 500": 0.0992149228559969,
    "Buy & Hold Gold": 0.184975994301714
  },
  {
    "Date": "2010-12-31",
    "MQE Strategy": 0.0464724390722979,
    "S&P 500": 0.172700168242203,
    "Buy & Hold Gold": 0.215862351315587
  },
  {
    "Date": "2011-01-31",
    "MQE Strategy": 0.0296493527298867,
    "S&P 500": 0.200024178515491,
    "Buy & Hold Gold": 0.141170425313923
  },
  {
    "Date": "2011-02-28",
    "MQE Strategy": 0.0175626318441851,
    "S&P 500": 0.24170997874565,
    "Buy & Hold Gold": 0.205766589624233
  },
  {
    "Date": "2011-03-31",
    "MQE Strategy": 0.0241099813640469,
    "S&P 500": 0.241859098936368,
    "Buy & Hold Gold": 0.231091687459073
  },
  {
    "Date": "2011-04-30",
    "MQE Strategy": 0.00907308638282811,
    "S&P 500": 0.277825112038091,
    "Buy & Hold Gold": 0.331279889627052
  },
  {
    "Date": "2011-05-31",
    "MQE Strategy": 0.00907308638282811,
    "S&P 500": 0.263494853336501,
    "Buy & Hold Gold": 0.314082785976954
  },
  {
    "Date": "2011-06-30",
    "MQE Strategy": 0.00143064677764415,
    "S&P 500": 0.242179986075741,
    "Buy & Hold Gold": 0.285335374801172
  },
  {
    "Date": "2011-07-31",
    "MQE Strategy": 0.0400587318857484,
    "S&P 500": 0.217330918033213,
    "Buy & Hold Gold": 0.393138245040894
  },
  {
    "Date": "2011-08-31",
    "MQE Strategy": 0.0429537302741261,
    "S&P 500": 0.150407135244432,
    "Buy & Hold Gold": 0.564424985978835
  },
  {
    "Date": "2011-09-30",
    "MQE Strategy": 0.139525766758478,
    "S&P 500": 0.070545259646726,
    "Buy & Hold Gold": 0.386379155304388
  },
  {
    "Date": "2011-10-31",
    "MQE Strategy": 0.130484778254012,
    "S&P 500": 0.148964365219079,
    "Buy & Hold Gold": 0.475188123837445
  },
  {
    "Date": "2011-11-30",
    "MQE Strategy": 0.217028052872215,
    "S&P 500": 0.125278879082886,
    "Buy & Hold Gold": 0.493411984154255
  },
  {
    "Date": "2011-12-31",
    "MQE Strategy": 0.217028052872215,
    "S&P 500": 0.137036377662888,
    "Buy & Hold Gold": 0.339664599088651
  },
  {
    "Date": "2012-01-31",
    "MQE Strategy": 0.265671866006639,
    "S&P 500": 0.189766010382763,
    "Buy & Hold Gold": 0.486824072749225
  },
  {
    "Date": "2012-02-29",
    "MQE Strategy": 0.216715742711453,
    "S&P 500": 0.241408213727993,
    "Buy & Hold Gold": 0.462953416308001
  },
  {
    "Date": "2012-03-31",
    "MQE Strategy": 0.185373088808602,
    "S&P 500": 0.281336680894883,
    "Buy & Hold Gold": 0.428216956785567
  },
  {
    "Date": "2012-04-30",
    "MQE Strategy": 0.172628860156451,
    "S&P 500": 0.272782954803259,
    "Buy & Hold Gold": 0.423169023719532
  },
  {
    "Date": "2012-05-31",
    "MQE Strategy": 0.193520538759711,
    "S&P 500": 0.196344792332694,
    "Buy & Hold Gold": 0.336926685751467
  },
  {
    "Date": "2012-06-30",
    "MQE Strategy": 0.171234317382446,
    "S&P 500": 0.244893872443749,
    "Buy & Hold Gold": 0.371919860550758
  },
  {
    "Date": "2012-07-31",
    "MQE Strategy": 0.148084769295702,
    "S&P 500": 0.251370721305056,
    "Buy & Hold Gold": 0.377908908897409
  },
  {
    "Date": "2012-08-31",
    "MQE Strategy": 0.136002019802784,
    "S&P 500": 0.282720308009904,
    "Buy & Hold Gold": 0.441307242650246
  },
  {
    "Date": "2012-09-30",
    "MQE Strategy": 0.168096366415283,
    "S&P 500": 0.315239130031048,
    "Buy & Hold Gold": 0.515314768648151
  },
  {
    "Date": "2012-10-31",
    "MQE Strategy": 0.181369570745975,
    "S&P 500": 0.295736862196212,
    "Buy & Hold Gold": 0.46945579076765
  },
  {
    "Date": "2012-11-30",
    "MQE Strategy": 0.181369570745975,
    "S&P 500": 0.28453176133112,
    "Buy & Hold Gold": 0.463808994643237
  },
  {
    "Date": "2012-12-31",
    "MQE Strategy": 0.181369570745975,
    "S&P 500": 0.300014216331997,
    "Buy & Hold Gold": 0.432922637629363
  },
  {
    "Date": "2013-01-31",
    "MQE Strategy": 0.191355833255147,
    "S&P 500": 0.366562098308883,
    "Buy & Hold Gold": 0.420773362604585
  },
  {
    "Date": "2013-02-28",
    "MQE Strategy": 0.17612327687073,
    "S&P 500": 0.383997742243167,
    "Buy & Hold Gold": 0.349845897725386
  },
  {
    "Date": "2013-03-31",
    "MQE Strategy": 0.186434762160496,
    "S&P 500": 0.436550262605549,
    "Buy & Hold Gold": 0.364476370810492
  },
  {
    "Date": "2013-04-30",
    "MQE Strategy": 0.22968598690886,
    "S&P 500": 0.464149482049704,
    "Buy & Hold Gold": 0.259582383357999
  },
  {
    "Date": "2013-05-31",
    "MQE Strategy": 0.273233236887037,
    "S&P 500": 0.498717664942937,
    "Buy & Hold Gold": 0.191478368761365
  },
  {
    "Date": "2013-06-30",
    "MQE Strategy": 0.424364089203267,
    "S&P 500": 0.4787179653359,
    "Buy & Hold Gold": 0.0470568084379754
  },
  {
    "Date": "2013-07-31",
    "MQE Strategy": 0.422802843736377,
    "S&P 500": 0.554459781332931,
    "Buy & Hold Gold": 0.122861028051733
  },
  {
    "Date": "2013-08-31",
    "MQE Strategy": 0.390142648752987,
    "S&P 500": 0.507837618485291,
    "Buy & Hold Gold": 0.194472892934692
  },
  {
    "Date": "2013-09-30",
    "MQE Strategy": 0.436163372682779,
    "S&P 500": 0.555555386072035,
    "Buy & Hold Gold": 0.134924661690417
  },
  {
    "Date": "2013-10-31",
    "MQE Strategy": 0.39231518829254,
    "S&P 500": 0.621113023751607,
    "Buy & Hold Gold": 0.13244346363009
  },
  {
    "Date": "2013-11-30",
    "MQE Strategy": 0.39231518829254,
    "S&P 500": 0.669983308269378,
    "Buy & Hold Gold": 0.0590349051312793
  },
  {
    "Date": "2013-12-31",
    "MQE Strategy": 0.384769780364489,
    "S&P 500": 0.709533764884328,
    "Buy & Hold Gold": 0.0283196220081674
  },
  {
    "Date": "2014-01-31",
    "MQE Strategy": 0.322759887202932,
    "S&P 500": 0.649275526616688,
    "Buy & Hold Gold": 0.0610026726378925
  },
  {
    "Date": "2014-02-28",
    "MQE Strategy": 0.376092679447281,
    "S&P 500": 0.72434343334737,
    "Buy & Hold Gold": 0.130561233068857
  },
  {
    "Date": "2014-03-31",
    "MQE Strategy": 0.424359641668756,
    "S&P 500": 0.73864786687946,
    "Buy & Hold Gold": 0.0980492563298925
  },
  {
    "Date": "2014-04-30",
    "MQE Strategy": 0.393859851440176,
    "S&P 500": 0.75073420375872,
    "Buy & Hold Gold": 0.108487270243484
  },
  {
    "Date": "2014-05-31",
    "MQE Strategy": 0.350838903271128,
    "S&P 500": 0.791362172966893,
    "Buy & Hold Gold": 0.0657083534816894
  },
  {
    "Date": "2014-06-30",
    "MQE Strategy": 0.34230332109254,
    "S&P 500": 0.828344525418386,
    "Buy & Hold Gold": 0.130903485291094
  },
  {
    "Date": "2014-07-31",
    "MQE Strategy": 0.361121550718361,
    "S&P 500": 0.794948433437524,
    "Buy & Hold Gold": 0.0962525627140405
  },
  {
    "Date": "2014-08-31",
    "MQE Strategy": 0.355604182720119,
    "S&P 500": 0.86578378213092,
    "Buy & Hold Gold": 0.100102665222602
  },
  {
    "Date": "2014-09-30",
    "MQE Strategy": 0.422358781066756,
    "S&P 500": 0.840043030014786,
    "Buy & Hold Gold": 0.0356775748030538
  },
  {
    "Date": "2014-10-31",
    "MQE Strategy": 0.514131129938844,
    "S&P 500": 0.914832053164747,
    "Buy & Hold Gold": 0.00196776750661654
  },
  {
    "Date": "2014-11-30",
    "MQE Strategy": 0.517809931323503,
    "S&P 500": 0.969682512944595,
    "Buy & Hold Gold": 0.023785015055132
  },
  {
    "Date": "2014-12-31",
    "MQE Strategy": 0.533608452452699,
    "S&P 500": 0.964497392613375,
    "Buy & Hold Gold": 0.0129192119739219
  },
  {
    "Date": "2015-01-31",
    "MQE Strategy": 0.647063089093315,
    "S&P 500": 0.906290340248185,
    "Buy & Hold Gold": 0.093856901599094
  },
  {
    "Date": "2015-02-28",
    "MQE Strategy": 0.63121044523937,
    "S&P 500": 1.01343294287967,
    "Buy & Hold Gold": 0.0374742684189053
  },
  {
    "Date": "2015-03-31",
    "MQE Strategy": 0.669347297467185,
    "S&P 500": 0.981810424792934,
    "Buy & Hold Gold": 0.0122347075294464
  },
  {
    "Date": "2015-04-30",
    "MQE Strategy": 0.728264799754825,
    "S&P 500": 1.00129934622485,
    "Buy & Hold Gold": 0.0116358444710674
  },
  {
    "Date": "2015-05-31",
    "MQE Strategy": 0.663433778227337,
    "S&P 500": 1.02702842657621,
    "Buy & Hold Gold": 0.017624892817719
  },
  {
    "Date": "2015-06-30",
    "MQE Strategy": 0.680192265076063,
    "S&P 500": 0.985855121489948,
    "Buy & Hold Gold": 0.00231001972885325
  },
  {
    "Date": "2015-07-31",
    "MQE Strategy": 0.770961360959242,
    "S&P 500": 1.03071406618366,
    "Buy & Hold Gold": -0.0632272598620737
  },
  {
    "Date": "2015-08-31",
    "MQE Strategy": 0.682011994670624,
    "S&P 500": 0.906942333973815,
    "Buy & Hold Gold": -0.0318275767352021
  },
  {
    "Date": "2015-09-30",
    "MQE Strategy": 0.727501806886083,
    "S&P 500": 0.858284423820753,
    "Buy & Hold Gold": -0.0456023670443569
  },
  {
    "Date": "2015-10-31",
    "MQE Strategy": 0.87275297948388,
    "S&P 500": 1.01444838269697,
    "Buy & Hold Gold": -0.0233573303282237
  },
  {
    "Date": "2015-11-30",
    "MQE Strategy": 0.982684144855618,
    "S&P 500": 1.02979178842434,
    "Buy & Hold Gold": -0.0881245685292954
  },
  {
    "Date": "2015-12-31",
    "MQE Strategy": 0.959956022333256,
    "S&P 500": 0.994711592281791,
    "Buy & Hold Gold": -0.092830249373093
  },
  {
    "Date": "2016-01-31",
    "MQE Strategy": 0.988139126392558,
    "S&P 500": 0.895402086521896,
    "Buy & Hold Gold": -0.0448323256545021
  },
  {
    "Date": "2016-02-29",
    "MQE Strategy": 1.08143398934366,
    "S&P 500": 0.893836169836253,
    "Buy & Hold Gold": 0.0556981287357159
  },
  {
    "Date": "2016-03-31",
    "MQE Strategy": 1.11665713084511,
    "S&P 500": 1.02122741855303,
    "Buy & Hold Gold": 0.0559547395718585
  },
  {
    "Date": "2016-04-30",
    "MQE Strategy": 1.12420176786476,
    "S&P 500": 1.0291931040952,
    "Buy & Hold Gold": 0.103011548009833
  },
  {
    "Date": "2016-05-31",
    "MQE Strategy": 1.21623499394157,
    "S&P 500": 1.06371305444503,
    "Buy & Hold Gold": 0.0393566034208532
  },
  {
    "Date": "2016-06-30",
    "MQE Strategy": 1.31879791392151,
    "S&P 500": 1.07088588330881,
    "Buy & Hold Gold": 0.127994498063149
  },
  {
    "Date": "2016-07-31",
    "MQE Strategy": 1.35865015962562,
    "S&P 500": 1.14641381302375,
    "Buy & Hold Gold": 0.154175174233225
  },
  {
    "Date": "2016-08-31",
    "MQE Strategy": 1.39930155954656,
    "S&P 500": 1.14898457574102,
    "Buy & Hold Gold": 0.118155347207936
  },
  {
    "Date": "2016-09-30",
    "MQE Strategy": 1.27561581789087,
    "S&P 500": 1.14910896482456,
    "Buy & Hold Gold": 0.123631069441589
  },
  {
    "Date": "2016-10-31",
    "MQE Strategy": 1.20747049861021,
    "S&P 500": 1.10090778579714,
    "Buy & Hold Gold": 0.0878678532524431
  },
  {
    "Date": "2016-11-30",
    "MQE Strategy": 1.39443598011211,
    "S&P 500": 1.18333481140676,
    "Buy & Hold Gold": 0.00171115667047395
  },
  {
    "Date": "2016-12-31",
    "MQE Strategy": 1.44687264308425,
    "S&P 500": 1.22759461019158,
    "Buy & Hold Gold": -0.0160849144787184
  },
  {
    "Date": "2017-01-31",
    "MQE Strategy": 1.57730169742971,
    "S&P 500": 1.26745659092513,
    "Buy & Hold Gold": 0.0340519550779623
  },
  {
    "Date": "2017-02-28",
    "MQE Strategy": 1.59214473191496,
    "S&P 500": 1.3565488013482,
    "Buy & Hold Gold": 0.0716974018283412
  },
  {
    "Date": "2017-03-31",
    "MQE Strategy": 1.69665101613467,
    "S&P 500": 1.35949427843913,
    "Buy & Hold Gold": 0.0671628993160196
  },
  {
    "Date": "2017-04-30",
    "MQE Strategy": 1.78120361586693,
    "S&P 500": 1.3829146540841,
    "Buy & Hold Gold": 0.083247709354026
  },
  {
    "Date": "2017-05-31",
    "MQE Strategy": 1.87843820113828,
    "S&P 500": 1.41654484356818,
    "Buy & Hold Gold": 0.0882956424200612
  },
  {
    "Date": "2017-06-30",
    "MQE Strategy": 1.92001843525568,
    "S&P 500": 1.43194995523835,
    "Buy & Hold Gold": 0.0615159987508915
  },
  {
    "Date": "2017-07-31",
    "MQE Strategy": 2.00457339333106,
    "S&P 500": 1.47773513151318,
    "Buy & Hold Gold": 0.0836754985216446
  },
  {
    "Date": "2017-08-31",
    "MQE Strategy": 2.0233926850829,
    "S&P 500": 1.48496450816135,
    "Buy & Hold Gold": 0.126112163061202
  },
  {
    "Date": "2017-09-30",
    "MQE Strategy": 2.06147468404516,
    "S&P 500": 1.53503528610774,
    "Buy & Hold Gold": 0.0964236366048019
  },
  {
    "Date": "2017-10-31",
    "MQE Strategy": 2.13218359110944,
    "S&P 500": 1.59906201452836,
    "Buy & Hold Gold": 0.0840177507438817
  },
  {
    "Date": "2017-11-30",
    "MQE Strategy": 2.11399399830476,
    "S&P 500": 1.67233293389153,
    "Buy & Hold Gold": 0.089322294646059
  },
  {
    "Date": "2017-12-31",
    "MQE Strategy": 2.1018628568836,
    "S&P 500": 1.70474300087939,
    "Buy & Hold Gold": 0.117642021094939
  },
  {
    "Date": "2018-01-31",
    "MQE Strategy": 2.12954759482074,
    "S&P 500": 1.87624925066337,
    "Buy & Hold Gold": 0.145619390880867
  },
  {
    "Date": "2018-02-28",
    "MQE Strategy": 2.09975375403507,
    "S&P 500": 1.77166737705649,
    "Buy & Hold Gold": 0.125513300002823
  },
  {
    "Date": "2018-03-31",
    "MQE Strategy": 2.09078656941642,
    "S&P 500": 1.69569473099819,
    "Buy & Hold Gold": 0.131759063626331
  },
  {
    "Date": "2018-04-30",
    "MQE Strategy": 2.16325857278093,
    "S&P 500": 1.70962655731737,
    "Buy & Hold Gold": 0.126112163061202
  },
  {
    "Date": "2018-05-31",
    "MQE Strategy": 2.17632678226191,
    "S&P 500": 1.77549503606265,
    "Buy & Hold Gold": 0.112337372752046
  },
  {
    "Date": "2018-06-30",
    "MQE Strategy": 2.15959074737434,
    "S&P 500": 1.7914562535179,
    "Buy & Hold Gold": 0.0705852126569639
  },
  {
    "Date": "2018-07-31",
    "MQE Strategy": 2.16633743971915,
    "S&P 500": 1.90512930058109,
    "Buy & Hold Gold": 0.0469711670518818
  },
  {
    "Date": "2018-08-31",
    "MQE Strategy": 2.20200437722586,
    "S&P 500": 1.997861149666,
    "Buy & Hold Gold": 0.0269507175599342
  },
  {
    "Date": "2018-09-30",
    "MQE Strategy": 2.20010813039166,
    "S&P 500": 2.01568506627918,
    "Buy & Hold Gold": 0.0194215864335725
  },
  {
    "Date": "2018-10-31",
    "MQE Strategy": 2.18606445108885,
    "S&P 500": 1.80728808789361,
    "Buy & Hold Gold": 0.0372176575827654
  },
  {
    "Date": "2018-11-30",
    "MQE Strategy": 2.19315849134266,
    "S&P 500": 1.93339805135101,
    "Buy & Hold Gold": 0.0439766428785573
  },
  {
    "Date": "2018-12-31",
    "MQE Strategy": 2.21335888359104,
    "S&P 500": 1.74771967635607,
    "Buy & Hold Gold": 0.0936858277083348
  },
  {
    "Date": "2019-01-31",
    "MQE Strategy": 2.27108255873789,
    "S&P 500": 1.9677173107065,
    "Buy & Hold Gold": 0.129106687234529
  },
  {
    "Date": "2019-02-28",
    "MQE Strategy": 2.25398007252988,
    "S&P 500": 2.06391785095863,
    "Buy & Hold Gold": 0.123203280273973
  },
  {
    "Date": "2019-03-31",
    "MQE Strategy": 2.27729870210062,
    "S&P 500": 2.11937720211034,
    "Buy & Hold Gold": 0.106262787460016
  },
  {
    "Date": "2019-04-30",
    "MQE Strategy": 2.22408606289068,
    "S&P 500": 2.24681103511545,
    "Buy & Hold Gold": 0.0975359302168959
  },
  {
    "Date": "2019-05-31",
    "MQE Strategy": 2.14044981532152,
    "S&P 500": 2.03975852812518,
    "Buy & Hold Gold": 0.117214231927321
  },
  {
    "Date": "2019-06-30",
    "MQE Strategy": 2.32491663978,
    "S&P 500": 2.25128406499345,
    "Buy & Hold Gold": 0.206108737405759
  },
  {
    "Date": "2019-07-31",
    "MQE Strategy": 2.32258516464032,
    "S&P 500": 2.27426419340929,
    "Buy & Hold Gold": 0.22014024299177
  },
  {
    "Date": "2019-08-31",
    "MQE Strategy": 2.31700667332759,
    "S&P 500": 2.2194416091117,
    "Buy & Hold Gold": 0.299709028168709
  },
  {
    "Date": "2019-09-30",
    "MQE Strategy": 2.20517043602949,
    "S&P 500": 2.28208554276084,
    "Buy & Hold Gold": 0.254021124178969
  },
  {
    "Date": "2019-10-31",
    "MQE Strategy": 2.16723794406131,
    "S&P 500": 2.35837508038979,
    "Buy & Hold Gold": 0.293121116763678
  },
  {
    "Date": "2019-11-30",
    "MQE Strategy": 2.11011014735827,
    "S&P 500": 2.49958017276281,
    "Buy & Hold Gold": 0.243497573319996
  },
  {
    "Date": "2019-12-31",
    "MQE Strategy": 2.12037792598937,
    "S&P 500": 2.60114997829504,
    "Buy & Hold Gold": 0.300051280390946
  },
  {
    "Date": "2020-01-31",
    "MQE Strategy": 2.26658016685415,
    "S&P 500": 2.5996958475907,
    "Buy & Hold Gold": 0.354294967733045
  },
  {
    "Date": "2020-02-29",
    "MQE Strategy": 2.15949132473208,
    "S&P 500": 2.31472287070223,
    "Buy & Hold Gold": 0.338210053254323
  },
  {
    "Date": "2020-03-31",
    "MQE Strategy": 2.68236378911974,
    "S&P 500": 1.90080946864793,
    "Buy & Hold Gold": 0.354722756900661
  },
  {
    "Date": "2020-04-30",
    "MQE Strategy": 3.00097174120443,
    "S&P 500": 2.26916464515774,
    "Buy & Hold Gold": 0.44096499042801
  },
  {
    "Date": "2020-05-31",
    "MQE Strategy": 3.00097174120443,
    "S&P 500": 2.42492483090612,
    "Buy & Hold Gold": 0.486054031359372
  },
  {
    "Date": "2020-06-30",
    "MQE Strategy": 3.18709048475559,
    "S&P 500": 2.48566366453803,
    "Buy & Hold Gold": 0.534051955077963
  },
  {
    "Date": "2020-07-31",
    "MQE Strategy": 3.58361483989183,
    "S&P 500": 2.69094211308381,
    "Buy & Hold Gold": 0.679329198177304
  },
  {
    "Date": "2020-08-31",
    "MQE Strategy": 3.51273278322387,
    "S&P 500": 2.94855775119197,
    "Buy & Hold Gold": 0.683435911522008
  },
  {
    "Date": "2020-09-30",
    "MQE Strategy": 3.3163692582013,
    "S&P 500": 2.80070961379,
    "Buy & Hold Gold": 0.614904107757754
  },
  {
    "Date": "2020-10-31",
    "MQE Strategy": 3.3163692582013,
    "S&P 500": 2.64726808416062,
    "Buy & Hold Gold": 0.606262787460014
  },
  {
    "Date": "2020-11-30",
    "MQE Strategy": 3.4026070111801,
    "S&P 500": 3.00303859417004,
    "Buy & Hold Gold": 0.519250408102096
  },
  {
    "Date": "2020-12-31",
    "MQE Strategy": 3.69368443687748,
    "S&P 500": 3.13525662343056,
    "Buy & Hold Gold": 0.619695325546932
  },
  {
    "Date": "2021-01-31",
    "MQE Strategy": 3.63095589560818,
    "S&P 500": 3.09311673226361,
    "Buy & Hold Gold": 0.580509900457557
  },
  {
    "Date": "2021-02-28",
    "MQE Strategy": 3.54210686063931,
    "S&P 500": 3.20692776075394,
    "Buy & Hold Gold": 0.47852490023301
  },
  {
    "Date": "2021-03-31",
    "MQE Strategy": 3.49081476043362,
    "S&P 500": 3.39791928777079,
    "Buy & Hold Gold": 0.466290192703566
  },
  {
    "Date": "2021-04-30",
    "MQE Strategy": 3.51334081984709,
    "S&P 500": 3.63061521277694,
    "Buy & Hold Gold": 0.512063633638686
  },
  {
    "Date": "2021-05-31",
    "MQE Strategy": 3.62405930713725,
    "S&P 500": 3.66102015442197,
    "Buy & Hold Gold": 0.627737782786293
  },
  {
    "Date": "2021-06-30",
    "MQE Strategy": 3.78830367190316,
    "S&P 500": 3.73392462467927,
    "Buy & Hold Gold": 0.520020449491951
  }
]

export default data;