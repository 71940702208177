import React from 'react';
import Slider from "react-slick";
import HeroCard from './HeroCard';
import { useCollection } from 'react-firebase-hooks/firestore';
import {db} from '../firebaseConfig'


function TopSlider(props) {

  const [value] = useCollection(
    db.collection('assets'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  return (
    <div>
      {/*}<p>
        {loading && (<GridLoader
          css={override}
          size={15}
          color={"#005687"}
        />)}
      </p>*/}
        {value && (
          <span>
            <h1 className="center text-center mt-4 mb-2 color-ucla">Current Directional Forecasts</h1>
            <p className="center text-center mb-2 color-primary">Real-time Forecasts created in the MQE Lab based on Economic Fundamentals.</p>
            <br />
            <Slider {...settings}>
              {value.docs.map(doc => (
                doc.data().isVisible &&
                <div key={doc.id}>
                  <HeroCard title={doc.data().name} image={doc.data().coverImage} id={doc.id} forecastTerm={props.forecastTerm} />
                </div>
              ))}
            </Slider>
          </span>
        )}
    </div>

  );
}

export default TopSlider;
