const data = [
  {
    "Date": "2007-03-01",
    "MQE Strategy": 0,
    "HFRI 500": 1.13,
    "S&P 500": 0.76
  },
  {
    "Date": "2007-04-01",
    "MQE Strategy": 0,
    "HFRI 500": 2.94,
    "S&P 500": 5.22
  },
  {
    "Date": "2007-05-01",
    "MQE Strategy": -1.82,
    "HFRI 500": 5.27,
    "S&P 500": 8.79
  },
  {
    "Date": "2007-06-01",
    "MQE Strategy": -2.02,
    "HFRI 500": 6.52,
    "S&P 500": 6.74
  },
  {
    "Date": "2007-07-01",
    "MQE Strategy": -3.33,
    "HFRI 500": 7.15,
    "S&P 500": 3.4
  },
  {
    "Date": "2007-08-01",
    "MQE Strategy": -7.57,
    "HFRI 500": 5.05,
    "S&P 500": 4.73
  },
  {
    "Date": "2007-09-01",
    "MQE Strategy": -8.69,
    "HFRI 500": 7.66,
    "S&P 500": 8.27
  },
  {
    "Date": "2007-10-01",
    "MQE Strategy": -2.59,
    "HFRI 500": 10.75,
    "S&P 500": 9.74
  },
  {
    "Date": "2007-11-01",
    "MQE Strategy": -0.33,
    "HFRI 500": 8.31,
    "S&P 500": 5.49
  },
  {
    "Date": "2007-12-01",
    "MQE Strategy": -4.03,
    "HFRI 500": 8.75,
    "S&P 500": 3.75
  },
  {
    "Date": "2008-01-01",
    "MQE Strategy": -4.42,
    "HFRI 500": 5.55,
    "S&P 500": -2.53
  },
  {
    "Date": "2008-02-01",
    "MQE Strategy": -1.43,
    "HFRI 500": 7.34,
    "S&P 500": -5.05
  },
  {
    "Date": "2008-03-01",
    "MQE Strategy": 2.38,
    "HFRI 500": 4.77,
    "S&P 500": -6.36
  },
  {
    "Date": "2008-04-01",
    "MQE Strategy": 0.49,
    "HFRI 500": 6.43,
    "S&P 500": -1.89
  },
  {
    "Date": "2008-05-01",
    "MQE Strategy": -1.93,
    "HFRI 500": 8.33,
    "S&P 500": -0.41
  },
  {
    "Date": "2008-06-01",
    "MQE Strategy": -1.29,
    "HFRI 500": 6.91,
    "S&P 500": -9.19
  },
  {
    "Date": "2008-07-01",
    "MQE Strategy": -4.18,
    "HFRI 500": 4.21,
    "S&P 500": -10
  },
  {
    "Date": "2008-08-01",
    "MQE Strategy": -0.51,
    "HFRI 500": 2.37,
    "S&P 500": -8.61
  },
  {
    "Date": "2008-09-01",
    "MQE Strategy": 8.73,
    "HFRI 500": -3.83,
    "S&P 500": -17.7
  },
  {
    "Date": "2008-10-01",
    "MQE Strategy": 11.13,
    "HFRI 500": -10.45,
    "S&P 500": -31.29
  },
  {
    "Date": "2008-11-01",
    "MQE Strategy": 21.48,
    "HFRI 500": -11.92,
    "S&P 500": -36.07
  },
  {
    "Date": "2008-12-01",
    "MQE Strategy": 21.86,
    "HFRI 500": -11.79,
    "S&P 500": -35.97
  },
  {
    "Date": "2009-01-01",
    "MQE Strategy": 26.03,
    "HFRI 500": -11.63,
    "S&P 500": -41.23
  },
  {
    "Date": "2009-02-01",
    "MQE Strategy": 31.42,
    "HFRI 500": -12.19,
    "S&P 500": -47.54
  },
  {
    "Date": "2009-03-01",
    "MQE Strategy": 26.98,
    "HFRI 500": -10.93,
    "S&P 500": -43.57
  },
  {
    "Date": "2009-04-01",
    "MQE Strategy": 29.62,
    "HFRI 500": -8.55,
    "S&P 500": -37.97
  },
  {
    "Date": "2009-05-01",
    "MQE Strategy": 35.59,
    "HFRI 500": -4.11,
    "S&P 500": -34.34
  },
  {
    "Date": "2009-06-01",
    "MQE Strategy": 37.83,
    "HFRI 500": -3.9,
    "S&P 500": -34.75
  },
  {
    "Date": "2009-07-01",
    "MQE Strategy": 32,
    "HFRI 500": -1.38,
    "S&P 500": -29.89
  },
  {
    "Date": "2009-08-01",
    "MQE Strategy": 28.91,
    "HFRI 500": 0.01,
    "S&P 500": -27.3
  },
  {
    "Date": "2009-09-01",
    "MQE Strategy": 32.69,
    "HFRI 500": 2.77,
    "S&P 500": -25.08
  },
  {
    "Date": "2009-10-01",
    "MQE Strategy": 37.21,
    "HFRI 500": 2.61,
    "S&P 500": -26.52
  },
  {
    "Date": "2009-11-01",
    "MQE Strategy": 44.17,
    "HFRI 500": 3.88,
    "S&P 500": -21.99
  },
  {
    "Date": "2009-12-01",
    "MQE Strategy": 44.85,
    "HFRI 500": 4.85,
    "S&P 500": -20.93
  },
  {
    "Date": "2010-01-01",
    "MQE Strategy": 49.82,
    "HFRI 500": 4.21,
    "S&P 500": -23.8
  },
  {
    "Date": "2010-02-01",
    "MQE Strategy": 47.48,
    "HFRI 500": 4.49,
    "S&P 500": -21.42
  },
  {
    "Date": "2010-03-01",
    "MQE Strategy": 45.17,
    "HFRI 500": 7.06,
    "S&P 500": -16.98
  },
  {
    "Date": "2010-04-01",
    "MQE Strategy": 45.82,
    "HFRI 500": 8.09,
    "S&P 500": -15.7
  },
  {
    "Date": "2010-05-01",
    "MQE Strategy": 43.17,
    "HFRI 500": 5.05,
    "S&P 500": -22.39
  },
  {
    "Date": "2010-06-01",
    "MQE Strategy": 52.73,
    "HFRI 500": 4.29,
    "S&P 500": -26.76
  },
  {
    "Date": "2010-07-01",
    "MQE Strategy": 49.3,
    "HFRI 500": 5.84,
    "S&P 500": -21.76
  },
  {
    "Date": "2010-08-01",
    "MQE Strategy": 46.61,
    "HFRI 500": 5.97,
    "S&P 500": -25.27
  },
  {
    "Date": "2010-09-01",
    "MQE Strategy": 46.21,
    "HFRI 500": 9.4,
    "S&P 500": -19.02
  },
  {
    "Date": "2010-10-01",
    "MQE Strategy": 51.76,
    "HFRI 500": 11.5,
    "S&P 500": -15.92
  },
  {
    "Date": "2010-11-01",
    "MQE Strategy": 55.21,
    "HFRI 500": 11.23,
    "S&P 500": -15.92
  },
  {
    "Date": "2010-12-01",
    "MQE Strategy": 57.1,
    "HFRI 500": 14.24,
    "S&P 500": -10.77
  },
  {
    "Date": "2011-01-01",
    "MQE Strategy": 53.82,
    "HFRI 500": 14.71,
    "S&P 500": -8.69
  },
  {
    "Date": "2011-02-01",
    "MQE Strategy": 53.36,
    "HFRI 500": 15.7,
    "S&P 500": -5.52
  },
  {
    "Date": "2011-03-01",
    "MQE Strategy": 57.2,
    "HFRI 500": 15.77,
    "S&P 500": -5.92
  },
  {
    "Date": "2011-04-01",
    "MQE Strategy": 58.34,
    "HFRI 500": 17.65,
    "S&P 500": -3.19
  },
  {
    "Date": "2011-05-01",
    "MQE Strategy": 53.25,
    "HFRI 500": 15.97,
    "S&P 500": -4.28
  },
  {
    "Date": "2011-06-01",
    "MQE Strategy": 56.16,
    "HFRI 500": 14.65,
    "S&P 500": -6.36
  },
  {
    "Date": "2011-07-01",
    "MQE Strategy": 52,
    "HFRI 500": 15.16,
    "S&P 500": -8.23
  },
  {
    "Date": "2011-08-01",
    "MQE Strategy": 47.36,
    "HFRI 500": 11.9,
    "S&P 500": -13.28
  },
  {
    "Date": "2011-09-01",
    "MQE Strategy": 49.55,
    "HFRI 500": 8.33,
    "S&P 500": -19.71
  },
  {
    "Date": "2011-10-01",
    "MQE Strategy": 52.66,
    "HFRI 500": 10.73,
    "S&P 500": -10.95
  },
  {
    "Date": "2011-11-01",
    "MQE Strategy": 45.76,
    "HFRI 500": 9.45,
    "S&P 500": -11.31
  },
  {
    "Date": "2011-12-01",
    "MQE Strategy": 37.4,
    "HFRI 500": 9.05,
    "S&P 500": -10.95
  },
  {
    "Date": "2012-01-01",
    "MQE Strategy": 41,
    "HFRI 500": 11.58,
    "S&P 500": -6.82
  },
  {
    "Date": "2012-02-01",
    "MQE Strategy": 42.72,
    "HFRI 500": 13.71,
    "S&P 500": -2.77
  },
  {
    "Date": "2012-03-01",
    "MQE Strategy": 45.78,
    "HFRI 500": 13.45,
    "S&P 500": -0.09
  },
  {
    "Date": "2012-04-01",
    "MQE Strategy": 42.67,
    "HFRI 500": 13.15,
    "S&P 500": -0.75
  },
  {
    "Date": "2012-05-01",
    "MQE Strategy": 43.53,
    "HFRI 500": 10.78,
    "S&P 500": -6.71
  },
  {
    "Date": "2012-06-01",
    "MQE Strategy": 53.71,
    "HFRI 500": 10.72,
    "S&P 500": -3.43
  },
  {
    "Date": "2012-07-01",
    "MQE Strategy": 54,
    "HFRI 500": 11.89,
    "S&P 500": -2.28
  },
  {
    "Date": "2012-08-01",
    "MQE Strategy": 53.53,
    "HFRI 500": 12.59,
    "S&P 500": 0.16
  },
  {
    "Date": "2012-09-01",
    "MQE Strategy": 51.72,
    "HFRI 500": 13.8,
    "S&P 500": 2.16
  },
  {
    "Date": "2012-10-01",
    "MQE Strategy": 52.94,
    "HFRI 500": 13.46,
    "S&P 500": 0.3
  },
  {
    "Date": "2012-11-01",
    "MQE Strategy": 51.38,
    "HFRI 500": 14.06,
    "S&P 500": 0.87
  },
  {
    "Date": "2012-12-01",
    "MQE Strategy": 52.1,
    "HFRI 500": 15.81,
    "S&P 500": 1.05
  },
  {
    "Date": "2013-01-01",
    "MQE Strategy": 55.29,
    "HFRI 500": 18.94,
    "S&P 500": 6.22
  },
  {
    "Date": "2013-02-01",
    "MQE Strategy": 55.77,
    "HFRI 500": 19.31,
    "S&P 500": 7.58
  },
  {
    "Date": "2013-03-01",
    "MQE Strategy": 52.36,
    "HFRI 500": 20.41,
    "S&P 500": 11.17
  },
  {
    "Date": "2013-04-01",
    "MQE Strategy": 55.1,
    "HFRI 500": 21.58,
    "S&P 500": 13.3
  },
  {
    "Date": "2013-05-01",
    "MQE Strategy": 55.11,
    "HFRI 500": 22.32,
    "S&P 500": 15.98
  },
  {
    "Date": "2013-06-01",
    "MQE Strategy": 67.01,
    "HFRI 500": 20.33,
    "S&P 500": 13.83
  },
  {
    "Date": "2013-07-01",
    "MQE Strategy": 75.7,
    "HFRI 500": 21.89,
    "S&P 500": 19.71
  },
  {
    "Date": "2013-08-01",
    "MQE Strategy": 80.12,
    "HFRI 500": 21.14,
    "S&P 500": 16.12
  },
  {
    "Date": "2013-09-01",
    "MQE Strategy": 78.52,
    "HFRI 500": 22.94,
    "S&P 500": 19.22
  },
  {
    "Date": "2013-10-01",
    "MQE Strategy": 72.55,
    "HFRI 500": 25.01,
    "S&P 500": 24.74
  },
  {
    "Date": "2013-11-01",
    "MQE Strategy": 75.48,
    "HFRI 500": 26.47,
    "S&P 500": 28.43
  },
  {
    "Date": "2013-12-01",
    "MQE Strategy": 75.48,
    "HFRI 500": 27.88,
    "S&P 500": 31.05
  },
  {
    "Date": "2014-01-01",
    "MQE Strategy": 78.58,
    "HFRI 500": 27.2,
    "S&P 500": 26.43
  },
  {
    "Date": "2014-02-01",
    "MQE Strategy": 78.79,
    "HFRI 500": 29.35,
    "S&P 500": 32.19
  },
  {
    "Date": "2014-03-01",
    "MQE Strategy": 80.47,
    "HFRI 500": 28.62,
    "S&P 500": 32.7
  },
  {
    "Date": "2014-04-01",
    "MQE Strategy": 78.24,
    "HFRI 500": 27.98,
    "S&P 500": 33.62
  },
  {
    "Date": "2014-05-01",
    "MQE Strategy": 80.83,
    "HFRI 500": 29.55,
    "S&P 500": 36.72
  },
  {
    "Date": "2014-06-01",
    "MQE Strategy": 78.73,
    "HFRI 500": 31.01,
    "S&P 500": 38.88
  },
  {
    "Date": "2014-07-01",
    "MQE Strategy": 76.6,
    "HFRI 500": 30.47,
    "S&P 500": 37.01
  },
  {
    "Date": "2014-08-01",
    "MQE Strategy": 78.47,
    "HFRI 500": 32.14,
    "S&P 500": 42.42
  },
  {
    "Date": "2014-09-01",
    "MQE Strategy": 78.71,
    "HFRI 500": 31.94,
    "S&P 500": 39.8
  },
  {
    "Date": "2014-10-01",
    "MQE Strategy": 85.62,
    "HFRI 500": 31.6,
    "S&P 500": 43.09
  },
  {
    "Date": "2014-11-01",
    "MQE Strategy": 82.73,
    "HFRI 500": 33.29,
    "S&P 500": 47.02
  },
  {
    "Date": "2014-12-01",
    "MQE Strategy": 95.03,
    "HFRI 500": 33.4,
    "S&P 500": 45.85
  },
  {
    "Date": "2015-01-01",
    "MQE Strategy": 101.28,
    "HFRI 500": 34.08,
    "S&P 500": 41.52
  },
  {
    "Date": "2015-02-01",
    "MQE Strategy": 95.64,
    "HFRI 500": 36.41,
    "S&P 500": 49.48
  },
  {
    "Date": "2015-03-01",
    "MQE Strategy": 97.37,
    "HFRI 500": 37.25,
    "S&P 500": 46.48
  },
  {
    "Date": "2015-04-01",
    "MQE Strategy": 99.26,
    "HFRI 500": 38.48,
    "S&P 500": 47.92
  },
  {
    "Date": "2015-05-01",
    "MQE Strategy": 96.71,
    "HFRI 500": 39.67,
    "S&P 500": 49.82
  },
  {
    "Date": "2015-06-01",
    "MQE Strategy": 103.07,
    "HFRI 500": 37.4,
    "S&P 500": 46.07
  },
  {
    "Date": "2015-07-01",
    "MQE Strategy": 99.61,
    "HFRI 500": 37.6,
    "S&P 500": 49.36
  },
  {
    "Date": "2015-08-01",
    "MQE Strategy": 100.22,
    "HFRI 500": 34.45,
    "S&P 500": 40.26
  },
  {
    "Date": "2015-09-01",
    "MQE Strategy": 97.98,
    "HFRI 500": 32.77,
    "S&P 500": 35.98
  },
  {
    "Date": "2015-10-01",
    "MQE Strategy": 99.36,
    "HFRI 500": 34.66,
    "S&P 500": 47.54
  },
  {
    "Date": "2015-11-01",
    "MQE Strategy": 115.38,
    "HFRI 500": 35.05,
    "S&P 500": 48.08
  },
  {
    "Date": "2015-12-01",
    "MQE Strategy": 114.44,
    "HFRI 500": 34.13,
    "S&P 500": 44.66
  },
  {
    "Date": "2016-01-01",
    "MQE Strategy": 110.65,
    "HFRI 500": 30.95,
    "S&P 500": 37.46
  },
  {
    "Date": "2016-02-01",
    "MQE Strategy": 115.34,
    "HFRI 500": 30.27,
    "S&P 500": 37.34
  },
  {
    "Date": "2016-03-01",
    "MQE Strategy": 111.14,
    "HFRI 500": 32.18,
    "S&P 500": 45.83
  },
  {
    "Date": "2016-04-01",
    "MQE Strategy": 126.25,
    "HFRI 500": 32.79,
    "S&P 500": 46.41
  },
  {
    "Date": "2016-05-01",
    "MQE Strategy": 126.12,
    "HFRI 500": 33.54,
    "S&P 500": 48.9
  },
  {
    "Date": "2016-06-01",
    "MQE Strategy": 134.86,
    "HFRI 500": 33.45,
    "S&P 500": 48.64
  },
  {
    "Date": "2016-07-01",
    "MQE Strategy": 138.61,
    "HFRI 500": 35.58,
    "S&P 500": 54.06
  },
  {
    "Date": "2016-08-01",
    "MQE Strategy": 141.64,
    "HFRI 500": 36,
    "S&P 500": 54.25
  },
  {
    "Date": "2016-09-01",
    "MQE Strategy": 147.23,
    "HFRI 500": 36.72,
    "S&P 500": 53.48
  },
  {
    "Date": "2016-10-01",
    "MQE Strategy": 149.73,
    "HFRI 500": 35.99,
    "S&P 500": 50.82
  },
  {
    "Date": "2016-11-01",
    "MQE Strategy": 151.9,
    "HFRI 500": 36.56,
    "S&P 500": 56.38
  },
  {
    "Date": "2016-12-01",
    "MQE Strategy": 164.4,
    "HFRI 500": 37.74,
    "S&P 500": 58.61
  },
  {
    "Date": "2017-01-01",
    "MQE Strategy": 172.9,
    "HFRI 500": 39.24,
    "S&P 500": 61.45
  },
  {
    "Date": "2017-02-01",
    "MQE Strategy": 175.28,
    "HFRI 500": 40.72,
    "S&P 500": 67.79
  },
  {
    "Date": "2017-03-01",
    "MQE Strategy": 175.82,
    "HFRI 500": 41.32,
    "S&P 500": 67.27
  },
  {
    "Date": "2017-04-01",
    "MQE Strategy": 184.26,
    "HFRI 500": 42.24,
    "S&P 500": 68.93
  },
  {
    "Date": "2017-05-01",
    "MQE Strategy": 191.75,
    "HFRI 500": 42.94,
    "S&P 500": 71.32
  },
  {
    "Date": "2017-06-01",
    "MQE Strategy": 197.1,
    "HFRI 500": 43.22,
    "S&P 500": 71.57
  },
  {
    "Date": "2017-07-01",
    "MQE Strategy": 206.79,
    "HFRI 500": 44.86,
    "S&P 500": 75.1
  },
  {
    "Date": "2017-08-01",
    "MQE Strategy": 221.54,
    "HFRI 500": 46.01,
    "S&P 500": 75.61
  },
  {
    "Date": "2017-09-01",
    "MQE Strategy": 218.02,
    "HFRI 500": 46.74,
    "S&P 500": 78.27
  },
  {
    "Date": "2017-10-01",
    "MQE Strategy": 222.34,
    "HFRI 500": 48.92,
    "S&P 500": 82.47
  },
  {
    "Date": "2017-11-01",
    "MQE Strategy": 230.5,
    "HFRI 500": 49.33,
    "S&P 500": 88.04
  },
  {
    "Date": "2017-12-01",
    "MQE Strategy": 234.62,
    "HFRI 500": 50.56,
    "S&P 500": 89.36
  },
  {
    "Date": "2018-01-01",
    "MQE Strategy": 238.98,
    "HFRI 500": 54.59,
    "S&P 500": 100.03
  },
  {
    "Date": "2018-02-01",
    "MQE Strategy": 242.04,
    "HFRI 500": 51.93,
    "S&P 500": 92.76
  },
  {
    "Date": "2018-03-01",
    "MQE Strategy": 251.94,
    "HFRI 500": 51.3,
    "S&P 500": 86.72
  },
  {
    "Date": "2018-04-01",
    "MQE Strategy": 246.04,
    "HFRI 500": 51.64,
    "S&P 500": 87.69
  },
  {
    "Date": "2018-05-01",
    "MQE Strategy": 255.11,
    "HFRI 500": 52.28,
    "S&P 500": 92.25
  },
  {
    "Date": "2018-06-01",
    "MQE Strategy": 251.2,
    "HFRI 500": 51.66,
    "S&P 500": 92.49
  },
  {
    "Date": "2018-07-01",
    "MQE Strategy": 262.09,
    "HFRI 500": 52.11,
    "S&P 500": 99.62
  },
  {
    "Date": "2018-08-01",
    "MQE Strategy": 261.14,
    "HFRI 500": 52.03,
    "S&P 500": 106
  },
  {
    "Date": "2018-09-01",
    "MQE Strategy": 267.24,
    "HFRI 500": 51.9,
    "S&P 500": 106.29
  },
  {
    "Date": "2018-10-01",
    "MQE Strategy": 265.5,
    "HFRI 500": 47.87,
    "S&P 500": 92.03
  },
  {
    "Date": "2018-11-01",
    "MQE Strategy": 271.99,
    "HFRI 500": 47.07,
    "S&P 500": 95.59
  },
  {
    "Date": "2018-12-01",
    "MQE Strategy": 267.56,
    "HFRI 500": 45.06,
    "S&P 500": 77.34
  },
  {
    "Date": "2019-01-01",
    "MQE Strategy": 262.27,
    "HFRI 500": 49.24,
    "S&P 500": 91.53
  },
  {
    "Date": "2019-02-01",
    "MQE Strategy": 265.97,
    "HFRI 500": 50.64,
    "S&P 500": 97.74
  },
  {
    "Date": "2019-03-01",
    "MQE Strategy": 272.91,
    "HFRI 500": 51.85,
    "S&P 500": 100.44
  },
  {
    "Date": "2019-04-01",
    "MQE Strategy": 275.3,
    "HFRI 500": 53.76,
    "S&P 500": 108.63
  },
  {
    "Date": "2019-05-01",
    "MQE Strategy": 273.79,
    "HFRI 500": 52.04,
    "S&P 500": 95.32
  },
  {
    "Date": "2019-06-01",
    "MQE Strategy": 278.72,
    "HFRI 500": 54.86,
    "S&P 500": 107.9
  },
  {
    "Date": "2019-07-01",
    "MQE Strategy": 284.68,
    "HFRI 500": 55.81,
    "S&P 500": 111.05
  },
  {
    "Date": "2019-08-01",
    "MQE Strategy": 299.42,
    "HFRI 500": 54.86,
    "S&P 500": 107.51
  },
  {
    "Date": "2019-09-01",
    "MQE Strategy": 294.14,
    "HFRI 500": 54.64,
    "S&P 500": 110.58
  },
  {
    "Date": "2019-10-01",
    "MQE Strategy": 301.11,
    "HFRI 500": 55.43,
    "S&P 500": 115.23
  },
  {
    "Date": "2019-11-01",
    "MQE Strategy": 304.4,
    "HFRI 500": 56.72,
    "S&P 500": 123.03
  },
  {
    "Date": "2019-12-01",
    "MQE Strategy": 304.89,
    "HFRI 500": 59.46,
    "S&P 500": 128.38
  },
  {
    "Date": "2020-01-01",
    "MQE Strategy": 310.99,
    "HFRI 500": 59.1,
    "S&P 500": 128.29
  },
  {
    "Date": "2020-02-01",
    "MQE Strategy": 307.31,
    "HFRI 500": 56.07,
    "S&P 500": 110.22
  },
  {
    "Date": "2020-03-01",
    "MQE Strategy": 294.68,
    "HFRI 500": 43.67,
    "S&P 500": 82.89
  },
  {
    "Date": "2020-04-01",
    "MQE Strategy": 275.74,
    "HFRI 500": 49.61,
    "S&P 500": 106.12
  },
  {
    "Date": "2020-05-01",
    "MQE Strategy": 303.52,
    "HFRI 500": 52.7,
    "S&P 500": 115.94
  },
  {
    "Date": "2020-06-01",
    "MQE Strategy": 296.14,
    "HFRI 500": 55.25,
    "S&P 500": 118.8
  },
  {
    "Date": "2020-07-01",
    "MQE Strategy": 300.74,
    "HFRI 500": 59.31,
    "S&P 500": 131.69
  },
  {
    "Date": "2020-08-01",
    "MQE Strategy": 314.41,
    "HFRI 500": 62.39,
    "S&P 500": 147.86
  },
  {
    "Date": "2020-09-01",
    "MQE Strategy": 324.85,
    "HFRI 500": 60.81,
    "S&P 500": 137.63
  },
  {
    "Date": "2020-10-01",
    "MQE Strategy": 325.17,
    "HFRI 500": 60.96,
    "S&P 500": 131.7
  },
  {
    "Date": "2020-11-01",
    "MQE Strategy": 334.53,
    "HFRI 500": 69.47,
    "S&P 500": 156.91
  },
  {
    "Date": "2020-12-01",
    "MQE Strategy": 309.74,
    "HFRI 500": 76.4,
    "S&P 500": 165.29
  },
  {
    "Date": "2021-01-01",
    "MQE Strategy": 302.28,
    "HFRI 500": 77.92,
    "S&P 500": 162.59
  },
  {
    "Date": "2021-02-01",
    "MQE Strategy": 301.78,
    "HFRI 500": 83.79,
    "S&P 500": 169.89
  },
  {
    "Date": "2021-03-01",
    "MQE Strategy": 316.91,
    "HFRI 500": 84.97,
    "S&P 500": 181.22
  }
]

export default data;