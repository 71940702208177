const data = {
  returns: [{
    "Sharpe": 0.24,
    "Annualized Return": "3.21%",
    "Forecast Success Ratio": 0.5,
    "Avg. Annual Return": 3.21,
    "Market Beta": 0.16,
    "Monthly Alpha": "0.16%",
    "Correlation with S&P": 0.2,
    "Max Drawdown": "-13.15%",
    "% of Winning Mo.": "51.67%",
    "Gini": 0.425,
    "Forecast Accuracy": "0.5",
    "Up Alpha": "-1.09%",
    "Down Alpha": "-0.10%",
    "Up Beta": 0.51,
    "Down Beta": -0.05,
    "Highest Monthly Return": 20.86,
    "Lowest Monthly Return": -9.22,
    "Annualized STD of Returns": "10.80%"
  }],
  hedge : [{
    "Sharpe": 0.82,
    "Annualized Return": "4.789%",
    "Avg. Annual Return": 4.464,
    "Market Beta": 0.34,
    "Monthly Alpha": "0.06%",
    "Correlation with S&P": 0.84,
    "Max Drawdown": "-10.76%",
    "% of Winning Mo.": "67.5%",
    "Gini": 0.418,
    "Forecast Accuracy": "",
    "Up Alpha": "0.14%",
    "Down Alpha": "0.28%",
    "Up Beta": 0.31,
    "Down Beta": 0.4,
    "Highest Monthly Return": 5.28,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "5.28%"
  }]
}

export default data;