const data = {
  returns: [{
    "Sharpe": 1.32,
    "Annualized Return": "16.1%",
    "Forecast Success Ratio": "58.2%",
    "Avg. Annual Return": 16.1,
    "Market Beta": 0.23,
    "Monthly Alpha": "1.02%",
    "Correlation with S&P": 0.29,
    "Max Drawdown": "-6.78%",
    "% of Winning Mo.": "59.7%",
    "Gini": 0.446,
    "Forecast Accuracy": "58.2%",
    "Up Alpha": "0.39%",
    "Down Alpha": "0.5%",
    "Up Beta": 0.41,
    "Down Beta": 0.04,
    "Highest Monthly Return": 17.28,
    "Lowest Monthly Return": -4.11,
    "Annualized STD of Returns": "10.95%"
  }],
  hedge : [{
    "Sharpe": 0.78,
    "Annualized Return": "5.272%",
    "Avg. Annual Return": 5.272,
    "Market Beta": 0.34,
    "Monthly Alpha": "0.1%",
    "Correlation with S&P": 0.86,
    "Max Drawdown": "-9.91%",
    "% of Winning Mo.": "66.42%",
    "Gini": 0.416,
    "Forecast Accuracy": "",
    "Up Alpha": "0.21%",
    "Down Alpha": "0.27%",
    "Up Beta": 0.3,
    "Down Beta": 0.39,
    "Highest Monthly Return": 5.29,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "5.47%"
  }]
}

export default data;