const data = {
  returns: [{
    "Sharpe": 0.63,
    "Annualized Return": "8.514%",
    "Forecast Success Ratio": "50.9%",
    "Avg. Annual Return": 8.51,
    "Market Beta": 0.07,
    "Monthly Alpha": "0.62%",
    "Correlation with S&P": 0.09,
    "Max Drawdown": "-30.32%",
    "% of Winning Mo.": "56.73%",
    "Gini": 0.45,
    "Forecast Accuracy": "",
    "Up Alpha": "-0.25%",
    "Down Alpha": "-0.03%",
    "Up Beta": 0.32,
    "Down Beta": -0.13,
    "Highest Monthly Return": 25.48,
    "Lowest Monthly Return": -6.51,
    "Annualized STD of Returns": "10.5%"
  }],
  hedge : [{
    "Sharpe": 0.44,
    "Annualized Return": "4.55%",
    "Avg. Annual Return": 4.55,
    "Market Beta": 0.33,
    "Monthly Alpha": "0.11%",
    "Correlation with S&P": 0.82,
    "Max Drawdown": "-20.72%",
    "% of Winning Mo.": "66.08%",
    "Gini": 0.426,
    "Forecast Accuracy": "",
    "Up Alpha": "0.19%",
    "Down Alpha": "0.22%",
    "Up Beta": 0.3,
    "Down Beta": 0.36,
    "Highest Monthly Return": 5.29,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "6.18%"
  }]
}

export default data;