const data = {
  returns: [{
    "Sharpe": 0.22,
    "Annualized Return": "5.30%",
    "Forecast Success Ratio": 0.5,
    "Avg. Annual Return": 5.30,
    "Market Beta": 0.021,
    "Monthly Alpha": "0.35%",
    "Correlation with S&P": 0.033,
    "Max Drawdown": "-29.41%",
    "% of Winning Mo.": "49.10%",
    "Gini": 0.414,
    "Forecast Accuracy": "0.5",
    "Up Alpha": "1.47%",
    "Down Alpha": "-0.26%",
    "Up Beta": -0.23,
    "Down Beta":-0.02,
    "Highest Monthly Return": 20.86,
    "Lowest Monthly Return": -29.41,
    "Annualized STD of Returns": "14.19%"
  }],
  hedge : [{
    "Sharpe": 0.26,
    "Annualized Return": "5.11%",
    "Avg. Annual Return": 5.11,
    "Market Beta": 0.075,
    "Monthly Alpha": "0.30%",
    "Correlation with S&P": 0.18,
    "Max Drawdown": "-20.35%",
    "% of Winning Mo.": "65.77%",
    "Gini": 0.418,
    "Forecast Accuracy": "",
    "Up Alpha": "0.23%",
    "Down Alpha": "0.35%",
    "Up Beta": 0.09,
    "Down Beta": 0.08,
    "Highest Monthly Return": 5.28,
    "Lowest Monthly Return": -7.95,
    "Annualized STD of Returns": "5.87%"
  }]
}

export default data;