const data = [
  {
    "Date": "2011-12-31",
    "MQE Strategy": 2.03,
    "HFRI 500": -0.37,
    "HFRI Currency Index": 0.38
  },
  {
    "Date": "2012-01-31",
    "MQE Strategy": 8.06,
    "HFRI 500": 1.94,
    "HFRI Currency Index": 1.74
  },
  {
    "Date": "2012-02-29",
    "MQE Strategy": 8.06,
    "HFRI 500": 3.89,
    "HFRI Currency Index": 1.38
  },
  {
    "Date": "2012-03-31",
    "MQE Strategy": 7.99,
    "HFRI 500": 3.65,
    "HFRI Currency Index": -1.02
  },
  {
    "Date": "2012-04-30",
    "MQE Strategy": 11.19,
    "HFRI 500": 3.38,
    "HFRI Currency Index": -1.17
  },
  {
    "Date": "2012-05-31",
    "MQE Strategy": 5.14,
    "HFRI 500": 1.21,
    "HFRI Currency Index": -1.74
  },
  {
    "Date": "2012-06-30",
    "MQE Strategy": 6.56,
    "HFRI 500": 1.16,
    "HFRI Currency Index": -2.25
  },
  {
    "Date": "2012-07-31",
    "MQE Strategy": 4.67,
    "HFRI 500": 2.23,
    "HFRI Currency Index": -0.3
  },
  {
    "Date": "2012-08-31",
    "MQE Strategy": 2.72,
    "HFRI 500": 2.86,
    "HFRI Currency Index": -1.61
  },
  {
    "Date": "2012-09-30",
    "MQE Strategy": 3.71,
    "HFRI 500": 3.98,
    "HFRI Currency Index": -1.54
  },
  {
    "Date": "2012-10-31",
    "MQE Strategy": 3.71,
    "HFRI 500": 3.66,
    "HFRI Currency Index": -2.91
  },
  {
    "Date": "2012-11-30",
    "MQE Strategy": 4.83,
    "HFRI 500": 4.21,
    "HFRI Currency Index": -2.93
  },
  {
    "Date": "2012-12-31",
    "MQE Strategy": 6.61,
    "HFRI 500": 5.81,
    "HFRI Currency Index": -2.54
  },
  {
    "Date": "2013-01-31",
    "MQE Strategy": 6.3,
    "HFRI 500": 8.66,
    "HFRI Currency Index": -2.85
  },
  {
    "Date": "2013-02-28",
    "MQE Strategy": 7.09,
    "HFRI 500": 9,
    "HFRI Currency Index": -3.12
  },
  {
    "Date": "2013-03-31",
    "MQE Strategy": 3.34,
    "HFRI 500": 10.01,
    "HFRI Currency Index": -2.67
  },
  {
    "Date": "2013-04-30",
    "MQE Strategy": 1.57,
    "HFRI 500": 11.08,
    "HFRI Currency Index": -3.5
  },
  {
    "Date": "2013-05-31",
    "MQE Strategy": 7.02,
    "HFRI 500": 11.76,
    "HFRI Currency Index": -3.93
  },
  {
    "Date": "2013-06-30",
    "MQE Strategy": 8.96,
    "HFRI 500": 9.94,
    "HFRI Currency Index": -4.86
  },
  {
    "Date": "2013-07-31",
    "MQE Strategy": 8.96,
    "HFRI 500": 11.37,
    "HFRI Currency Index": -5.81
  },
  {
    "Date": "2013-08-31",
    "MQE Strategy": 12.5,
    "HFRI 500": 10.68,
    "HFRI Currency Index": -7.38
  },
  {
    "Date": "2013-09-30",
    "MQE Strategy": 13.73,
    "HFRI 500": 12.33,
    "HFRI Currency Index": -6.85
  },
  {
    "Date": "2013-10-31",
    "MQE Strategy": 11.83,
    "HFRI 500": 14.21,
    "HFRI Currency Index": -6.93
  },
  {
    "Date": "2013-11-30",
    "MQE Strategy": 14.26,
    "HFRI 500": 15.55,
    "HFRI Currency Index": -7.24
  },
  {
    "Date": "2013-12-31",
    "MQE Strategy": 14.26,
    "HFRI 500": 16.83,
    "HFRI Currency Index": -7.48
  },
  {
    "Date": "2014-01-31",
    "MQE Strategy": 13.57,
    "HFRI 500": 16.21,
    "HFRI Currency Index": -7.81
  },
  {
    "Date": "2014-02-28",
    "MQE Strategy": 13.97,
    "HFRI 500": 18.18,
    "HFRI Currency Index": -8.45
  },
  {
    "Date": "2014-03-31",
    "MQE Strategy": 13.4,
    "HFRI 500": 17.51,
    "HFRI Currency Index": -8.22
  },
  {
    "Date": "2014-04-30",
    "MQE Strategy": 12.97,
    "HFRI 500": 16.93,
    "HFRI Currency Index": -8.7
  },
  {
    "Date": "2014-05-31",
    "MQE Strategy": 11.13,
    "HFRI 500": 18.37,
    "HFRI Currency Index": -8.09
  },
  {
    "Date": "2014-06-30",
    "MQE Strategy": 12.23,
    "HFRI 500": 19.69,
    "HFRI Currency Index": -8.34
  },
  {
    "Date": "2014-07-31",
    "MQE Strategy": 14.27,
    "HFRI 500": 19.2,
    "HFRI Currency Index": -8.06
  },
  {
    "Date": "2014-08-31",
    "MQE Strategy": 13.46,
    "HFRI 500": 20.73,
    "HFRI Currency Index": -7.05
  },
  {
    "Date": "2014-09-30",
    "MQE Strategy": 16.09,
    "HFRI 500": 20.54,
    "HFRI Currency Index": -4.97
  },
  {
    "Date": "2014-10-31",
    "MQE Strategy": 16.09,
    "HFRI 500": 20.23,
    "HFRI Currency Index": -4.21
  },
  {
    "Date": "2014-11-30",
    "MQE Strategy": 14.02,
    "HFRI 500": 21.78,
    "HFRI Currency Index": -4.62
  },
  {
    "Date": "2014-12-31",
    "MQE Strategy": 7.69,
    "HFRI 500": 21.88,
    "HFRI Currency Index": -3.22
  },
  {
    "Date": "2015-01-31",
    "MQE Strategy": 8.36,
    "HFRI 500": 22.5,
    "HFRI Currency Index": -2.14
  },
  {
    "Date": "2015-02-28",
    "MQE Strategy": 8.07,
    "HFRI 500": 24.63,
    "HFRI Currency Index": -2.58
  },
  {
    "Date": "2015-03-31",
    "MQE Strategy": 5.67,
    "HFRI 500": 25.39,
    "HFRI Currency Index": -2.02
  },
  {
    "Date": "2015-04-30",
    "MQE Strategy": 5.11,
    "HFRI 500": 26.52,
    "HFRI Currency Index": -2.8
  },
  {
    "Date": "2015-05-31",
    "MQE Strategy": 6.14,
    "HFRI 500": 27.61,
    "HFRI Currency Index": -2.34
  },
  {
    "Date": "2015-06-30",
    "MQE Strategy": 7.21,
    "HFRI 500": 25.53,
    "HFRI Currency Index": -3.91
  },
  {
    "Date": "2015-07-31",
    "MQE Strategy": 11.58,
    "HFRI 500": 25.72,
    "HFRI Currency Index": -3.43
  },
  {
    "Date": "2015-08-31",
    "MQE Strategy": 2.1,
    "HFRI 500": 22.84,
    "HFRI Currency Index": -3.57
  },
  {
    "Date": "2015-09-30",
    "MQE Strategy": 0.79,
    "HFRI 500": 21.3,
    "HFRI Currency Index": -4.9
  },
  {
    "Date": "2015-10-31",
    "MQE Strategy": 3.49,
    "HFRI 500": 23.03,
    "HFRI Currency Index": -4.73
  },
  {
    "Date": "2015-11-30",
    "MQE Strategy": 3.49,
    "HFRI 500": 23.38,
    "HFRI Currency Index": -3.34
  },
  {
    "Date": "2015-12-31",
    "MQE Strategy": 2.87,
    "HFRI 500": 22.54,
    "HFRI Currency Index": -4.31
  },
  {
    "Date": "2016-01-31",
    "MQE Strategy": 6.27,
    "HFRI 500": 19.64,
    "HFRI Currency Index": -3.37
  },
  {
    "Date": "2016-02-29",
    "MQE Strategy": 1.92,
    "HFRI 500": 19.02,
    "HFRI Currency Index": -1.41
  },
  {
    "Date": "2016-03-31",
    "MQE Strategy": 11.61,
    "HFRI 500": 20.77,
    "HFRI Currency Index": -3.02
  },
  {
    "Date": "2016-04-30",
    "MQE Strategy": 11.46,
    "HFRI 500": 21.32,
    "HFRI Currency Index": -2.62
  },
  {
    "Date": "2016-05-31",
    "MQE Strategy": 11.54,
    "HFRI 500": 22,
    "HFRI Currency Index": -2.87
  },
  {
    "Date": "2016-06-30",
    "MQE Strategy": 12.4,
    "HFRI 500": 21.93,
    "HFRI Currency Index": -2.17
  },
  {
    "Date": "2016-07-31",
    "MQE Strategy": 9.86,
    "HFRI 500": 23.87,
    "HFRI Currency Index": -2.33
  },
  {
    "Date": "2016-08-31",
    "MQE Strategy": 15.69,
    "HFRI 500": 24.25,
    "HFRI Currency Index": -2.29
  },
  {
    "Date": "2016-09-30",
    "MQE Strategy": 17.47,
    "HFRI 500": 24.91,
    "HFRI Currency Index": -3.57
  },
  {
    "Date": "2016-10-31",
    "MQE Strategy": 19.46,
    "HFRI 500": 24.25,
    "HFRI Currency Index": -2.74
  },
  {
    "Date": "2016-11-30",
    "MQE Strategy": 18.7,
    "HFRI 500": 24.77,
    "HFRI Currency Index": -1.39
  },
  {
    "Date": "2016-12-31",
    "MQE Strategy": 17.77,
    "HFRI 500": 25.84,
    "HFRI Currency Index": -0.95
  },
  {
    "Date": "2017-01-31",
    "MQE Strategy": 17.49,
    "HFRI 500": 27.21,
    "HFRI Currency Index": -1.89
  },
  {
    "Date": "2017-02-28",
    "MQE Strategy": 21.33,
    "HFRI 500": 28.56,
    "HFRI Currency Index": -1.52
  },
  {
    "Date": "2017-03-31",
    "MQE Strategy": 25.25,
    "HFRI 500": 29.12,
    "HFRI Currency Index": -1.55
  },
  {
    "Date": "2017-04-30",
    "MQE Strategy": 24.88,
    "HFRI 500": 29.96,
    "HFRI Currency Index": -1.5
  },
  {
    "Date": "2017-05-31",
    "MQE Strategy": 25.49,
    "HFRI 500": 30.59,
    "HFRI Currency Index": -1.8
  },
  {
    "Date": "2017-06-30",
    "MQE Strategy": 31.54,
    "HFRI 500": 30.85,
    "HFRI Currency Index": -1.71
  },
  {
    "Date": "2017-07-31",
    "MQE Strategy": 28.62,
    "HFRI 500": 32.34,
    "HFRI Currency Index": -2.36
  },
  {
    "Date": "2017-08-31",
    "MQE Strategy": 30.24,
    "HFRI 500": 33.4,
    "HFRI Currency Index": -3.82
  },
  {
    "Date": "2017-09-30",
    "MQE Strategy": 32.84,
    "HFRI 500": 34.07,
    "HFRI Currency Index": -3.21
  },
  {
    "Date": "2017-10-31",
    "MQE Strategy": 35.91,
    "HFRI 500": 36.05,
    "HFRI Currency Index": -2.66
  },
  {
    "Date": "2017-11-30",
    "MQE Strategy": 39.39,
    "HFRI 500": 36.44,
    "HFRI Currency Index": -2.54
  },
  {
    "Date": "2017-12-31",
    "MQE Strategy": 33.69,
    "HFRI 500": 37.55,
    "HFRI Currency Index": -3.99
  },
  {
    "Date": "2018-01-31",
    "MQE Strategy": 37.26,
    "HFRI 500": 41.24,
    "HFRI Currency Index": -5.51
  },
  {
    "Date": "2018-02-28",
    "MQE Strategy": 41.37,
    "HFRI 500": 38.81,
    "HFRI Currency Index": -4.41
  },
  {
    "Date": "2018-03-31",
    "MQE Strategy": 37.51,
    "HFRI 500": 38.23,
    "HFRI Currency Index": -4.68
  },
  {
    "Date": "2018-04-30",
    "MQE Strategy": 40.44,
    "HFRI 500": 38.55,
    "HFRI Currency Index": -4.34
  },
  {
    "Date": "2018-05-31",
    "MQE Strategy": 44.84,
    "HFRI 500": 39.13,
    "HFRI Currency Index": -2.56
  },
  {
    "Date": "2018-06-30",
    "MQE Strategy": 43.77,
    "HFRI 500": 38.56,
    "HFRI Currency Index": -3.26
  },
  {
    "Date": "2018-07-31",
    "MQE Strategy": 44.7,
    "HFRI 500": 38.97,
    "HFRI Currency Index": -4.11
  },
  {
    "Date": "2018-08-31",
    "MQE Strategy": 44.5,
    "HFRI 500": 38.9,
    "HFRI Currency Index": -4.81
  },
  {
    "Date": "2018-09-30",
    "MQE Strategy": 44.42,
    "HFRI 500": 38.78,
    "HFRI Currency Index": -5.16
  },
  {
    "Date": "2018-10-31",
    "MQE Strategy": 40.06,
    "HFRI 500": 35.1,
    "HFRI Currency Index": -2.88
  },
  {
    "Date": "2018-11-30",
    "MQE Strategy": 38.93,
    "HFRI 500": 34.37,
    "HFRI Currency Index": -4.66
  },
  {
    "Date": "2018-12-31",
    "MQE Strategy": 43.03,
    "HFRI 500": 32.53,
    "HFRI Currency Index": -4
  },
  {
    "Date": "2019-01-31",
    "MQE Strategy": 48.52,
    "HFRI 500": 36.35,
    "HFRI Currency Index": -3.05
  },
  {
    "Date": "2019-02-28",
    "MQE Strategy": 48.52,
    "HFRI 500": 37.63,
    "HFRI Currency Index": -3.42
  },
  {
    "Date": "2019-03-31",
    "MQE Strategy": 47.83,
    "HFRI 500": 38.73,
    "HFRI Currency Index": -3.47
  },
  {
    "Date": "2019-04-30",
    "MQE Strategy": 46.04,
    "HFRI 500": 40.48,
    "HFRI Currency Index": -3.08
  },
  {
    "Date": "2019-05-31",
    "MQE Strategy": 42.18,
    "HFRI 500": 38.91,
    "HFRI Currency Index": -2.73
  },
  {
    "Date": "2019-06-30",
    "MQE Strategy": 44.99,
    "HFRI 500": 41.49,
    "HFRI Currency Index": -3.17
  },
  {
    "Date": "2019-07-31",
    "MQE Strategy": 44.57,
    "HFRI 500": 42.35,
    "HFRI Currency Index": -1.45
  },
  {
    "Date": "2019-08-31",
    "MQE Strategy": 47.98,
    "HFRI 500": 41.48,
    "HFRI Currency Index": 0.16
  },
  {
    "Date": "2019-09-30",
    "MQE Strategy": 46.7,
    "HFRI 500": 41.29,
    "HFRI Currency Index": 1.19
  },
  {
    "Date": "2019-10-31",
    "MQE Strategy": 42.32,
    "HFRI 500": 42.01,
    "HFRI Currency Index": 0.43
  },
  {
    "Date": "2019-11-30",
    "MQE Strategy": 43.76,
    "HFRI 500": 43.18,
    "HFRI Currency Index": 0.8
  },
  {
    "Date": "2019-12-31",
    "MQE Strategy": 41.46,
    "HFRI 500": 45.69,
    "HFRI Currency Index": -0.55
  },
  {
    "Date": "2020-01-31",
    "MQE Strategy": 40.26,
    "HFRI 500": 45.36,
    "HFRI Currency Index": 0.75
  },
  {
    "Date": "2020-02-29",
    "MQE Strategy": 40.27,
    "HFRI 500": 42.59,
    "HFRI Currency Index": 2.71
  },
  {
    "Date": "2020-03-31",
    "MQE Strategy": 68.34,
    "HFRI 500": 31.26,
    "HFRI Currency Index": 2.82
  },
  {
    "Date": "2020-04-30",
    "MQE Strategy": 75.54,
    "HFRI 500": 36.69,
    "HFRI Currency Index": 2.06
  },
  {
    "Date": "2020-05-31",
    "MQE Strategy": 54.7,
    "HFRI 500": 39.51,
    "HFRI Currency Index": 1.39
  },
  {
    "Date": "2020-06-30",
    "MQE Strategy": 60.35,
    "HFRI 500": 41.84,
    "HFRI Currency Index": 1.37
  },
  {
    "Date": "2020-07-31",
    "MQE Strategy": 52.8,
    "HFRI 500": 45.55,
    "HFRI Currency Index": 1.49
  },
  {
    "Date": "2020-08-31",
    "MQE Strategy": 52.51,
    "HFRI 500": 48.36,
    "HFRI Currency Index": 0.82
  },
  {
    "Date": "2020-09-30",
    "MQE Strategy": 52.51,
    "HFRI 500": 46.92,
    "HFRI Currency Index": 1.07
  },
  {
    "Date": "2020-10-31",
    "MQE Strategy": 52.51,
    "HFRI 500": 47.06,
    "HFRI Currency Index": 0.93
  },
  {
    "Date": "2020-11-30",
    "MQE Strategy": 50.77,
    "HFRI 500": 54.84,
    "HFRI Currency Index": 1.12
  },
  {
    "Date": "2020-12-31",
    "MQE Strategy": 49.06,
    "HFRI 500": 61.17,
    "HFRI Currency Index": 2.37
  },
  {
    "Date": "2021-01-31",
    "MQE Strategy": 51.55,
    "HFRI 500": 62.51,
    "HFRI Currency Index": 1.91
  },
  {
    "Date": "2021-02-28",
    "MQE Strategy": 51.87,
    "HFRI 500": 68.39,
    "HFRI Currency Index": 1.69
  },
  {
    "Date": "2021-03-31",
    "MQE Strategy": 62.91,
    "HFRI 500": 69.53,
    "HFRI Currency Index": 2.26
  },
  {
    "Date": "2021-04-30",
    "MQE Strategy": 67.39,
    "HFRI 500": 73.45,
    "HFRI Currency Index": 1.74
  },
  {
    "Date": "2021-05-31",
    "MQE Strategy": 68,
    "HFRI 500": 75.63,
    "HFRI Currency Index": 1.73
  },
  {
    "Date": "2021-06-30",
    "MQE Strategy": 69.26,
    "HFRI 500": 76.3,
    "HFRI Currency Index": 1.03
  },
  {
    "Date": "2021-07-31",
    "MQE Strategy": 68.85,
    "HFRI 500": 75.05,
    "HFRI Currency Index": -0.75
  },
  {
    "Date": "2021-08-31",
    "MQE Strategy": 66.44,
    "HFRI 500": 76.03,
    "HFRI Currency Index": -1.08
  },
  {
    "Date": "2021-09-30",
    "MQE Strategy": 63.5,
    "HFRI 500": 75.47,
    "HFRI Currency Index": -0.7
  },
  {
    "Date": "2021-10-31",
    "MQE Strategy": 64.49,
    "HFRI 500": 78.19,
    "HFRI Currency Index": -2.46
  },
  {
    "Date": "2021-11-30",
    "MQE Strategy": 54.6,
    "HFRI 500": 75.43,
    "HFRI Currency Index": -3.59
  },
  {
    "Date": "2021-12-31",
    "MQE Strategy": 59.65,
    "HFRI 500": 77.02,
    "HFRI Currency Index": -3.43
  },
  {
    "Date": "2022-01-31",
    "MQE Strategy": 63.47,
    "HFRI 500": 77.02,
    "HFRI Currency Index": -3.43
  },
  {
    "Date": "2022-02-28",
    "MQE Strategy": 58.75,
    "HFRI 500": 77.02,
    "HFRI Currency Index": -3.43
  },
  {
    "Date": "2022-03-31",
    "MQE Strategy": 56.34,
    "HFRI 500": 77.02,
    "HFRI Currency Index": -3.43
  }
 ]

export default data;