const data = [
  {
    "Date": "2007-01-01",
    "MQE Strategy": 0,
    "HFRI 500": 1.14,
    "S&P 500": 1.5
  },
  {
    "Date": "2007-02-01",
    "MQE Strategy": 0,
    "HFRI 500": 1.93,
    "S&P 500": -0.49
  },
  {
    "Date": "2007-03-01",
    "MQE Strategy": -2.01,
    "HFRI 500": 3.08,
    "S&P 500": 0.27
  },
  {
    "Date": "2007-04-01",
    "MQE Strategy": -1.07,
    "HFRI 500": 4.93,
    "S&P 500": 4.71
  },
  {
    "Date": "2007-05-01",
    "MQE Strategy": -2.82,
    "HFRI 500": 7.3,
    "S&P 500": 8.26
  },
  {
    "Date": "2007-06-01",
    "MQE Strategy": -2.51,
    "HFRI 500": 8.57,
    "S&P 500": 6.22
  },
  {
    "Date": "2007-07-01",
    "MQE Strategy": -2.66,
    "HFRI 500": 9.21,
    "S&P 500": 2.9
  },
  {
    "Date": "2007-08-01",
    "MQE Strategy": -3.36,
    "HFRI 500": 7.07,
    "S&P 500": 4.22
  },
  {
    "Date": "2007-09-01",
    "MQE Strategy": -2.43,
    "HFRI 500": 9.74,
    "S&P 500": 7.74
  },
  {
    "Date": "2007-10-01",
    "MQE Strategy": -5.51,
    "HFRI 500": 12.89,
    "S&P 500": 9.2
  },
  {
    "Date": "2007-11-01",
    "MQE Strategy": -3.58,
    "HFRI 500": 10.39,
    "S&P 500": 4.97
  },
  {
    "Date": "2007-12-01",
    "MQE Strategy": -4.23,
    "HFRI 500": 10.85,
    "S&P 500": 3.24
  },
  {
    "Date": "2008-01-01",
    "MQE Strategy": -6.12,
    "HFRI 500": 7.59,
    "S&P 500": -3
  },
  {
    "Date": "2008-02-01",
    "MQE Strategy": -7.28,
    "HFRI 500": 9.41,
    "S&P 500": -5.51
  },
  {
    "Date": "2008-03-01",
    "MQE Strategy": -8.5,
    "HFRI 500": 6.79,
    "S&P 500": -6.81
  },
  {
    "Date": "2008-04-01",
    "MQE Strategy": -7.7,
    "HFRI 500": 8.48,
    "S&P 500": -2.37
  },
  {
    "Date": "2008-05-01",
    "MQE Strategy": -8.18,
    "HFRI 500": 10.42,
    "S&P 500": -0.9
  },
  {
    "Date": "2008-06-01",
    "MQE Strategy": -6.71,
    "HFRI 500": 8.97,
    "S&P 500": -9.63
  },
  {
    "Date": "2008-07-01",
    "MQE Strategy": -8.18,
    "HFRI 500": 6.22,
    "S&P 500": -10.44
  },
  {
    "Date": "2008-08-01",
    "MQE Strategy": -8.16,
    "HFRI 500": 4.35,
    "S&P 500": -9.06
  },
  {
    "Date": "2008-09-01",
    "MQE Strategy": 0.16,
    "HFRI 500": -1.98,
    "S&P 500": -18.1
  },
  {
    "Date": "2008-10-01",
    "MQE Strategy": 8.44,
    "HFRI 500": -8.72,
    "S&P 500": -31.63
  },
  {
    "Date": "2008-11-01",
    "MQE Strategy": 2.8,
    "HFRI 500": -10.22,
    "S&P 500": -36.39
  },
  {
    "Date": "2008-12-01",
    "MQE Strategy": -3.11,
    "HFRI 500": -10.09,
    "S&P 500": -36.28
  },
  {
    "Date": "2009-01-01",
    "MQE Strategy": -5.17,
    "HFRI 500": -9.93,
    "S&P 500": -41.51
  },
  {
    "Date": "2009-02-01",
    "MQE Strategy": -8.05,
    "HFRI 500": -10.5,
    "S&P 500": -47.8
  },
  {
    "Date": "2009-03-01",
    "MQE Strategy": -9.65,
    "HFRI 500": -9.21,
    "S&P 500": -43.85
  },
  {
    "Date": "2009-04-01",
    "MQE Strategy": -9.94,
    "HFRI 500": -6.78,
    "S&P 500": -38.27
  },
  {
    "Date": "2009-05-01",
    "MQE Strategy": -12.22,
    "HFRI 500": -2.26,
    "S&P 500": -34.66
  },
  {
    "Date": "2009-06-01",
    "MQE Strategy": -16.34,
    "HFRI 500": -2.05,
    "S&P 500": -35.07
  },
  {
    "Date": "2009-07-01",
    "MQE Strategy": -21.79,
    "HFRI 500": 0.52,
    "S&P 500": -30.23
  },
  {
    "Date": "2009-08-01",
    "MQE Strategy": -24.43,
    "HFRI 500": 1.94,
    "S&P 500": -27.65
  },
  {
    "Date": "2009-09-01",
    "MQE Strategy": -20.7,
    "HFRI 500": 4.75,
    "S&P 500": -25.44
  },
  {
    "Date": "2009-10-01",
    "MQE Strategy": -20.81,
    "HFRI 500": 4.59,
    "S&P 500": -26.87
  },
  {
    "Date": "2009-11-01",
    "MQE Strategy": -22.87,
    "HFRI 500": 5.88,
    "S&P 500": -22.37
  },
  {
    "Date": "2009-12-01",
    "MQE Strategy": -20.78,
    "HFRI 500": 6.87,
    "S&P 500": -21.31
  },
  {
    "Date": "2010-01-01",
    "MQE Strategy": -18.3,
    "HFRI 500": 6.22,
    "S&P 500": -24.17
  },
  {
    "Date": "2010-02-01",
    "MQE Strategy": -17.4,
    "HFRI 500": 6.51,
    "S&P 500": -21.8
  },
  {
    "Date": "2010-03-01",
    "MQE Strategy": -12.88,
    "HFRI 500": 9.13,
    "S&P 500": -17.38
  },
  {
    "Date": "2010-04-01",
    "MQE Strategy": -15.17,
    "HFRI 500": 10.18,
    "S&P 500": -16.11
  },
  {
    "Date": "2010-05-01",
    "MQE Strategy": -14.17,
    "HFRI 500": 7.08,
    "S&P 500": -22.77
  },
  {
    "Date": "2010-06-01",
    "MQE Strategy": -14.17,
    "HFRI 500": 6.3,
    "S&P 500": -27.11
  },
  {
    "Date": "2010-07-01",
    "MQE Strategy": -13.53,
    "HFRI 500": 7.88,
    "S&P 500": -22.14
  },
  {
    "Date": "2010-08-01",
    "MQE Strategy": -8.54,
    "HFRI 500": 8.01,
    "S&P 500": -25.64
  },
  {
    "Date": "2010-09-01",
    "MQE Strategy": -8.16,
    "HFRI 500": 11.51,
    "S&P 500": -19.41
  },
  {
    "Date": "2010-10-01",
    "MQE Strategy": -7.48,
    "HFRI 500": 13.65,
    "S&P 500": -16.33
  },
  {
    "Date": "2010-11-01",
    "MQE Strategy": -6.01,
    "HFRI 500": 13.38,
    "S&P 500": -16.33
  },
  {
    "Date": "2010-12-01",
    "MQE Strategy": -5.21,
    "HFRI 500": 16.44,
    "S&P 500": -11.21
  },
  {
    "Date": "2011-01-01",
    "MQE Strategy": -2.79,
    "HFRI 500": 16.92,
    "S&P 500": -9.14
  },
  {
    "Date": "2011-02-01",
    "MQE Strategy": 0.43,
    "HFRI 500": 17.94,
    "S&P 500": -5.98
  },
  {
    "Date": "2011-03-01",
    "MQE Strategy": 0.57,
    "HFRI 500": 18.01,
    "S&P 500": -6.38
  },
  {
    "Date": "2011-04-01",
    "MQE Strategy": 2.54,
    "HFRI 500": 19.92,
    "S&P 500": -3.66
  },
  {
    "Date": "2011-05-01",
    "MQE Strategy": 1.45,
    "HFRI 500": 18.2,
    "S&P 500": -4.75
  },
  {
    "Date": "2011-06-01",
    "MQE Strategy": 3.4,
    "HFRI 500": 16.86,
    "S&P 500": -6.81
  },
  {
    "Date": "2011-07-01",
    "MQE Strategy": 4.07,
    "HFRI 500": 17.38,
    "S&P 500": -8.68
  },
  {
    "Date": "2011-08-01",
    "MQE Strategy": 5.86,
    "HFRI 500": 14.06,
    "S&P 500": -13.7
  },
  {
    "Date": "2011-09-01",
    "MQE Strategy": 5.43,
    "HFRI 500": 10.42,
    "S&P 500": -20.1
  },
  {
    "Date": "2011-10-01",
    "MQE Strategy": 7.63,
    "HFRI 500": 12.86,
    "S&P 500": -11.38
  },
  {
    "Date": "2011-11-01",
    "MQE Strategy": 9.5,
    "HFRI 500": 11.56,
    "S&P 500": -11.74
  },
  {
    "Date": "2011-12-01",
    "MQE Strategy": 14.19,
    "HFRI 500": 11.15,
    "S&P 500": -11.38
  },
  {
    "Date": "2012-01-01",
    "MQE Strategy": 16.44,
    "HFRI 500": 13.73,
    "S&P 500": -7.27
  },
  {
    "Date": "2012-02-01",
    "MQE Strategy": 19.06,
    "HFRI 500": 15.9,
    "S&P 500": -3.25
  },
  {
    "Date": "2012-03-01",
    "MQE Strategy": 18,
    "HFRI 500": 15.64,
    "S&P 500": -0.57
  },
  {
    "Date": "2012-04-01",
    "MQE Strategy": 16.69,
    "HFRI 500": 15.34,
    "S&P 500": -1.24
  },
  {
    "Date": "2012-05-01",
    "MQE Strategy": 19.2,
    "HFRI 500": 12.91,
    "S&P 500": -7.17
  },
  {
    "Date": "2012-06-01",
    "MQE Strategy": 24.81,
    "HFRI 500": 12.86,
    "S&P 500": -3.9
  },
  {
    "Date": "2012-07-01",
    "MQE Strategy": 26.71,
    "HFRI 500": 14.05,
    "S&P 500": -2.76
  },
  {
    "Date": "2012-08-01",
    "MQE Strategy": 23.91,
    "HFRI 500": 14.76,
    "S&P 500": -0.32
  },
  {
    "Date": "2012-09-01",
    "MQE Strategy": 23.67,
    "HFRI 500": 16,
    "S&P 500": 1.66
  },
  {
    "Date": "2012-10-01",
    "MQE Strategy": 26.17,
    "HFRI 500": 15.65,
    "S&P 500": -0.19
  },
  {
    "Date": "2012-11-01",
    "MQE Strategy": 24.66,
    "HFRI 500": 16.26,
    "S&P 500": 0.37
  },
  {
    "Date": "2012-12-01",
    "MQE Strategy": 24.58,
    "HFRI 500": 18.04,
    "S&P 500": 0.56
  },
  {
    "Date": "2013-01-01",
    "MQE Strategy": 26.63,
    "HFRI 500": 21.23,
    "S&P 500": 5.71
  },
  {
    "Date": "2013-02-01",
    "MQE Strategy": 27,
    "HFRI 500": 21.61,
    "S&P 500": 7.05
  },
  {
    "Date": "2013-03-01",
    "MQE Strategy": 27.52,
    "HFRI 500": 22.74,
    "S&P 500": 10.63
  },
  {
    "Date": "2013-04-01",
    "MQE Strategy": 27.2,
    "HFRI 500": 23.93,
    "S&P 500": 12.75
  },
  {
    "Date": "2013-05-01",
    "MQE Strategy": 26.37,
    "HFRI 500": 24.68,
    "S&P 500": 15.41
  },
  {
    "Date": "2013-06-01",
    "MQE Strategy": 24.22,
    "HFRI 500": 22.65,
    "S&P 500": 13.27
  },
  {
    "Date": "2013-07-01",
    "MQE Strategy": 26.68,
    "HFRI 500": 24.25,
    "S&P 500": 19.13
  },
  {
    "Date": "2013-08-01",
    "MQE Strategy": 28.82,
    "HFRI 500": 23.48,
    "S&P 500": 15.56
  },
  {
    "Date": "2013-09-01",
    "MQE Strategy": 31.29,
    "HFRI 500": 25.32,
    "S&P 500": 18.63
  },
  {
    "Date": "2013-10-01",
    "MQE Strategy": 31.69,
    "HFRI 500": 27.42,
    "S&P 500": 24.13
  },
  {
    "Date": "2013-11-01",
    "MQE Strategy": 31.69,
    "HFRI 500": 28.91,
    "S&P 500": 27.81
  },
  {
    "Date": "2013-12-01",
    "MQE Strategy": 33.89,
    "HFRI 500": 30.34,
    "S&P 500": 30.41
  },
  {
    "Date": "2014-01-01",
    "MQE Strategy": 33.83,
    "HFRI 500": 29.65,
    "S&P 500": 25.82
  },
  {
    "Date": "2014-02-01",
    "MQE Strategy": 30.75,
    "HFRI 500": 31.84,
    "S&P 500": 31.54
  },
  {
    "Date": "2014-03-01",
    "MQE Strategy": 33.2,
    "HFRI 500": 31.1,
    "S&P 500": 32.05
  },
  {
    "Date": "2014-04-01",
    "MQE Strategy": 31.79,
    "HFRI 500": 30.45,
    "S&P 500": 32.97
  },
  {
    "Date": "2014-05-01",
    "MQE Strategy": 31.53,
    "HFRI 500": 32.05,
    "S&P 500": 36.05
  },
  {
    "Date": "2014-06-01",
    "MQE Strategy": 31.53,
    "HFRI 500": 33.53,
    "S&P 500": 38.2
  },
  {
    "Date": "2014-07-01",
    "MQE Strategy": 32.7,
    "HFRI 500": 32.98,
    "S&P 500": 36.34
  },
  {
    "Date": "2014-08-01",
    "MQE Strategy": 34.29,
    "HFRI 500": 34.69,
    "S&P 500": 41.72
  },
  {
    "Date": "2014-09-01",
    "MQE Strategy": 35.04,
    "HFRI 500": 34.49,
    "S&P 500": 39.12
  },
  {
    "Date": "2014-10-01",
    "MQE Strategy": 36.61,
    "HFRI 500": 34.14,
    "S&P 500": 42.4
  },
  {
    "Date": "2014-11-01",
    "MQE Strategy": 36.33,
    "HFRI 500": 35.87,
    "S&P 500": 46.31
  },
  {
    "Date": "2014-12-01",
    "MQE Strategy": 39.44,
    "HFRI 500": 35.97,
    "S&P 500": 45.13
  },
  {
    "Date": "2015-01-01",
    "MQE Strategy": 39.65,
    "HFRI 500": 36.67,
    "S&P 500": 40.83
  },
  {
    "Date": "2015-02-01",
    "MQE Strategy": 39.11,
    "HFRI 500": 39.05,
    "S&P 500": 48.75
  },
  {
    "Date": "2015-03-01",
    "MQE Strategy": 41.2,
    "HFRI 500": 39.89,
    "S&P 500": 45.76
  },
  {
    "Date": "2015-04-01",
    "MQE Strategy": 38.9,
    "HFRI 500": 41.15,
    "S&P 500": 47.2
  },
  {
    "Date": "2015-05-01",
    "MQE Strategy": 35.84,
    "HFRI 500": 42.37,
    "S&P 500": 49.09
  },
  {
    "Date": "2015-06-01",
    "MQE Strategy": 34.4,
    "HFRI 500": 40.05,
    "S&P 500": 45.35
  },
  {
    "Date": "2015-07-01",
    "MQE Strategy": 34.75,
    "HFRI 500": 40.26,
    "S&P 500": 48.64
  },
  {
    "Date": "2015-08-01",
    "MQE Strategy": 34.16,
    "HFRI 500": 37.04,
    "S&P 500": 39.58
  },
  {
    "Date": "2015-09-01",
    "MQE Strategy": 32.64,
    "HFRI 500": 35.33,
    "S&P 500": 35.31
  },
  {
    "Date": "2015-10-01",
    "MQE Strategy": 31.96,
    "HFRI 500": 37.25,
    "S&P 500": 46.82
  },
  {
    "Date": "2015-11-01",
    "MQE Strategy": 31.6,
    "HFRI 500": 37.65,
    "S&P 500": 47.36
  },
  {
    "Date": "2015-12-01",
    "MQE Strategy": 34.82,
    "HFRI 500": 36.72,
    "S&P 500": 43.96
  },
  {
    "Date": "2016-01-01",
    "MQE Strategy": 37.22,
    "HFRI 500": 33.48,
    "S&P 500": 36.79
  },
  {
    "Date": "2016-02-01",
    "MQE Strategy": 37.2,
    "HFRI 500": 32.78,
    "S&P 500": 36.68
  },
  {
    "Date": "2016-03-01",
    "MQE Strategy": 34.66,
    "HFRI 500": 34.73,
    "S&P 500": 45.12
  },
  {
    "Date": "2016-04-01",
    "MQE Strategy": 43.11,
    "HFRI 500": 35.35,
    "S&P 500": 45.69
  },
  {
    "Date": "2016-05-01",
    "MQE Strategy": 41.26,
    "HFRI 500": 36.11,
    "S&P 500": 48.17
  },
  {
    "Date": "2016-06-01",
    "MQE Strategy": 39.8,
    "HFRI 500": 36.03,
    "S&P 500": 47.92
  },
  {
    "Date": "2016-07-01",
    "MQE Strategy": 49.83,
    "HFRI 500": 38.19,
    "S&P 500": 53.31
  },
  {
    "Date": "2016-08-01",
    "MQE Strategy": 50.77,
    "HFRI 500": 38.62,
    "S&P 500": 53.5
  },
  {
    "Date": "2016-09-01",
    "MQE Strategy": 51.59,
    "HFRI 500": 39.36,
    "S&P 500": 52.73
  },
  {
    "Date": "2016-10-01",
    "MQE Strategy": 46.33,
    "HFRI 500": 38.62,
    "S&P 500": 50.08
  },
  {
    "Date": "2016-11-01",
    "MQE Strategy": 55.45,
    "HFRI 500": 39.2,
    "S&P 500": 55.61
  },
  {
    "Date": "2016-12-01",
    "MQE Strategy": 54.43,
    "HFRI 500": 40.4,
    "S&P 500": 57.84
  },
  {
    "Date": "2017-01-01",
    "MQE Strategy": 56.44,
    "HFRI 500": 41.93,
    "S&P 500": 60.66
  },
  {
    "Date": "2017-02-01",
    "MQE Strategy": 65.01,
    "HFRI 500": 43.43,
    "S&P 500": 66.98
  },
  {
    "Date": "2017-03-01",
    "MQE Strategy": 64.48,
    "HFRI 500": 44.05,
    "S&P 500": 66.46
  },
  {
    "Date": "2017-04-01",
    "MQE Strategy": 66.88,
    "HFRI 500": 44.98,
    "S&P 500": 68.11
  },
  {
    "Date": "2017-05-01",
    "MQE Strategy": 66.34,
    "HFRI 500": 45.69,
    "S&P 500": 70.48
  },
  {
    "Date": "2017-06-01",
    "MQE Strategy": 67.21,
    "HFRI 500": 45.99,
    "S&P 500": 70.74
  },
  {
    "Date": "2017-07-01",
    "MQE Strategy": 70.03,
    "HFRI 500": 47.65,
    "S&P 500": 74.25
  },
  {
    "Date": "2017-08-01",
    "MQE Strategy": 71.86,
    "HFRI 500": 48.83,
    "S&P 500": 74.76
  },
  {
    "Date": "2017-09-01",
    "MQE Strategy": 72.69,
    "HFRI 500": 49.57,
    "S&P 500": 77.4
  },
  {
    "Date": "2017-10-01",
    "MQE Strategy": 76.11,
    "HFRI 500": 51.79,
    "S&P 500": 81.58
  },
  {
    "Date": "2017-11-01",
    "MQE Strategy": 76.21,
    "HFRI 500": 52.21,
    "S&P 500": 87.13
  },
  {
    "Date": "2017-12-01",
    "MQE Strategy": 78.85,
    "HFRI 500": 53.46,
    "S&P 500": 88.43
  },
  {
    "Date": "2018-01-01",
    "MQE Strategy": 81.92,
    "HFRI 500": 57.57,
    "S&P 500": 99.05
  },
  {
    "Date": "2018-02-01",
    "MQE Strategy": 79.76,
    "HFRI 500": 54.86,
    "S&P 500": 91.82
  },
  {
    "Date": "2018-03-01",
    "MQE Strategy": 80.55,
    "HFRI 500": 54.21,
    "S&P 500": 85.81
  },
  {
    "Date": "2018-04-01",
    "MQE Strategy": 83.18,
    "HFRI 500": 54.57,
    "S&P 500": 86.77
  },
  {
    "Date": "2018-05-01",
    "MQE Strategy": 90.49,
    "HFRI 500": 55.22,
    "S&P 500": 91.31
  },
  {
    "Date": "2018-06-01",
    "MQE Strategy": 97.35,
    "HFRI 500": 54.58,
    "S&P 500": 91.55
  },
  {
    "Date": "2018-07-01",
    "MQE Strategy": 95.72,
    "HFRI 500": 55.05,
    "S&P 500": 98.65
  },
  {
    "Date": "2018-08-01",
    "MQE Strategy": 97.24,
    "HFRI 500": 54.97,
    "S&P 500": 104.99
  },
  {
    "Date": "2018-09-01",
    "MQE Strategy": 98.89,
    "HFRI 500": 54.83,
    "S&P 500": 105.28
  },
  {
    "Date": "2018-10-01",
    "MQE Strategy": 97.67,
    "HFRI 500": 50.73,
    "S&P 500": 91.1
  },
  {
    "Date": "2018-11-01",
    "MQE Strategy": 104.29,
    "HFRI 500": 49.91,
    "S&P 500": 94.64
  },
  {
    "Date": "2018-12-01",
    "MQE Strategy": 105.81,
    "HFRI 500": 47.86,
    "S&P 500": 76.47
  },
  {
    "Date": "2019-01-01",
    "MQE Strategy": 109.06,
    "HFRI 500": 52.12,
    "S&P 500": 90.6
  },
  {
    "Date": "2019-02-01",
    "MQE Strategy": 114.07,
    "HFRI 500": 53.55,
    "S&P 500": 96.78
  },
  {
    "Date": "2019-03-01",
    "MQE Strategy": 119.18,
    "HFRI 500": 54.77,
    "S&P 500": 99.46
  },
  {
    "Date": "2019-04-01",
    "MQE Strategy": 116.08,
    "HFRI 500": 56.72,
    "S&P 500": 107.61
  },
  {
    "Date": "2019-05-01",
    "MQE Strategy": 116.95,
    "HFRI 500": 54.97,
    "S&P 500": 94.37
  },
  {
    "Date": "2019-06-01",
    "MQE Strategy": 114.68,
    "HFRI 500": 57.85,
    "S&P 500": 106.89
  },
  {
    "Date": "2019-07-01",
    "MQE Strategy": 115.56,
    "HFRI 500": 58.81,
    "S&P 500": 110.02
  },
  {
    "Date": "2019-08-01",
    "MQE Strategy": 121.24,
    "HFRI 500": 57.85,
    "S&P 500": 106.5
  },
  {
    "Date": "2019-09-01",
    "MQE Strategy": 122.97,
    "HFRI 500": 57.62,
    "S&P 500": 109.55
  },
  {
    "Date": "2019-10-01",
    "MQE Strategy": 127.28,
    "HFRI 500": 58.43,
    "S&P 500": 114.19
  },
  {
    "Date": "2019-11-01",
    "MQE Strategy": 140.09,
    "HFRI 500": 59.74,
    "S&P 500": 121.94
  },
  {
    "Date": "2019-12-01",
    "MQE Strategy": 140.07,
    "HFRI 500": 62.54,
    "S&P 500": 127.27
  },
  {
    "Date": "2020-01-01",
    "MQE Strategy": 136.84,
    "HFRI 500": 62.16,
    "S&P 500": 127.18
  },
  {
    "Date": "2020-02-01",
    "MQE Strategy": 139.03,
    "HFRI 500": 59.08,
    "S&P 500": 109.19
  },
  {
    "Date": "2020-03-01",
    "MQE Strategy": 132.97,
    "HFRI 500": 46.44,
    "S&P 500": 82
  },
  {
    "Date": "2020-04-01",
    "MQE Strategy": 192.32,
    "HFRI 500": 52.5,
    "S&P 500": 105.11
  },
  {
    "Date": "2020-05-01",
    "MQE Strategy": 196.39,
    "HFRI 500": 55.64,
    "S&P 500": 114.88
  },
  {
    "Date": "2020-06-01",
    "MQE Strategy": 196.39,
    "HFRI 500": 58.24,
    "S&P 500": 117.74
  },
  {
    "Date": "2020-07-01",
    "MQE Strategy": 188.34,
    "HFRI 500": 62.39,
    "S&P 500": 130.56
  },
  {
    "Date": "2020-08-01",
    "MQE Strategy": 192.97,
    "HFRI 500": 65.52,
    "S&P 500": 146.65
  },
  {
    "Date": "2020-09-01",
    "MQE Strategy": 192.2,
    "HFRI 500": 63.91,
    "S&P 500": 136.47
  },
  {
    "Date": "2020-10-01",
    "MQE Strategy": 205.31,
    "HFRI 500": 64.06,
    "S&P 500": 130.57
  },
  {
    "Date": "2020-11-01",
    "MQE Strategy": 207.24,
    "HFRI 500": 72.74,
    "S&P 500": 155.66
  },
  {
    "Date": "2020-12-01",
    "MQE Strategy": 202.78,
    "HFRI 500": 79.81,
    "S&P 500": 164
  },
  {
    "Date": "2021-01-01",
    "MQE Strategy": 213.34,
    "HFRI 500": 81.35,
    "S&P 500": 161.31
  },
  {
    "Date": "2021-02-01",
    "MQE Strategy": 210.19,
    "HFRI 500": 87.34,
    "S&P 500": 168.58
  },
  {
    "Date": "2021-03-01",
    "MQE Strategy": 220.39,
    "HFRI 500": 88.54,
    "S&P 500": 179.85
  }
]

export default data;